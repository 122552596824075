import { useEffect, useState } from "react";
import { ExtendedActuator } from "../models/project.models";
import api, { endpoints } from "../utils/api";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar,
    TooltipProps
} from 'recharts';
import { ActuatorHistoryDto } from "../models/actuator.models";

export default function GlobalCharts() {
    const [actuators, setActuators] = useState<ExtendedActuator[]>([]);
    const [dropdownSelection, setDropdownSelection] = useState<string[]>([]);
    const [selectedActuators, setSelectedActuators] = useState<Map<string, string>>(new Map());
    const [selectedActuatorsDelta, setSelectedActuatorsDelta] = useState(new Map());

    const [combinedActuatorHistory, setCombinedActuatorHistory] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<DateRange>();
    const [selectedFilters, setSelectedFilters] = useState<string[]>(["week"]);
    const [chartType, setChartType] = useState<'line' | 'bar'>('line');
    const [lineKeys, setLineKeys] = useState<string[]>([]);

    const locale = localStorage.getItem("i18nextLng") ?? "en";
    const { t } = useTranslation();

    const filterOptions = [
        { label: t("onlyToday"), value: "today" },
        { label: t("yesterday"), value: "yesterday" },
        { label: t("thisWeek"), value: "week" },
        { label: t("lastWeek"), value: "lastWeek" },
        { label: t("thisMonth"), value: "month" },
        { label: t("lastMonth"), value: "lastMonth" },
        { label: t("thisYear"), value: "thisYear" },
        { label: t("lastYear"), value: "lastYear" },
        { label: t("allTime"), value: "all" },
        { label: t("custom"), value: "custom" },
    ];

    useEffect(() => {
        fetchActuators();
        setSelectedActuatorsDelta(new Map());
    }, []);

    const fetchActuators = async () => {
        try {
            const response = await api.get(endpoints.actuators + `/actuators/`);
            if (response.data && response.data.length > 0) {
                setActuators(response.data);
            }
        }
        catch (error) {
        }
    }

    const removeActuator = (topic: string) => {
        const newMap = new Map(selectedActuators);
        newMap.delete(topic);
        setSelectedActuators(newMap);
    };

    const handleFilterChange = (selectedValue: string) => {
        setSelectedFilters(prev => {
            if (prev.includes(selectedValue)) {
                return prev.filter(filter => filter !== selectedValue);
            } else {
                return [...prev, selectedValue];
            }
        });
    };

    const handleDateRangeChange = (
        from?: string,
        to?: string
    ) => {
        setDateRange({ from, to });
    };

    const getDateRangeForFilter = (filter: string) => {
        const today = new Date();
        const startOfDay = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        );
        var from, to;

        switch (filter) {
            case "today":
                from = to = startOfDay;
                break;
            case "yesterday":
                from = new Date(startOfDay);
                from.setDate(from.getDate() - 1);
                to = new Date(from);
                break;
            case "week":
                from = new Date(startOfDay);
                from.setDate(from.getDate() - 7);
                to = new Date(startOfDay);
                break;
            case "lastWeek":
                from = new Date(startOfDay);
                from.setDate(from.getDate() - 14);
                to = new Date(startOfDay);
                to.setDate(to.getDate() - 7);
                break;
            case "month":
                from = new Date(today.getFullYear(), today.getMonth(), 1);
                to = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                break;
            case "lastMonth":
                from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                to = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case "thisYear":
                from = new Date(today.getFullYear(), 0, 1);
                to = new Date(startOfDay);
                break;
            case "lastYear":
                from = new Date(today.getFullYear() - 1, 0, 1);
                to = new Date(today.getFullYear() - 1, 11, 31);
                break;
            case "custom":
                const customRange = dateRange;
                if (customRange && customRange.from && customRange.to) {
                    return {
                        from: customRange.from,
                        to: customRange.to,
                    };
                }
                return {};
            case "all":
            default:
                return { from: "all", to: "all" };
        }

        return {
            from: from
                ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`
                : undefined,
            to: to
                ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
                : undefined,
        };
    }

    const fetchAllActuatorData = async () => {
        const promises: Promise<any[][] | FetchError>[] = [];

        selectedActuators.forEach((value, actuatorIdentifier) => {
            const ranges = selectedFilters.map(filter => getDateRangeForFilter(filter)).filter(Boolean);
            const fromValues = ranges.map(dr => dr?.from).filter(Boolean).join(',');
            const toValues = ranges.map(dr => dr?.to).filter(Boolean).join(',');

            let url = `${endpoints.actuatorhistory}/${encodeURIComponent(actuatorIdentifier)}`;
            if (fromValues && toValues) {
                url += `/${fromValues}/${toValues}`;
            }

            const promise = api.get<ActuatorHistoryDto[][]>(url)
                .then(response => {
                    if (response.data) {
                        var data = response.data;
                        data.forEach(data => data.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()));
                        return data.map((data, index) => {
                            return data.map((entry: any) => {
                                return {
                                    ...entry,
                                    delta: Number((entry.value - data[0].value).toFixed(entry.decimals ?? 2)),
                                    actuatorIdentifier: actuatorIdentifier
                                };
                            });
                        });
                    }
                    return [];
                })
                .catch(error => ({ error, actuatorIdentifier }));
            promises.push(promise);
        });

        try {
            const results = await Promise.all(promises);
            const combinedData: { [key: string]: any } = {};
            const errors: FetchError[] = [];
            const allKeys: string[] = []
            selectedActuatorsDelta.clear();

            results.forEach(result => {
                if ('error' in result) {
                    errors.push(result);
                    return;
                }

                result.forEach((subResult, index) => {
                    var delta = subResult[subResult.length - 1].delta;
                    var actuatorIdentifier = subResult[0].actuatorIdentifier;
                    selectedActuatorsDelta.set(actuatorIdentifier, delta);

                    subResult.forEach(entry => {
                        const timestamp = entry.createdDate;
                        const actuatorKey = `${entry.unitName} ${entry.actuatorName} (${index + 1})`;

                        if (!allKeys.includes(actuatorKey)) {
                            allKeys.push(actuatorKey)
                        }

                        if (!combinedData[timestamp]) {
                            combinedData[timestamp] = { name: timestamp };
                        }

                        combinedData[timestamp][actuatorKey] = entry.value;
                        combinedData[timestamp][`delta`] = entry.delta;
                    });
                });
            });

            const combinedActuatorHistory = Object.keys(combinedData).sort().map(timestamp => combinedData[timestamp]);
            setLineKeys(allKeys);

            setCombinedActuatorHistory(combinedActuatorHistory);

            if (errors.length > 0) {
                console.error("Errors occurred while fetching data:", errors);
            }
        } catch (error) {
            console.error("An error occurred while fetching actuator data:", error);
        }
    };

    const toggleChartType = () => {
        setChartType(chartType == 'bar' ? 'line' : 'bar');
    }

    const convertToCSV = (data: any[]): string => {
        if (data.length === 0) return '';

        const headers = 'Date,Name,Value\n';
        const rows = data.map(item => {
            const date = item.name;
            const entries = Object.entries(item);
            const secondEntry = entries.find(([key]) => key !== 'name');
            if (!secondEntry) return '';
            const [name, value] = secondEntry;
            return `${date},${name},${value}`;
        }).join('\n');

        return headers + rows;
    };

    const downloadCSV = () => {
        const csv = convertToCSV(combinedActuatorHistory);
        if (!csv) {
            alert(t("noDataToExport"));
            return;
        }

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "export.csv";
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const chart = () => {
        if (combinedActuatorHistory.length === 0) {
            return (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <p><strong>{t("noDataAvailableMessage")}</strong></p>
                </div>
            );
        }

        return (
            chartType == 'line' ?
                <div style={{ width: '100%', height: '400px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            data={combinedActuatorHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {lineKeys.map((key, index) => (
                                <Line
                                    key={key}
                                    type="monotone"
                                    dataKey={key}
                                    stroke={getRandomColor() || "#82ca9d"}
                                    name={key}
                                    connectNulls
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                :
                <div style={{ width: '100%', height: '100%' }}>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            data={combinedActuatorHistory}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            {/* <Tooltip content={<CustomTooltip />} /> */}
                            <Tooltip />
                            <Legend />
                            {lineKeys.map((key, index) => (
                                <Bar
                                    key={key}
                                    type="monotone"
                                    dataKey={key}
                                    fill={getRandomColor() || "#82ca9d"}
                                    name={key}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
        );
    };

    const CustomTooltip = ({ active, payload, label }: TooltipProps<string, string>) => {
        const { t } = useTranslation();
        const date = new Date(label);
        if (active && payload && payload.length) {
            return (
            <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                <p className="label">{`${date.toLocaleTimeString(locale)} ${date.toLocaleDateString(locale)}`}</p>
                <p style={{color: payload[0].color ?? "black"}}>{`${payload[0].dataKey}`}</p>
                <p>{`${t("value")}: ${payload[0].value}`}</p>
                {/* <p>{`${t("delta")}: ${payload[0].payload["delta"]}`}</p> */}
            </div>
            );
        }
        return null;
    };

    function getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    return <>
        <Row className="mb-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
            <Col sm={10}>
                <Form.Control
                    as="select"
                    multiple
                    value={Array.from(dropdownSelection)}
                    style={{ height: '30vh' }}
                    onChange={(e) => {
                        const target = e.target as unknown as HTMLSelectElement;
                        setDropdownSelection(Array.from(target.selectedOptions).map(o => o.value));
                    }}
                >
                    {
                        (function () {
                            return actuators.sort((a, b) => a.unitName.localeCompare(b.unitName))
                                .map(extendedActuator => {
                                    const actuatorName = `${extendedActuator.name}`;
                                    const unitName = `${extendedActuator.unitName}`;
                                    const gdasGuidAddress = `${extendedActuator.gdasGuid}/${extendedActuator.address}`;
                                    const physicalUnit = extendedActuator.physicalUnit ?? '';

                                    return (
                                        <option key={gdasGuidAddress} value={gdasGuidAddress + "#.#" + unitName + "#.#" + actuatorName}>
                                            {unitName + " " + actuatorName}
                                        </option>
                                    );
                                });
                        })()
                    }
                </Form.Control>
            </Col>
            <Col xs="auto">
                <Button
                    variant="primary"
                    onClick={() => {
                        dropdownSelection.forEach(selection => {
                            if (!selectedActuators.has(selection)) {
                                var option = selection.split("#.#");
                                setSelectedActuators(new Map(selectedActuators.set(option[0], option[1] + "#.#" + option[2])));
                            }
                        });
                    }}
                >
                    {t("add")}
                </Button>
            </Col>
        </Row>
        <Row>
            {Array.from(selectedActuators).map(([topic, value], index) => {
                var [gdasGuid, address] = topic.split('/ADDRESS-VALUE');
                var actuator = actuators.find(a => a.gdasGuid === gdasGuid && a.address.includes(address));
                return (
                    <Form.Group as={Row} key={index} className="align-items-center" style={{ margin: "0.5rem 0" }}>
                        <Col sm={10}>
                            <Form.Label>{`${actuator?.unitName} ${actuator?.name}`}</Form.Label>
                        </Col>
                        <Col sm={2}>
                            <Button
                                variant="danger"
                                onClick={() => removeActuator(topic)}
                            >
                                {t("remove")}
                            </Button>
                        </Col>
                    </Form.Group>
                );
            })}
        </Row>
        <Row>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                }}
            >
                {filterOptions.map((option) => (
                    <div key={option.value} style={{ flex: "1 1 33%", padding: "5px" }}>
                        <Form.Check
                            inline
                            label={option.label}
                            name={`dataFilter-all`}
                            type="checkbox"
                            id={`checkbox-all-${option.value}`}
                            value={option.value}
                            checked={selectedFilters.includes(option.value)}
                            onChange={() => handleFilterChange(option.value)}
                        />
                    </div>
                ))}
            </div>
            <Row>
            <Col sm={3} md={3} lg={3} style={{display: "flex", flexDirection: "column", marginTop: "1rem"}}>
              <Row>
                <p><strong>{t("deltaFirstLast")}</strong></p>
              </Row>
              <Row>
                {
                    Array.from(selectedActuatorsDelta).map(([actuatorIdentifier, delta], index) => {
                        return (
                            <Col key={index}>
                                <p><strong>{(selectedActuators.get(actuatorIdentifier))?.replace("#.#", " ")}: {delta}</strong></p>
                            </Col>
                        );
                    })
                }
                </Row>
                </Col>
                <Col>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "stretch",
                            justifyContent: "center",
                            padding: "1rem",
                        }}
                    >
                        <label
                        style={{
                            marginRight: "1%",
                            display: "flex",
                            alignItems: "center",
                        }}
                        >
                        {t("from")}:
                        </label>
                        <input
                            type="date"
                            disabled={!selectedFilters?.includes("custom")}
                            onChange={(e) =>
                                handleDateRangeChange(
                                    e.target.value,
                                    dateRange?.to
                                )
                            }
                        />
                        <label
                        style={{
                            margin: "0 1%",
                            display: "flex",
                            alignItems: "center",
                        }}
                        >
                        {t("to")}:
                        </label>
                        <input
                            type="date"
                            disabled={!selectedFilters?.includes("custom")}
                            onChange={(e) =>
                                handleDateRangeChange(
                                    dateRange?.from,
                                    e.target.value
                                )
                            }
                        />
                        <Button style={{ marginLeft: "2%" }} onClick={() => fetchAllActuatorData()}>{t("fetch")}</Button>
                        <Button style={{ marginLeft: "2%" }} onClick={() => toggleChartType()}>{t("switchChartType")}</Button>
                        <Button style={{ marginLeft: '2%' }} onClick={() => downloadCSV()}>{t('downloadCSV')}</Button>
                    </div>
                </Col>
            </Row>
        </Row>
        <Row>
            {chart()}
        </Row>
    </>

    interface DateRange {
        from?: string;
        to?: string;
    }

    interface FetchError {
        error: any;
        actuatorIdentifier: string;
    }
}