import { useTranslation } from "react-i18next";

const StateViewWidget: React.FC<StateViewWidgetProps> = ({ value, labels, colours }) => {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h1>
                <strong>{t(value ? labels[Number(value)] : '')}</strong>
            </h1>
            <div style={{
                width: '50px',
                height: '50px',
                backgroundColor: value ? colours[Number(value)] : 'white',
                borderRadius: '50%',
            }}></div>
        </div>
    );
}

interface StateViewWidgetProps {
    value: string;
    labels: string[];
    colours: string[];
}

export default StateViewWidget;