import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { BuildingIdentifier, Unit } from '../../../models/project.models';
import ActuatorAccordion from './ActuatorAccordion';
import api, { endpoints } from '../../../utils/api';
import { useTranslation } from 'react-i18next';

const UnitAccordion: React.FC<UnitAccordionProps> = ({ building }) => {
  const { t } = useTranslation();
  const [units, setUnits] = useState<Unit[]>([]);

  const fetchUnits = async () => {
    const response = await api.get(`${endpoints.units}/unitName/${building.buildingId}`);
    setUnits(response.data);
  };

  return (
    <Accordion.Item eventKey={building.buildingId}>
      <Accordion.Header onClick={() => {
        if (units.length === 0) fetchUnits();
        }}>{building.buildingName}</Accordion.Header>
      <Accordion.Body>
        <Accordion defaultActiveKey="0">
          <h4>{t("unitList")}</h4>
          {units.map((unit) => (
            <ActuatorAccordion key={unit.id} unit={unit} />
          ))}
        </Accordion>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default UnitAccordion;


interface UnitAccordionProps {
  building: BuildingIdentifier;
}