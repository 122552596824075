import { useEffect, useRef, useState } from 'react';
import AuthenticationContext from './components/auth/AuthenticationContext';
import AppRouter from './components/elements/AppRouter';
import FeedbackToast from './components/elements/FeedbackToast';
import api, { endpoints } from './utils/api';
import './resources/leaflet/leaflet.css';

import './App.scss';
import { User } from './models/auth.models';

function App() {

    const [user, setUser] = useState<User | null | undefined>(() => {
        // On component mount, retrieve user from localStorage
        const persistedValue = localStorage.getItem("user");
        return persistedValue ? JSON.parse(persistedValue) : null;
    });
    const [currentUser, setCurrentUser] = useState<User | null>(null)

    const [isLoggedIn, setIsLoggedIn] = useState(() => {
        // On component mount, check if isLoggedIn exists in localStorage
        const persistedValue = localStorage.getItem("isLoggedIn");
        return persistedValue ? JSON.parse(persistedValue) : false;
    });

    const updateIsLoggedIn = (value: boolean) => {
        setIsLoggedIn(value);
        localStorage.setItem("isLoggedIn", JSON.stringify(value));
    };

    const updateUser = (userData: User | null | undefined) => {
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
    };

    const isAdmin = () => {
        return currentUser?.roles?.includes('SuperAdmin') ?? false;
    };

    const isProjectOwner = () => {
        return currentUser?.roles?.includes('ProjectOwner') ?? false;
    };

    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState("")
    const [mode, setMode] = useState("")
    const [duration, setDuration] = useState<number>(0)

    const meRef = useRef<string>("")

    const g = async () => {
        try {
            const response = await api.get(endpoints.accounts + '/user')

            if (response) {
                // authorization
                setIsLoggedIn(true)
                setCurrentUser(response.data)
                // fetch own mail
                if (!meRef.current) {
                    meRef.current = response.data.email
                }
            } else {
                console.log("no response")
            }
        } catch (e) {
            setIsLoggedIn(false)
        }
    }

    useEffect(() => {
        g()
    }, [])

    useEffect(() => {
        g()
    }, [isLoggedIn])

    return (
        <AuthenticationContext.Provider
            value={{
                isLoggedIn,
                updateIsLoggedIn,
                user,
                updateUser,
                meRef,
                success,
                setSuccess,
                message,
                setMessage,
                mode,
                setMode,
                duration,
                setDuration,
                currentUser,
                isAdmin,
                isProjectOwner
            }}>
            <FeedbackToast />
            <AppRouter />
        </AuthenticationContext.Provider>

    )
}

export default App