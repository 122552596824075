import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de_dashboard from './dashboard/de_dashboard.json';
import en_dashboard from './dashboard/en_dashboard.json';
import de_error from './error/de_error.json';
import en_error from './error/en_error.json';
import de_projectmanagement from './projectmanagement/de_projectmanagement.json';
import en_projectmanagement from './projectmanagement/en_projectmanagement.json';
import de_usermanagement from './usermanagement/de_usermanagement.json';
import en_usermanagement from './usermanagement/en_usermanagement.json';
import de_warning from './warning/de_warning.json';
import en_warning from './warning/en_warning.json';
import de_auth from './auth/de_auth.json';
import en_auth from './auth/en_auth.json';
import de_wellcontrol from './wellcontrol/de_wellcontrol.json';
import en_wellcontrol from './wellcontrol/en_wellcontrol.json';
import de_footer from './footer/de_footer.json';
import en_footer from './footer/en_footer.json';
import de_general from './general/de_general.json';
import en_general from './general/en_general.json';
import de_widgets from './widgets/de_widgets.json';
import en_widgets from './widgets/en_widgets.json';
import de_mail from './mail/de_mail.json';
import en_mail from './mail/en_mail.json';
import de_history from './history/de_history.json';
import en_history from './history/en_history.json';


// Load all translations
const resources = {
    en: { translation: {...en_dashboard, ...en_error, ...en_projectmanagement,
        ...en_usermanagement, ...en_warning, ...en_auth, ...en_wellcontrol,
        ...en_footer, ...en_general, ...en_widgets, ...en_mail, ...en_history}},
    de: { translation: {...de_dashboard, ...de_error, ...de_projectmanagement,
        ...de_usermanagement, ...de_warning, ...de_auth, ...de_wellcontrol,
        ...de_footer, ...de_general, ...de_widgets, ...de_mail, ...de_history}},
}

// Initialize i18n
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,
    });

// Make i18n available
export default i18n;