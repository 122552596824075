import Login from './auth/Login';
import logo from '../resources/logo.png';
import white_logo from '../resources/white_logo.png';
import background_login from '../resources/background_login.png';
import RequestPasswordResetModel from './auth/RequestPasswordResetModel';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import '../styles/LoginPage.scss';
import Footer from './footer/Footer';
import { Col, Container, Row } from 'react-bootstrap';


export default function LoginPage() {
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const { t } = useTranslation();

    const togglePopup = () => {
        setShowPasswordReset(!showPasswordReset)
    }

    return (
        <>
            <Container fluid className="min-vh-100 d-flex flex-column">
                <Row className="flex-grow-1">
                    <Col md={8} className="p-0 d-none d-md-block">
                        <div className="h-100" style={{ backgroundImage: `url(${background_login})`, backgroundSize: 'cover', backgroundPosition: 'center center', backgroundRepeat: 'no-repeat' }}>
                        </div>
                    </Col>
                    <Col md={4} className="d-flex flex-column align-items-center justify-content-center" style={{ backgroundColor: 'black' }}>
                            <div className="mb-5 text-center">
                                <img src={white_logo} width="80%" alt="logo" />
                            </div>
                            <Login />
                            <div className="mt-4 text-center">
                                <u onClick={togglePopup} style={{ color: "white", cursor: "pointer", textDecoration: "none" }}>{t("forgotPassword")}</u>
                            </div>
                            {showPasswordReset && <RequestPasswordResetModel closePopup={togglePopup} />}
                    </Col>
                </Row>
                <Row md={12}>
                    <Footer />
                </Row>
            </Container>
        </>
    )
}