import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

// Custom hook for returning validation schemas
export const useValidationSchemas = () => {
  const { t } = useTranslation();

  const nullableOnlyLetterValidation = Yup.string()
    .matches(/^[A-Za-z]+$/, t('onlyLetters'))
    .nullable();

  const nullableOnlyNumberValidation = Yup.string()
  .matches(/^[-]?[0-9]+(\.[0-9]{1,2})?$/, t('onlyNumbers'))
  .nullable();

  const nameValidation = Yup.string()
      .required(t("required"))
      .matches(/^[A-Za-z]+$/, t('onlyLetters'));

  const emailValidation = Yup.string()
      .required(t('required'))
      .email(t('validEmail'));

  const passwordValidation = Yup.string()
      .required(t("required"))
      .min(8, t("tooShort"))
      .matches(/[0-9]/, t("oneNumber"))
      .matches(/[a-zA-Z]/, t("oneLetter"))
      .matches(/[A-Z]/, t("oneUppercaseLetter"))
      .matches(/(?=.*[^\da-zA-Z])/, t("oneSpecial"));

  const confirmPasswordValidation = Yup.string()
      .required(t("confirmPassword"))
      .oneOf([Yup.ref('password')], t("passwordsMatch"));

  const currentPasswordValidation = Yup.string().required(t("required"));

  return {
    nullableOnlyLetterValidation,
    nullableOnlyNumberValidation,
    nameValidation,
    emailValidation,
    passwordValidation,
    confirmPasswordValidation,
    currentPasswordValidation,
  };
};
