import { CalculatorIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function CalculatorCard(props: CalculatorCardProps) {
    const { t } = useTranslation();
    return (
        <div className="card" style={{ height: '100%', width: '100%', padding: '1rem', border: '0px', borderRadius: '0px', cursor: "pointer" }} onClick={props.onClick}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h2>{t("writeValues")}</h2>
                    <CalculatorIcon style={{ width: '5rem', height: '5rem' }} />
                </div>
            </div>
        </div>
    );
};

interface CalculatorCardProps {
    onClick: () => void;
}