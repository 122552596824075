import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticationContext from "../../auth/AuthenticationContext";
import api, { endpoints } from "../../../utils/api";
import MapWithPinActuatorCards from "../../elements/MapWithPinActuatorCards";
import { Building } from "../../../models/project.models";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { moduleConfig, moduleRegistry } from "../ModuleRegistry";
import { wellZoneBuildingsRegistry } from '../BuildingRegisty';
import MapWithFixedBuildingActuatorCards from "../../elements/MapWithFixedBuildingActuatorCards";

export default function WellControl() {
  const { t } = useTranslation();
  var { zoneId } = useParams() as { zoneId: string };
  const { currentUser } = useContext(AuthenticationContext);
  const { pinStatusActuatorName, pinStatusColours } = moduleConfig['29f2d24a-b07b-4721-bda6-9a02f0ede08f'] || {};
  const navigate = useNavigate();

  const location = useLocation();
  const [pins, setPins] = useState<Building[]>([]);
  const [predefinedActuatorOrder, setPredefinedActuatorOrder] = useState<string[]>([]);
  var selectedBuilding = location.state?.selectedBuilding as Building;
  var selectedZone = location.state?.filter as string;

  const actuatorOrder = [
    "Betriebsmeldung:hidden",
    "Status Wahlschalter Betriebsart",
    "Status Freigabe",
    "Status Betrieb Pumpe",
    "Freigabe Steuerung",
    "Spannung",
    "Strom",
    "Betriebsstunden Total",
    "Fördermenge Total",
    "divider:",
    "Status Sanftanlasser",
    "Status Motorschutz",
    "Status Pumpenstrom",
    "max. Wasserstand",
    "Wartung Brunnen",
    "aktuelle Gesamtleistung",
    "Energieverbrauch",
    "Temperatur Steuerungskasten",
    "Wasserstand",
    "Spannung L1",
    "Leistung L1",
    "Strom L1",
    "Spannung L2",
    "Leistung L2",
    "Strom L2",
    "Spannung L3",
    "Leistung L3",
    "Strom L3",
  ];

  const overAllOrder = [
    "text:Identification:allZones",
    "empty",
    "empty",
    "/2000/5/0",
    "/2000/7/0",
    "empty"
  ];

  const updateBuilding = (building: Building) => {
    selectedBuilding = building;
  };

  const zone1order = [
    "text:Identification:" + t("Zone") + " 1",
    "empty",
    "/2000/9/0",
    "/2000/5/1",
    "/2000/7/1",
    "empty"
  ];

  const zone2order = [
    "text:Identification:" + t("Zone") + " 2",
    "empty",
    "/2000/9/1",
    "/2000/5/2",
    "/2000/7/2",
    "empty"
  ];

  const zone3order = [
    "text:Identification:" + t("Zone") + " 3",
    "empty",
    "/2000/9/2",
    "/2000/5/3",
    "/2000/7/3",
    "empty"
  ];

  useEffect(() => {

    if (selectedZone !== undefined) zoneId = "-1";

    switch (zoneId) {
      case '1':
        setPredefinedActuatorOrder(zone1order);
        break;
      case '2':
        setPredefinedActuatorOrder(zone2order);
        break;
      case '3':
        setPredefinedActuatorOrder(zone3order);
        break;
      case undefined:
      default:
        if (selectedBuilding === undefined) {
          setPredefinedActuatorOrder(overAllOrder);
        }
        else {
          setPredefinedActuatorOrder(actuatorOrder);
        }
        break;
    }
  }, [zoneId, selectedBuilding]);

  useEffect(() => {
    //get active modules for user
    try {
      api
        .get(endpoints.buildings + "/moduleBuildings", {
          params: {
            moduleIds: '29f2d24a-b07b-4721-bda6-9a02f0ede08f' //brunnensteuerung
          }
        })
        .then((response) => {
          setPins(response.data);
        });
    } catch (error) {
      //console.error("Error fetching modules:", error);
    }
  }, [currentUser]);

  const onPinSelected = (pin: Building, filter: string | undefined) => {
    const moduleId = pin.module.id;
    const module = moduleRegistry[moduleId];
    if (module) {
        navigate(module.path + (filter !== undefined ? ("/" + filter) : ""), { state: { selectedBuilding: pin, filter } });
    }
  };

  return (
    <>
      <h1>{t("Well Control")}</h1>
      {
        zoneId === undefined || selectedZone !== undefined
        ?
          (
            selectedBuilding !== undefined || selectedZone !== undefined
            ?
              <MapWithPinActuatorCards pins={selectedZone === undefined ?  pins : pins.filter(pin => wellZoneBuildingsRegistry[selectedZone].includes(pin.name))} actuatorOrder={predefinedActuatorOrder} pinStatusActuatorName={pinStatusActuatorName} pinStatusColours={pinStatusColours} preselectedPin={selectedBuilding} onPinSelected={updateBuilding} callingComponent="well-control"></MapWithPinActuatorCards>
            :
              <MapWithFixedBuildingActuatorCards buildingId="f59cd020-9093-42b0-97d5-add4831210cc" actuatorOrder={overAllOrder} pins={zoneId ? pins.filter(pin => wellZoneBuildingsRegistry[zoneId].includes(pin.name)) : pins} onPinSelected={onPinSelected} callingComponent="well-control" zone={zoneId}></MapWithFixedBuildingActuatorCards>
          )
        :
          <MapWithFixedBuildingActuatorCards buildingId="f59cd020-9093-42b0-97d5-add4831210cc" actuatorOrder={predefinedActuatorOrder} pins={zoneId ? pins.filter(pin => wellZoneBuildingsRegistry[zoneId].includes(pin.name)) : pins} onPinSelected={onPinSelected} callingComponent="well-control" zone={zoneId} ></MapWithFixedBuildingActuatorCards>
      }
    </>
  );
}