export const piezoZoneBuildingsRegistry: { [key: string]: string[] } = {
    'all':
        [
            "2100/93/0",
            "2100/90/0",
            "2100/91/0",
            "2100/92/0",
            "VC15",
            "VD61",
            "VE10",
            "VE60",
            "VG20",
            "VG60",
            "VG96",
            "VB63",
            "VB76",
            "VC55",
            "VC70",
            "VB01",
            "VC20",
            "VC45"
        ],
    '1':
        [
            "2100/90/0",
            "VC15",
            "VD61",
            "VE10",
            "VE60",
            "VG20",
            "VG60",
            "VG96"
        ],
    '2':
        [
            "2100/91/0",
            "VB63",
            "VB76",
            "VC55",
            "VC70"
        ],
    '3':
        [
            "2100/92/0",
            "VB01",
            "VC20",
            "VC45"
        ]
};

export const wellZoneBuildingsRegistry: { [key: string]: string[] } = {
    'all':
        [
            "FB10",
            "FB12",
            "FB18",
            "FB24",
            "FB26",
            "FB28",
            "FB30",
            "FB32",
            "FB34",
            "FB09",
            "FB11",
            "FB25",
            "FB27",
            "FB29",
            "FB01",
            "FB02",
            "FB03",
            "FB04",
            "FB05",
            "FB06",
            "FB07",
            "FB08",
            "FB14",
            "FB16",
            "FB23"
        ],
    '1':
        [
            "FB10",
            "FB12",
            "FB18",
            "FB24",
            "FB26",
            "FB28",
            "FB30",
            "FB32",
            "FB34",
            "2000/9/0"
        ],
    '2':
        [
            "FB09",
            "FB11",
            "FB25",
            "FB27",
            "FB29",
            "2000/9/1"
        ],
    '3':
        [
            "FB01",
            "FB02",
            "FB03",
            "FB04",
            "FB05",
            "FB06",
            "FB07",
            "FB08",
            "FB14",
            "FB16",
            "FB23",
            "2000/9/2"
        ]
};

export const allZonesAndInterventionAreas: { [key: string]: string[] } = {
    'all':
        [
            "FB10",
            "FB12",
            "FB18",
            "FB24",
            "FB26",
            "FB28",
            "FB30",
            "FB32",
            "FB34",
            "FB09",
            "FB11",
            "FB25",
            "FB27",
            "FB29",
            "FB01",
            "FB02",
            "FB03",
            "FB04",
            "FB05",
            "FB06",
            "FB07",
            "FB08",
            "FB14",
            "FB16",
            "FB23",
            "2000/9/0",
            "2100/93/0",
            "2100/90/0",
            "2100/91/0",
            "2100/92/0",
            "VC15",
            "VD61",
            "VE10",
            "VE60",
            "VG20",
            "VG60",
            "VG96",
            "VB63",
            "VB76",
            "VC55",
            "VC70",
            "VB01",
            "VC20",
            "VC45"
        ],
    '1':
        [
            "FB10",
            "FB12",
            "FB18",
            "FB24",
            "FB26",
            "FB28",
            "FB30",
            "FB32",
            "FB34",
            "2100/90/0",
            "VC15",
            "VD61",
            "VE10",
            "VE60",
            "VG20",
            "VG60",
            "VG96"
        ],
    '2':
        [
            "FB09",
            "FB11",
            "FB25",
            "FB27",
            "FB29",
            "2000/9/1",
            "2100/91/0",
            "VB63",
            "VB76",
            "VC55",
            "VC70"
        ],
    '3':
        [
            "FB01",
            "FB02",
            "FB03",
            "FB04",
            "FB05",
            "FB06",
            "FB07",
            "FB08",
            "FB14",
            "FB16",
            "FB23",
            "2000/9/2",
            "2100/92/0",
            "VB01",
            "VC20",
            "VC45"
        ]
};

export const findBuildingInZones = (callingComponent: string, address: string, isGlobal: boolean): string[] => {
    const zones = ['1', '2', '3'];
    const foundInZones: string[] = [];
    var zoneRegistry: { [key: string]: string[] } = {};
    if ((callingComponent !== 'piezo' && callingComponent !== 'well-control') || isGlobal) {
        zoneRegistry = allZonesAndInterventionAreas;
    }
    else{
        zoneRegistry = callingComponent === 'piezo' ? piezoZoneBuildingsRegistry : wellZoneBuildingsRegistry;
    }

    zones.forEach(zone => {
        if (zoneRegistry[zone].includes(address)) {
            foundInZones.push(zone);
        }
    });

    return foundInZones;
};
