import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { MenuItem } from "../Home";
import gdas_logo_initials from "../../resources/logo_gdas_initials.png";

export default function SideBar({
  items,
  activeItem,
}: SideBarProps) {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // 60000 milliseconds = 1 minute

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="sidebar">
      <div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "1.5rem" }}>
          <img src={gdas_logo_initials} alt="GDAS" style={{ width: "75px", height: "75px" }} />
        </div>
      </div>
      <div className="sidebar__date-time">
        <h1>{currentDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h1>
        <p>{currentDateTime.toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1.$2.$3')}</p>
      </div>

      <ul className='navlist' style={{ overflowY: 'auto' }} >
        {items.map((i: any, index: number) => {
          const isActive = i.name === activeItem.name;
          const isSubItem = i.isSubItem && i.isSubItem === true;
          const hasHandler = i.handler && typeof i.handler === 'function';

          return (
            <li key={index} className={`${isActive && "active"} ${isSubItem && 'sidebar-subitem'}`} onClick={hasHandler ? i.handler : null}>
              <Link to={`${i.to}`}>
                {" "}
                {i?.icon}
                <span>{i.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

interface SideBarProps {
  items: MenuItem[];
  activeItem: MenuItem;
}
