import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SimpleToggle: React.FC<SimpleToggleProps> = ({ id, value, labels, onToggle }) => {
    const { t } = useTranslation();

    return (
        <Form.Check
            type="switch"
            id={id}
            checked={value === '1'}
            onChange={(e) => {
                onToggle();
            }}
            label={t(labels[Number(value)])}
        />
    );
}

interface SimpleToggleProps {
    id: string;
    value: string;
    labels: string[];
    onToggle: () => void;
}

export default SimpleToggle;