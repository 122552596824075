import { userCredentials } from "../../models/auth.models";
import { useContext, useEffect, useState } from "react";
import AuthForm from "./AuthForm";
import DisplayErrors from "../elements/DisplayErrors";
import { useNavigate, useLocation } from "react-router-dom";
import AuthenticationContext from "./AuthenticationContext";
import api, { endpoints } from "../../utils/api";
import PasswordResetModal from "./PasswordResetModal";
import useFeedback from "../elements/customHooks/useFeedback";
import { useTranslation } from "react-i18next";

export default function Login() {
    const { t } = useTranslation();
    const [errors, setErrors] = useState<string[]>([])
    const navigate = useNavigate()
    const location = useLocation()
    const { isLoggedIn, updateIsLoggedIn } = useContext(AuthenticationContext)

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false)
    const [emailFromUrl, setEmailFromUrl] = useState<string | null>(null)
    const [tokenFromUrl, setTokenFromUrl] = useState<string | null>(null)

    const { done } = useFeedback()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)

        const emailConfirmationStatus = searchParams.get('emailConfirmed')
        if (emailConfirmationStatus === 'true') {
            done(t("emailConfirmed"), "success", 3000)
        }

        const emailFromQuery = searchParams.get('email')
        const tokenFromQuery = searchParams.get('token')

        if (emailFromQuery && tokenFromQuery) {
            setEmailFromUrl(emailFromQuery)
            setTokenFromUrl(tokenFromQuery)
            setShowResetPasswordModal(true)
        }

    }, [location.search])

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    const handleCloseResetPasswordModal = () => {
        setShowResetPasswordModal(false);
        navigate('/', { replace: true }) // to clean up URL parameters
    };

    async function login(credentials: userCredentials) {
        try {
            const response = await api.post(endpoints.accounts + '/login', credentials)
            if (response.status === 200) {
                updateIsLoggedIn(true)
            }
        } catch (error) {
            const errorMessage = (error as Error).message;
            setErrors([errorMessage]);
        }
    }

    return (
        <>
            {showResetPasswordModal ?
                <PasswordResetModal
                    email={emailFromUrl || ''}
                    token={tokenFromUrl || ''}
                    handleClose={handleCloseResetPasswordModal}
                    show={showResetPasswordModal} />
                :
                <div id="login">
                    <h3 style={{color: "white"}}>{t("login")}</h3>
                    <DisplayErrors errors={errors} />
                    <AuthForm
                        model={{ email: '', password: '' }}
                        onSubmit={async values => await login(values)} />
                </div>
            }
        </>
    )
}