import React from 'react';
import { Accordion } from 'react-bootstrap';
import { BuildingIdentifier } from '../../../models/project.models';
import UnitAccordion from './UnitAccordion';

const BuildingAccordion: React.FC<BuildingAccordionProps> = ({ buildings }) => {

  return (
    <Accordion defaultActiveKey="0">
      {buildings.length > 0 && buildings.map((building) => (
        <UnitAccordion key={building.buildingId} building={building} />
      ))}
    </Accordion>
  );
};

export default BuildingAccordion;


interface BuildingAccordionProps {
  buildings: BuildingIdentifier[];
}