import { MapContainer, Marker, TileLayer, useMap, Popup, Tooltip } from "react-leaflet";
import React, { useEffect, useState } from "react";
import BuildingActuators from "./BuildingActuators";
import api, { endpoints } from "../../utils/api";
import mqtt from 'mqtt';
import { Actuator, Building, ExtendedActuator, Unit } from "../../models/project.models";
import { moduleConfig } from "../modules/ModuleRegistry";
import L, { Icon } from "leaflet";
import { useTranslation } from "react-i18next";

function MapView() {
  const map = useMap();

  useEffect(() => {
    map.setView({ lat: 46.297627, lng: 7.903123 }, 16);
    map.scrollWheelZoom.enable();
  }, []);

  return null;
}

const MapWithFixedBuildingActuatorCards: React.FC<MapWithFixedBuildingActuatorCardsProps> = ({
  buildingId,
  actuatorOrder,
  pins,
  onPinSelected,
  callingComponent,
  zone
}) => {
  const [building, setBuilding] = useState<Building>();
  const [writableActuators, setWritableActuators] = useState<ExtendedActuator[]>([]);
  const [mqttMessages, setMqttMessages] = useState<{ [key: string]: string }>({});

  const { t } = useTranslation();

  const getMarkerIcon = ((pin: Building, statusUnit: Unit, statusActuator: Actuator): Icon => {
    const defaultIconOptions = {
      iconAnchor: [11, 40] as [number, number],
      popupAnchor: [0, -40] as [number, number],
      iconSize: [22, 40] as [number, number],
      shadowUrl: require('../../resources/leaflet/images/marker-shadow.png'),
      shadowSize: [22, 40] as [number, number],
      shadowAnchor: [5, 40] as [number, number]
    };

    var defaultIcon = new L.Icon({
      ...defaultIconOptions,
      iconUrl: require(`../../resources/markers/well-control/marker-icon-blue.png`),
      iconRetinaUrl: require(`../../resources/markers/well-control/marker-icon-blue.png`)
    });

    if (!pin.module || !moduleConfig[pin.module.id] || !(moduleConfig[pin.module.id].pinStatusActuatorName) || !statusUnit || !statusActuator) return defaultIcon;

    const statusValue = mqttMessages[`${statusUnit.gdasGuid}/${statusActuator.address}`];

    const mqttValue = isNaN(parseFloat(statusValue)) ? -1 : parseFloat(statusValue);
    const colour = moduleConfig[pin.module.id].pinStatusColours ? (moduleConfig[pin.module.id].pinStatusColours as any)[mqttValue.toString()] || 'blue' : 'blue';

    //PIEZO
    if (pin.module.id === 'a18936c1-208f-463f-b864-a392e76b6f93') {
      // Use local icons for Well Control
      return new L.Icon({
        iconAnchor: [11, 40] as [number, number],
        popupAnchor: [0, -40] as [number, number],
        iconSize: [22, 40] as [number, number],
        shadowUrl: require('../../resources/leaflet/images/marker-shadow.png'),
        shadowSize: [22, 40] as [number, number],
        shadowAnchor: [5, 40] as [number, number],
        iconUrl: require(`../../resources/markers/piezo/piezo-dark-${colour}.png`),
        iconRetinaUrl: require(`../../resources/markers/piezo/piezo-dark-${colour}.png`),
      });
    }

    return new L.Icon({
      ...defaultIconOptions,
      iconUrl: require(`../../resources/markers/well-control/marker-icon-${colour}.png`),
      iconRetinaUrl: require(`../../resources/markers/well-control/marker-icon-${colour}.png`)
    });
  });

  useEffect(() => {
    const fetchBuilding = async () => {
      try {
        await api.get(endpoints.buildings + `/building/${buildingId}`)
          .then((response) => {
            setBuilding(response.data);

            fetchWritableActuators();

          });
      } catch (error) {
        // console.error("Failed to fetch building", error);
      }
    };

    const fetchWritableActuators = async () => {
      try {
        await api.post(endpoints.actuators + "/actuators/-1/")
          .then((response) => {
            setWritableActuators(response.data);
          });
      } catch (error) {
        // console.error("Failed to fetch writable actuators", error);
      }
    }

    fetchBuilding();
  }, [pins]);

  useEffect(() => {
    if (!pins || !pins[0]) return;

    const options = {
      username: pins[0].mqttUser,
      password: pins[0].mqttPass,
    };

    const client = mqtt.connect(pins[0].mqttServer, options);

    client.on('connect', () => {
      pins.forEach((building: Building) => {
        building.units.forEach((unit) => {
          client.subscribe(unit.gdasGuid + "/KeepAlive");
          unit.actuators.forEach((actuator) => {
            if (moduleConfig[building.module.id] && actuator.name === moduleConfig[building.module.id].pinStatusActuatorName) {
              try {
                client.subscribe(unit.gdasGuid + "/" + actuator.address);
              } catch (error) {
                console.log(error);
              }
            }
          });
        });
      });

      writableActuators.forEach((actuator) => {
        try {
          client.subscribe(actuator.gdasGuid + "/" + actuator.address);
        }
        catch (error) {
          console.log(error);
        }
      });
    });

    client.on('message', (topic, message) => {
      setMqttMessages((prevMessages) => ({
        ...prevMessages,
        [topic]: message.toString(),
      }));
    });

    return () => {
      client.end();
    };
  }, [building]);

  if (!building) {
    return <div>{t("loading")}</div>;
  }
  return (
    <>
      <div style={{ width: "100%", height: "50vh" }}>
        <MapContainer style={{ width: "100%", height: "100%" }}>
          <MapView />
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {pins.map((pin) => {
            const statusActuator = pin.units.flatMap((unit: Unit) => unit.actuators).find((actuator: Actuator) => actuator.name === moduleConfig[pin.module.id]?.pinStatusActuatorName);
            const statusUnit = pin.units.find((unit: Unit) => unit.actuators.includes(statusActuator));

            let mqttValue = -1;
            let tooltipMessage = "";

            if (statusUnit && statusActuator) {
              const mqttMessage = mqttMessages[`${statusUnit.gdasGuid}/${statusActuator.address}`];
              mqttValue = !isNaN(parseFloat(mqttMessage)) ? parseFloat(mqttMessage) : -1;
              tooltipMessage = moduleConfig[pin.module.id]?.pinStatusTooltipMessage ? moduleConfig[pin.module.id].pinStatusTooltipMessage[mqttValue] : "";
            }
            return (
              <Marker
                key={pin.id}
                position={[pin.latitude, pin.longitude]}
                icon={getMarkerIcon(pin, statusUnit, statusActuator)}
                eventHandlers={{
                  click: () => onPinSelected(pin, zone),
                }}
              >
                <Popup closeOnClick={false} closeButton={false}>
                  <div>
                    <h2>{pin.name}</h2>
                  </div>
                </Popup>
                {tooltipMessage &&
                  <Tooltip permanent={false} interactive={true}>
                    {statusUnit.name}: {t(tooltipMessage)}
                  </Tooltip>
                }
              </Marker>
            );
          })}
        </MapContainer>
      </div>
      {
        // all actuators from the master server
        <BuildingActuators key={buildingId} building={building} actuatorOrder={actuatorOrder} callingComponent={callingComponent} zone={zone}></BuildingActuators>
      }
    </>
  );
};

export default MapWithFixedBuildingActuatorCards;