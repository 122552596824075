import React, { useState, useEffect } from "react";
import mqtt from "mqtt";
import ActuatorCard from "./ActuatorCard";
import SimpleLineChart from "./SimpleLineChart";
import { Button, Col, Form, Row } from "react-bootstrap";
import api, { endpoints } from "../../utils/api";
import {
  ActuatorHistoryDto,
  BuildingActuatorsProps,
} from "../../models/actuator.models";
import { useTranslation } from "react-i18next";
import { Actuator, ExtendedActuator } from "../../models/project.models";
import TextCard from "./TextCard";
import CalculatorCard from "./CalculatorCard";
import PositiveAndNegativeBarChart, { ChartDataStructure } from "./PositiveAndNegativeBarChart";
import { findBuildingInZones, piezoZoneBuildingsRegistry, wellZoneBuildingsRegistry } from "../modules/BuildingRegisty";
import CalculatorModal from "./CalculatorModal";

const BuildingActuators: React.FC<BuildingActuatorsProps> = ({
  building,
  actuatorOrder,
  callingComponent,
  zone
}) => {
  const { t } = useTranslation();
  const [mqttMessages, setMqttMessages] = useState<{ [key: string]: string }>({});
  const [writableActuators, setWritableActuators] = useState<ExtendedActuator[]>([]);
  const [selectedActuators, setSelectedActuators] = useState<Set<string>>(new Set());
  const [actuatorHistory, setActuatorHistory] = useState<{ [key: string]: ChartDataStructure[]; }>({});
  const [dataLoadAttempts, setDataLoadAttempts] = useState<{ [key: string]: "notAttempted" | "dataLoaded" | "noData"; }>({});
  const [dateRanges, setDateRanges] = useState<{ [key: string]: DateRange }>({});
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [chartTypes, setChartTypes] = useState<{ [key: string]: 'line' | 'bar' }>({});
  const [showActuatorModal, setShowActuatorModal] = useState<boolean>(false);
  const [selectedActuatorsForModal, setSelectedActuatorsForModal] = useState<Map<string, string>>(new Map());
  const [selectedActuatorsDelta, setSelectedActuatorsDelta] = useState(new Map());
  const [dropdownSelection, setDropdownSelection] = useState<string[]>([]);
  const [filterCalculatorModalPages] = useState<string[]>(["overview"]);
  const locale = localStorage.getItem("i18nextLng") ?? "en";
  const isMobile = window.innerWidth <= 768;

  const filterOptions = [
    { label: t("onlyToday"), value: "today" },
    { label: t("yesterday"), value: "yesterday" },
    { label: t("thisWeek"), value: "week" },
    { label: t("lastWeek"), value: "lastWeek" },
    { label: t("thisMonth"), value: "month" },
    { label: t("lastMonth"), value: "lastMonth" },
    { label: t("thisYear"), value: "thisYear" },
    { label: t("lastYear"), value: "lastYear" },
    { label: t("allTime"), value: "all" },
    { label: t("custom"), value: "custom" },
  ];

  const filterLabels: { [key: string]: string } ={
    today: t("onlyToday"),
    yesterday: t("yesterday"),
    week: t("thisWeek"),
    lastWeek: t("lastWeek"),
    month: t("thisMonth"),
    lastMonth: t("lastMonth"),
    thisYear: t("thisYear"),
    lastYear: t("lastYear"),
    all: t("allTime"),
    custom: t("custom"),
};

  const handleFilterChange = (actuatorIdentifier: string, selectedValue: string) => {
    setSelectedFilters(prev => {
        const currentSelections = new Set(prev[actuatorIdentifier] || []);
        if (currentSelections.has(selectedValue)) {
            currentSelections.delete(selectedValue);
        } else {
            if (currentSelections.size < 2) {
                currentSelections.add(selectedValue);
            } else {
                currentSelections.delete(currentSelections.values().next().value);
                currentSelections.add(selectedValue);
            }
        }
        return { ...prev, [actuatorIdentifier]: Array.from(currentSelections) };
    });
};

const getDateRangeForFilter = (filter: string, actuatorIdentifier: string) => {
  const today = new Date();
  const startOfDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  var from, to;

  switch (filter) {
    case "today":
      from = to = startOfDay;
      break;
    case "yesterday":
      from = new Date(startOfDay);
      from.setDate(from.getDate() - 1);
      to = new Date(from);
      break;
    case "week":
      from = new Date(startOfDay);
      from.setDate(from.getDate() - (from.getDay() ? from.getDay() - 1 : 6)); // Adjust to start the week on Monday
      to = new Date(startOfDay);
      break;
    case "lastWeek":
      from = new Date(startOfDay);
      from.setDate(from.getDate() - (from.getDay() ? from.getDay() - 1 : 6) - 7); // Go to last Monday
      to = new Date(from);
      to.setDate(to.getDate() + 6); // End on last Sunday
      break;
    case "month":
      from = new Date(today.getFullYear(), today.getMonth(), 1);
      to = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;
    case "lastMonth":
      from = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      to = new Date(today.getFullYear(), today.getMonth(), 0);
      break;
    case "thisYear":
      from = new Date(today.getFullYear(), 0, 1);
      to = new Date(startOfDay);
      break;
    case "lastYear":
      from = new Date(today.getFullYear() - 1, 0, 1);
      to = new Date(today.getFullYear() - 1, 11, 31);
      break;
    case "custom":
      const customRange = dateRanges[actuatorIdentifier];
      if (customRange && customRange.from && customRange.to) {
        return {
          from: customRange.from,
          to: customRange.to,
        };
      }
      return {};
    case "all":
    default:
      return {from: "all", to: "all"};
  }

  return {
    from: from
      ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`
      : undefined,
    to: to
      ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}`
      : undefined,
  };
};

  const fetchInputTypeActuatorData = async (inputType: string, buildingId: string | undefined) => {
    try {
      // Master doesn't have actuators that are writeable
      const buildingParameter = buildingId != undefined && buildingId != "f59cd020-9093-42b0-97d5-add4831210cc" ? `/${buildingId}` : '/';
      const response = await api.post(endpoints.actuators + `/actuators/${inputType}${buildingParameter}`);
      if (response.data && response.data.length > 0) {
        setWritableActuators(response.data);
      }
    }
    catch (error) {
      //console.error("Failed to fetch actuator data", error);
    }
  }

  const fetchActuatorData = async (actuatorIdentifier: string) => {
    setActuatorHistory(prevHistory => ({
        ...prevHistory,
        [actuatorIdentifier]: [],
    }));

    const filterValues = selectedFilters[actuatorIdentifier] || [];
    const ranges = filterValues.map(filter => getDateRangeForFilter(filter, actuatorIdentifier)).filter(Boolean);
    const fromValues = ranges.map(dr => dr.from).filter(Boolean).join(',');
    const toValues = ranges.map(dr => dr.to).filter(Boolean).join(',');
    const url = `${endpoints.actuatorhistory}/${encodeURIComponent(actuatorIdentifier)}/${fromValues}/${toValues}`;

    try {
        const response = await api.get(url);
        if (response.data && response.data.length > 0) {
            let combinedData: ChartDataStructure[];

            const data1 = response.data[0] || [];
            const data2 = response.data[1] || [];
            data1.sort((a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
            data2.sort((a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());

            var oldestDate1 = data1.length > 0 ? new Date(data1[0].createdDate) : null;
            var oldestDate2 = data2.length > 0 ? new Date(data2[0].createdDate) : null;

            var oldestValue: number | null = null;
            var newestValue: number | null = null;

            if (oldestDate1 && oldestDate2) {
                if (oldestDate1 < oldestDate2) {
                    oldestValue = data1[0].value;
                } else {
                    oldestValue = data2[0].value;
                }
                newestValue = data1[data1.length - 1].value;
            } else if (oldestDate1 && !oldestDate2) {
                oldestValue = data1[0].value;
                newestValue = data1[data1.length - 1].value;
            } else if (oldestDate2 && !oldestDate1) {
                oldestValue = data2[0].value;
                newestValue = data2[data2.length - 1].value;
            }

            if (newestValue !== null && oldestValue !== null) {
              setSelectedActuatorsDelta(prev => new Map(prev).set(actuatorIdentifier, (Number(newestValue) - Number(oldestValue)).toFixed(2)));
            }

            const combinedDataMap = new Map();

            const normalizeAndAddData = (data: ActuatorHistoryDto[], valueKey: string) => {
                data.forEach(item => {
                    const dateTime = new Date(item.createdDate);
                    dateTime.setSeconds(0, 0);
                    const dateTimeKey = dateTime.toISOString();
                    if (!combinedDataMap.has(dateTimeKey)) {
                        combinedDataMap.set(dateTimeKey, {
                            createdDate: dateTimeKey,
                            value1: null,
                            value2: null,
                            delta: null
                        });
                    }
                    var decimalItem = item.value.toFixed(item.decimals ?? 2);
                    combinedDataMap.get(dateTimeKey)[valueKey] = decimalItem;

                    if (oldestValue !== null) {
                        combinedDataMap.get(dateTimeKey).delta = (item.value - oldestValue).toFixed(item.decimals ?? 2);
                    }
                });
            };

            normalizeAndAddData(data1, 'value1');
            normalizeAndAddData(data2, 'value2');

            combinedData = Array.from(combinedDataMap.values());
            combinedData.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
            for (const actuator of combinedData) {
                actuator.createdDate = new Date(actuator.createdDate).toLocaleTimeString(locale) + " " + new Date(actuator.createdDate).toLocaleDateString(locale);
            }

            setActuatorHistory(prevHistory => ({
                ...prevHistory,
                [actuatorIdentifier]: combinedData,
            }));

            setDataLoadAttempts(prev => ({
                ...prev,
                [actuatorIdentifier]: "dataLoaded",
            }));
        }
    } catch (error) {
        console.error("Failed to fetch actuator data", error);
        setDataLoadAttempts(prev => ({
            ...prev,
            [actuatorIdentifier]: "noData",
        }));
    }
};

  const handleActuatorClick = async (gdasGuid: string, address: string) => {
    const actuatorIdentifier = `${gdasGuid}/${address}`;
    const unit = building.units.find((u) => u.gdasGuid === gdasGuid);
    const actuator = unit?.actuators.find((a) => a.address === address);

    if (!unit || !actuator || actuator.inputType === 1) {
      // If the actuator is a toggle type, don't fetch data or toggle the selection
      return;
    }

    setSelectedActuators(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(actuatorIdentifier)) {
        newSelected.delete(actuatorIdentifier);
      } else {
        newSelected.add(actuatorIdentifier);
      }
      return newSelected;
    });

    if (!selectedActuators.has(actuatorIdentifier)) {
      await fetchActuatorData(actuatorIdentifier);
    }
  };

  const handlePublish = (newValues: Map<string, string>) => {
    const options = {
      username: building.mqttUser,
      password: building.mqttPass,
    };

    const client = mqtt.connect(building.mqttServer, options);

    client.on('connect', () => {
      newValues.forEach((message, topic) => {

        var actuatorChangeDto = {
          server: building.mqttServer,
          topic: topic,
          actuatorId: building.units.find(u => topic.includes(u.gdasGuid))?.actuators.find(a => topic.includes(a.address))?.id,
          value: parseFloat(message),
        };

        client.publish(topic, message, { retain: true });

        api.post(endpoints.actuatorhistory + "/user-interaction", actuatorChangeDto);
      });
      client.end();
    });
  };

  const handleCalculatorClick = async () => {
    setShowActuatorModal(true);
  }

  const belongsToZone = (actuator: ExtendedActuator) => {
    const zoneRegistry = callingComponent === 'piezo' ? piezoZoneBuildingsRegistry : wellZoneBuildingsRegistry;
    const simplifiedAddress = actuator.address.replace("ADDRESS-VALUE/", "");

    if (!zone) {
        // Over all zones
        const target = actuator.unitName === "MASTER" ? simplifiedAddress : actuator.unitName;
        return zoneRegistry['all'].includes(target);
    } else {
        // Specific zone
        if (actuator.unitName === "MASTER") {
            const zones = findBuildingInZones(callingComponent, simplifiedAddress, false);
            return zones.includes(zone);
        }
        return zoneRegistry[zone] && zoneRegistry[zone].includes(actuator.unitName);
    }
};

  const filteredWritableActuators = writableActuators.filter(extendedActuator =>
    belongsToZone(extendedActuator)
  );

  const handleDropdownChange = (selections: string[]) => {
    setDropdownSelection(selections);
  };

  const handleAddActuator = (selection: string) => {
    if (!selectedActuatorsForModal.has(selection)) {
      setSelectedActuatorsForModal(new Map(selectedActuatorsForModal.set(selection, '')));
    }
  };

  const handleValueChange = (topic: string, value: string) => {
    setSelectedActuatorsForModal(new Map(selectedActuatorsForModal.set(topic, value)));
  };

  const handleResetModal = () => {
    setSelectedActuatorsForModal(new Map());
  };

  const handleCancelModal = () => {
    setShowActuatorModal(false);
    setSelectedActuatorsForModal(new Map());
  };

  const handleSendModal = () => {
    handlePublish(selectedActuatorsForModal);
    setShowActuatorModal(false);
  };

  const handleDateRangeChange = (
    actuatorIdentifier: string,
    from?: string,
    to?: string
  ) => {
    setDateRanges((prevRanges) => ({
      ...prevRanges,
      [actuatorIdentifier]: { from, to },
    }));
  };

  const toggleChartType = (actuatorIdentifier: string) => {
    setChartTypes(prev => ({
      ...prev,
      [actuatorIdentifier]: prev[actuatorIdentifier] === 'bar' ? 'line' : 'bar',
    }));
  }

  const chart = (actuatorIdentifier: string, isCustomSelected: boolean) => {

    const ChartComponent = chartTypes[actuatorIdentifier] === 'bar' ? PositiveAndNegativeBarChart : SimpleLineChart;

    return (
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {filterOptions.map((option) => (
            <div style={{ flex: "1 1 33%", padding: "5px" }}>
              <Form.Check
                inline
                label={option.label}
                name={`dataFilter-${actuatorIdentifier}`}
                type="checkbox"
                id={`chackbox-${actuatorIdentifier}-${option.value}`}
                value={option.value}
                checked={selectedFilters[actuatorIdentifier]?.includes(option.value)}
                onChange={() =>
                  handleFilterChange(actuatorIdentifier, option.value)
                }
              />
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            backgroundColor: "white",
            padding: "1rem",
          }}
        >
          <Row>
            <Col sm={3} md={3} lg={3} style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
              <Row>
                <p><strong>{t("deltaFirstLast")}</strong></p>
              </Row>
              <Row>
              {selectedActuatorsDelta.get(actuatorIdentifier) ? (
                <p><strong>{selectedActuatorsDelta.get(actuatorIdentifier)}</strong></p>
              ) : (
                <p>{t("noDataAvailableMessage")}</p>
              )}
              </Row>
            </Col>
            <Col style={{display: "flex", flexWrap: "wrap", alignContent: "center", justifyContent: "center"}}>
              <div style={{display: "flex", width: '100%', justifyContent: "center", marginBottom: "0.75rem"}}>
                <label
                  style={{
                    marginRight: "2%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("from")}:
                </label>
                <input
                  type="date"
                  disabled={!isCustomSelected}
                  onChange={(e) =>
                    handleDateRangeChange(
                      actuatorIdentifier,
                      e.target.value,
                      dateRanges[actuatorIdentifier]?.to
                    )
                  }
                />
                <label
                  style={{
                    margin: "0 2%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {t("to")}:
                </label>
                <input
                  type="date"
                  disabled={!isCustomSelected}
                  onChange={(e) =>
                    handleDateRangeChange(
                      actuatorIdentifier,
                      dateRanges[actuatorIdentifier]?.from,
                      e.target.value
                    )
                  }
                />
              </div>
              <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <Button style={{ marginLeft: "2%" }} onClick={() => fetchActuatorData(actuatorIdentifier)}>{t("fetch")}</Button>
                <Button style={{ marginLeft: "2%" }} onClick={() => toggleChartType(actuatorIdentifier)}>{t("switchChartType")}</Button>
                <Button style={{ marginLeft: '2%' }} onClick={() => downloadCSV(actuatorIdentifier)}>{t('downloadCSV')}</Button>
              </div>
            </Col>
          </Row>
        </div>
        {!dataLoadAttempts[actuatorIdentifier] || !dataLoadAttempts[actuatorIdentifier].includes("noData") ? (
          <ChartComponent
              chartData={actuatorHistory[actuatorIdentifier] || []}
              label1={filterLabels[selectedFilters[actuatorIdentifier]?.[0]] || 'Value 1'}
              label2={filterLabels[selectedFilters[actuatorIdentifier]?.[1]]}
          />
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <p><strong>{t("noDataAvailableMessage")}</strong></p>
          </div>
        )}
      </div>
    );
  };

  const convertToCSV = (data: ChartDataStructure[]): string => {
    const headers = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(row =>
      Object.values(row).join(',')
    ).join('\n');
    return headers + rows;
  };

  const downloadCSV = (actuatorIdentifier: string) => {
    const data = actuatorHistory[actuatorIdentifier];
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = "data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    // Overview is the only component that should not have the calculator card
    if (!filterCalculatorModalPages.includes(callingComponent)) {
      fetchInputTypeActuatorData("-1", building.id);
    }

    building.units.forEach((unit) => {
      unit.actuators.forEach((actuator) => {
        setSelectedFilters(prev => ({
          ...prev,
          [`${unit.gdasGuid}/${actuator.address}`]: ["week"]
        }));
      });
    });

    setSelectedActuatorsDelta(new Map());
  }, []);

  useEffect(() => {
    const options = {
      username: building.mqttUser,
      password: building.mqttPass,
    };

    const client = mqtt.connect(building.mqttServer, options);

    client.on("connect", () => {
      building.units.forEach((unit) => {
        client.subscribe(unit.gdasGuid + "/KeepAlive");
        unit.actuators.forEach((actuator) => {
          try {
            client.subscribe(unit.gdasGuid + "/" + actuator.address);
          } catch (error) {
            console.log(error);
          }
        });
      });
    });

    client.on("message", (address, message) => {
      setMqttMessages((prevMessages) => ({
        ...prevMessages,
        [address]: message.toString(),
      }));
    });

    return () => {
      client.end();
    };
  }, [building]);

  const sortItems = (actuators: Actuator[]) => {
    if (actuatorOrder && actuatorOrder.length > 0) {
      const sortedItems: SortedItem[] = [];
      actuatorOrder.forEach((order) => {
        if (order.startsWith('text:')) {
          const titleContent = order.split(':')[1] ?? '';
          const textContent = order.split(':')[2] ?? '';
          sortedItems.push({ type: 'text', title: titleContent, content: textContent });
        }
        else if (order === 'divider:') {
          sortedItems.push({ type: 'divider' })
        }
        else if (order === 'empty') {
          sortedItems.push({ type: 'empty' });
        }
        else if (!order.endsWith(':hidden')) {
          var actuator = actuators.find(act => act.name === order);

          if (!actuator) {
            actuator = actuators.find(act => act.address.endsWith(order));
          }

          if (actuator) {
            sortedItems.push(actuator);
          }
        }
      });

      // These pages have a predefined order for actuators
      if (callingComponent !== "overview" && zone && undefined) {
        // append any actuators not included in the predefined order to the end
        actuators.forEach((actuator) => {
          // don't show writable actuators in the actuators list
          if (!sortedItems.includes(actuator) && !actuatorOrder.includes(`${actuator.name}:hidden`) && actuator.inputType != -1) {
            sortedItems.push(actuator);
          }
        });
      }
      return sortedItems;
    }
    return actuators; // return the default order if no predefined order is set
  };

  if (!building.units) return <></>;

  return (
    <>
      <Row>
        {building.units.map((unit) => {
          var sortedActuators = sortItems(unit.actuators);
          return sortedActuators.map((item, index) => {
            if ("type" in item && item.type === "text") {
              return (
                <>
                  {
                    // Only show the title for the first two dividers in the overview page
                    callingComponent === "overview" &&
                    <h2>{index == 0 ? t("WellControl") : t("Piezo")}</h2>
                  }
                  <Col
                    lg={4}
                    md={6}
                    key={`textcard-${index}`}
                    style={{ marginTop: "1rem" }}
                  >
                    <TextCard title={t(item.title)} text={t(item.content)} />
                  </Col>
                </>
              );
            } else if ("type" in item && item.type === "divider") {
              return (
                <Col
                  sm={12}
                  key={`divider-${index}`}
                  style={{ marginTop: "1rem" }}
                >
                  <hr />
                </Col>
              );
            } else if ("type" in item && item.type === "empty") {
              if (isMobile) return null;
              return (
                <Col
                  sm={12}
                  md={4}
                  key={`empty-${index}`}
                >
                  <div style={{ height: "1rem" }} />
                </Col>
              );
            } else {
              const actuatorItem = item as Actuator;
              const actuatorIdentifier = `${unit.gdasGuid}/${actuatorItem.address}`;
              const isActuatorSelected = selectedActuators.has(actuatorIdentifier);
              const isCustomSelected = selectedFilters[actuatorIdentifier]?.includes("custom");

              return (
                <React.Fragment key={actuatorIdentifier}>
                  <Col lg={4} md={6} style={{ marginTop: "1rem" }}>
                    <div
                      onClick={() => handleActuatorClick(unit.gdasGuid, actuatorItem.address)}
                    >
                      <ActuatorCard
                        building={building}
                        unit={unit}
                        actuator={actuatorItem}
                        mqttMessage={mqttMessages[actuatorIdentifier]}
                      />
                    </div>
                  </Col>
                  {isActuatorSelected && (
                    <Col xs={12} style={{ marginTop: "1rem" }}>
                      <div className="card" style={{ height: '100%', width: '100%', padding: '1rem', border: '0px', borderRadius: '0px' }}>
                        {
                          <div style={{ marginTop: "1rem", backgroundColor: "white" }}>
                            <h3>{item.name}</h3>
                          </div>
                        }
                        {
                          chart(actuatorIdentifier, isCustomSelected)
                        }
                      </div>
                    </Col>
                  )}
                </React.Fragment>
              );
            }
          });
        })}
        {
          !filterCalculatorModalPages.includes(callingComponent) &&
          filteredWritableActuators.length > 0 &&
          <Col lg={4} style={{marginTop: "1rem"}}>
            <CalculatorCard onClick={handleCalculatorClick}/>
          </Col>
        }
      </Row>
        <CalculatorModal
          show={showActuatorModal}
          onHide={handleCancelModal}
          writableActuators={filteredWritableActuators}
          dropdownSelection={dropdownSelection}
          onDropdownChange={handleDropdownChange}
          selectedActuatorsForModal={selectedActuatorsForModal}
          onAddActuator={handleAddActuator}
          onValueChange={handleValueChange}
          onReset={handleResetModal}
          onCancel={handleCancelModal}
          onSend={handleSendModal}
          mqttMessages={mqttMessages}
          isGlobal={false}
          t={t}
        />
    </>
  );
};

interface DateRange {
  from?: string;
  to?: string;
}

type SortedItem =
  | Actuator
  | { type: "text"; title: string; content: string }
  | { type: "empty" }
  | { type: "divider" };

export default BuildingActuators;