import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ToggleWidget: React.FC<ToggleWidgetProps> = ({ id, value, labels, onToggle }) => {
    const { t } = useTranslation();

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {value !== '' ? (
                <>
                    <h1>
                        <strong>{t(labels[Number(value)])}</strong>
                    </h1>
                    <Form.Check
                        type="switch"
                        style={{
                            width: '50px',
                            height: '30px',
                            transform: 'scale(1.5)',
                            marginLeft: 'auto',
                        }}
                        id={id}
                        checked={value === '1'}
                        onChange={(e) => {
                            onToggle();
                        }}
                    />
                </>
            ) : null}
        </div>
    );
}

interface ToggleWidgetProps {
    id: string;
    value: string;
    labels: string[];
    onToggle: () => void;
}

export default ToggleWidget;