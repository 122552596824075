import React, { ReactNode, useContext, useEffect, useState } from "react";
import mqtt from 'mqtt';
import "../styles/Home.scss";
import AuthenticationContext from "./auth/AuthenticationContext";
import api, { endpoints } from "../utils/api";
import SideBar from "./elements/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderContainer from "./elements/HeaderContainer";
import { User } from "../models/auth.models";
import CalculatorModal from "./elements/CalculatorModal";
import {
    WindowIcon,
    ExclamationTriangleIcon,
    UserGroupIcon,
    ClipboardDocumentCheckIcon,
    CircleStackIcon,
    PresentationChartLineIcon,
    CalculatorIcon
} from '@heroicons/react/24/outline';
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { moduleRegistry } from "./modules/ModuleRegistry";
import { EnvelopeIcon } from "../icons/EnvelopeIcon";
import { WarningDto } from "../models/warning.models";
import { ExtendedActuator } from "../models/project.models";
import { cameraOrder } from "./modules/CameraRegistry";

const Home: React.FC<HomeProps> = ({ children, updatedUserData }) => {
    const location = useLocation();
    const navigate = useNavigate();
    var selectedZone = location.state?.filter as string;
    const i18nextLng = localStorage.getItem('i18nextLng') ?? 'en';
    const [mqttMessages] = useState<{ [key: string]: string }>({});
    const [acknowledgements, setAcknowledgements] = useState<any>({});
    const [warningMessage, setWarningMessage] = useState<WarningDto[]>([]);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(true);
    const [userSignedWarning, setUserSignedWarning] = useState<boolean>(false);
    const [user, setUser] = useState<User | null | undefined>(null);
    const [userModules, setUserModules] = useState<Module[]>([]);
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [showActuatorModal, setShowActuatorModal] = useState<boolean>(false);
    const [selectedActuatorsForModal, setSelectedActuatorsForModal] = useState<Map<string, string>>(new Map());
    const [dropdownSelection, setDropdownSelection] = useState<string[]>([]);
    const [writableActuators, setWritableActuators] = useState<ExtendedActuator[]>([]);
    const [activeItem, setActiveItem] = useState<MenuItem>({
        name: '',
        to: '',
        icon: <></>,
    });
    const { t } = useTranslation();
    const { currentUser, isAdmin, isProjectOwner } = useContext(AuthenticationContext);
    const { updateIsLoggedIn } = useContext(AuthenticationContext);

    const handleAcknowledgementChange = (warningId: string, isChecked: boolean) => {
        setAcknowledgements((prevState: any) => ({
            ...prevState,
            [warningId]: {
                acknowledged: isChecked,
                error: isChecked ? '' : prevState[warningId]?.error || '',
            },
        }));
    };

    const fetchWarning = async () => {
        try {
            const response = await api.get(endpoints.warnings);
            if (response.data != null) {
                const warningsArray = Array.isArray(response.data) ? response.data : [response.data];
                const blockingWarnings = warningsArray.filter((warning: WarningDto) => warning.isBlockingWarning);
                setWarningMessage(blockingWarnings);
                setShowWarningModal(blockingWarnings.length > 0);
            }
            else {
                setShowWarningModal(false);
                setWarningMessage([]);
            }
        } catch (error) {
            setShowWarningModal(false);
            setWarningMessage([]);
        }
    };

    async function fetchUserAccessActuators() {
        try {
            const userAccessResponse = await api.get(endpoints.userAccesses + '/userAccesses/user/-1');
            if (userAccessResponse.data) {
                const actuatorIds = userAccessResponse.data.map((access: UserAccess) => access.actuatorId);
                const actuatorsResponse = await api.post(endpoints.actuators + '/actuators/-1/', actuatorIds);
                if (actuatorsResponse.data) {
                    setWritableActuators(actuatorsResponse.data);
                }
            }
        } catch (error) {
            //console.error("Error fetching user access actuators", error);
        }
    }

    async function handleActuatorModal() {
        await fetchUserAccessActuators();
        setShowActuatorModal(true);
    }

    const handlePublish = (newValues: Map<string, string>) => {
        const extendedActuator = writableActuators.find(a => newValues.has(a.gdasGuid + "/" + a.address));

        if (extendedActuator?.server === undefined || extendedActuator?.username === undefined || extendedActuator?.pass === undefined) {
            return;
        }

        const options = {
            username: extendedActuator.username,
            password: extendedActuator.pass
        };
        const server = extendedActuator.server;

        const client = mqtt.connect(server, options);

        client.on('connect', () => {
            newValues.forEach((message, topic) => {

                var actuatorChangeDto = {
                    server: server,
                    topic: topic,
                    actuatorId: extendedActuator.id,
                    value: parseFloat(message),
                };

                client.publish(topic, message, { retain: true });

                api.post(endpoints.actuatorhistory + "/user-interaction", actuatorChangeDto);
            });
            client.end();
        });
    };

    const onDropdownChange = (selections: string[]) => {
        setDropdownSelection(selections);
    };

    const onAddActuator = (selection: string) => {
        setSelectedActuatorsForModal(new Map(selectedActuatorsForModal.set(selection, '')));
    };

    const onValueChange = (topic: string, value: string) => {
        setSelectedActuatorsForModal(new Map(selectedActuatorsForModal.set(topic, value)));
    };

    const onReset = () => {
        setSelectedActuatorsForModal(new Map());
    };

    const onCancel = () => {
        setShowActuatorModal(false);
    };

    const onSend = () => {
        handlePublish(selectedActuatorsForModal);
        setShowActuatorModal(false);
    };

    const handleSignWarning = async (messageId: string) => {
        const acknowledgment = acknowledgements[messageId];
        if (!acknowledgment || !acknowledgment.acknowledged) {
            // Update the error message for the specific warning
            setAcknowledgements((prevState: any) => ({
                ...prevState,
                [messageId]: {
                    ...prevState[messageId],
                    error: t("acknowledgWarning"),
                },
            }));
            return;
        }
        try {
            const response = await api.put(endpoints.warnings + `/signwarning/${messageId}`);
            if (response.data === "") {
                setWarningMessage(prevState => prevState.filter(message => message.warningId !== messageId));

                setAcknowledgements((prevState: any) => {
                    const updatedState = { ...prevState };
                    delete updatedState[messageId];
                    return updatedState;
                });
            }
        } catch (error) {
            // console.error('Sign warning failed', error);
        }
    };

    const stopStream = async () => {
        await api.post(endpoints.camera + `/cancel-stream`)
            .catch(error => console.error('Error stopping the stream:', error));
    }

    const modalWarning = (message: WarningDto) => {
        const acknowledgement = acknowledgements[message.warningId] || { acknowledged: false, error: '' };

        // Set the translation key and parameters for the warning message
        let translationKeyWarning;
        let params = {};

        if (message.currentValue > (message?.thresholdTop ?? 0)) {
            translationKeyWarning = 'valueAboveThreshold';
            params = {
                value: message.currentValue.toFixed(message.decimals),
                thresholdTop: message.thresholdTop,
                unit: message.physicalUnit
            };
        } else if (message.currentValue < (message.thresholdBottom ?? 0)) {
            translationKeyWarning = 'valueBelowThreshold';
            params = {
                value: message.currentValue.toFixed(message.decimals),
                thresholdBottom: message.thresholdBottom,
                unit: message.physicalUnit
            };
        } else {
            translationKeyWarning = 'valueWithinRange';
            params = {
                value: message.currentValue.toFixed(message.decimals),
                thresholdBottom: message.thresholdBottom,
                thresholdTop: message.thresholdTop,
                unit: message.physicalUnit
            };
        }

        return (
            <div className="warning-modal">
                <div className="warning-modal-content">
                    <h2>{t("warning")}</h2>
                    <div>
                        <p><strong>{t("projectName")}:</strong> {message.projectName ?? 'N/A'}</p>
                        <p><strong>{t("actuatorName")}:</strong> {message.actuatorName ?? 'N/A'}</p>
                        <p><strong>{t("createdDate")}:</strong> {new Date(message.createdDate).toLocaleString(i18nextLng, { dateStyle: 'medium', timeStyle: 'short' }) ?? 'N/A'}</p>
                        <p><strong>{t("currentValue")}:</strong> {message.currentValue?.toFixed(message.decimals) ?? 'N/A'} {message.physicalUnit ?? ''}</p>
                        <p><strong>{t("thresholdTop")}:</strong> {message.thresholdTop ?? 'N/A'} {message.physicalUnit ?? ''}</p>
                        <p><strong>{t("thresholdBottom")}:</strong> {message.thresholdBottom ?? 'N/A'} {message.physicalUnit ?? ''}</p>
                        <p><strong>{t("warningReason")}: </strong>{t(translationKeyWarning, params)}</p>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id={message.warningId}
                            checked={acknowledgement.acknowledged}
                            onChange={(e) => handleAcknowledgementChange(message.warningId, e.target.checked)}
                            style={{ margin: '1% 1% 2% 0%' }}
                        />
                        <label htmlFor={message.warningId}>
                            {t('acknowledgmentUser', {
                                firstName: currentUser?.firstName,
                                lastName: currentUser?.lastName
                            })}
                        </label>
                        {acknowledgement.error && <p style={{ color: 'red' }}>{acknowledgement.error}</p>}
                    </div>
                    <Button variant="primary" onClick={() => handleSignWarning(message.warningId)}>{t("understood")}</Button>
                </div>
            </div>
        )
    };

    async function handleLogout() {
        try {
            await api.post(endpoints.accounts + '/logout');
            updateIsLoggedIn(false);
            navigate('/');
        } catch (error) {
            //console.error('Logout failed', error);
        }
    }

    const getUserModules = () => {
        api.get(endpoints.modules + '/userModules').then(response => {
            try {
                if (response.data) {
                    setUserModules(response.data);
                }
            } catch (error) {
            }
        })
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        stopStream();
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        updatedUserData && setUser(updatedUserData);
    }, [updatedUserData]);

    useEffect(() => {
        setUser(currentUser);

        var isAdminOrProjectOwner = isAdmin() || isProjectOwner();

        let items: MenuItem[] = [
            {
                name: t("Overview"),
                to: "/",
                icon: <WindowIcon />
            }
        ];

        getUserModules();
        userModules.forEach(userModule => {
            const moduleInfo = moduleRegistry[userModule.id];
            if (moduleInfo) {
                // Well Control
                if (userModule.id === "29f2d24a-b07b-4721-bda6-9a02f0ede08f") {

                    items.push({
                        name: t(moduleInfo.name),
                        to: moduleInfo.path,
                        icon: moduleInfo.icon,
                    });

                    var zones = 3;
                    for (var i = 0; i < zones; i++) {
                        items.push({
                            name: t("zone") + " " + (i + 1),
                            to: moduleInfo.path + "/" + (i + 1),
                            isSubItem: true,
                            icon: moduleInfo.icon
                        });
                    }
                }

                // Camera
                else if (userModule.id === "7a8b4e8d-8ce2-4c2d-841a-7edca5425522") {

                    items.push({
                        name: t(moduleInfo.name),
                        to: moduleInfo.path,
                        icon: moduleInfo.icon,
                    });

                    for (var i = 0; i < cameraOrder.length; i++) {
                        items.push({
                            name: cameraOrder[i].menuTitle,
                            to: moduleInfo.path + "/" + (i + 1),
                            isSubItem: true,
                            icon: moduleInfo.icon
                        });
                    }
                }

                // Piezo
                else if (userModule.id === "a18936c1-208f-463f-b864-a392e76b6f93") {
                    items.push({
                        name: t(moduleInfo.name),
                        to: moduleInfo.path,
                        icon: moduleInfo.icon,
                    });

                    var interventionArea = 3;
                    for (var i = 0; i < interventionArea; i++) {
                        items.push({
                            name: t("interventionArea") + " " + (i + 1),
                            to: moduleInfo.path + "/" + (i + 1),
                            isSubItem: true,
                            icon: moduleInfo.icon
                        });
                    }
                }
                else {
                    items.push({
                        name: t(moduleInfo.name),
                        to: moduleInfo.path,
                        icon: moduleInfo.icon,
                    });
                }
            }
        });

        if (isAdminOrProjectOwner) {
            items.push({
                name: t("projectManagement"),
                to: "/projectmanagement",
                icon: <ClipboardDocumentCheckIcon />
            });
            items.push({
                name: t("warnings"),
                to: "/warnings",
                icon: <ExclamationTriangleIcon />
            });
        }


        if (isAdminOrProjectOwner) {
            items.push({
                name: t("userManagement"),
                to: "/usermanagement",
                icon: <UserGroupIcon />
            });
            items.push({
                name: t("emailSettings"),
                to: "/mail",
                icon: <EnvelopeIcon />
            });
        }

        items.push({
            name: t("actuatorHistory"),
            to: "/actuatorhistory",
            icon: <CircleStackIcon />
        });

        items.push({
            name: t("warningHistory"),
            to: "/warninghistory",
            icon: <CircleStackIcon />
        });

        if (isAdminOrProjectOwner){
            items.push({
                name: t("writeValues"),
                to: activeItem.to,
                handler: handleActuatorModal,
                icon: <CalculatorIcon />
            });
        }

        items.push({
            name: t("charts"),
            to: "/charts",
            icon: <PresentationChartLineIcon />
        });

        setMenuItems(items);
    }, [currentUser]);

    useEffect(() => {
        const foundedItem = menuItems.find((i) => location?.pathname === i.to);
        foundedItem
            ? setActiveItem(foundedItem)
            : setActiveItem({
                name: '',
                to: '',
                icon: <></>,
            });

        fetchWarning();

        if (!location.pathname.includes("camera")) {
            stopStream();
        }
    }, [location]);

    useEffect(() => {
        if (warningMessage.length === 0) {
            setUserSignedWarning(true);
            setShowWarningModal(false);
        }
    }, [warningMessage]);

    return (
        <div>
            <CalculatorModal
                show={showActuatorModal}
                onHide={() => setShowActuatorModal(false)}
                writableActuators={writableActuators}
                mqttMessages={mqttMessages}
                dropdownSelection={dropdownSelection}
                onDropdownChange={onDropdownChange}
                selectedActuatorsForModal={selectedActuatorsForModal}
                onAddActuator={onAddActuator}
                onValueChange={onValueChange}
                onReset={onReset}
                onCancel={onCancel}
                onSend={onSend}
                isGlobal={true}
                t={t}
            />
            <HeaderContainer
                user={user}
                items={menuItems}
                activeItem={activeItem}
                handleLogout={handleLogout}
            />
            <div className="layout">
                <SideBar items={menuItems} activeItem={activeItem} />
                <div className='main-wrapper'>
                    <div className="content-wrapper">
                        {
                            showWarningModal && (
                                warningMessage.map((message) => (
                                    <div key={message.actuarorId + message.topic} style={{ marginBottom: "5%" }}>
                                        {modalWarning(message)}
                                    </div>
                                )
                                ))
                        }
                        {
                            (userSignedWarning && !showWarningModal) &&
                            <div className="children-container">{children}</div>
                        }
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className='footer'>
                            <a href='https://gdas.swiss/imprint/'>{t("imprint")}</a>
                            <a href='https://gdas.swiss/disclaimer/'>{t("disclaimer")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export interface MenuItem {
    name: string;
    to: string;
    handler?: () => void;
    icon?: JSX.Element;
    isSubItem?: boolean;
}

interface HomeProps {
    children?: ReactNode;
    updatedUserData?: User | undefined | null;
}

export default Home;