import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api, { endpoints } from "../utils/api";
import { ActuatorHistoryDto } from "../models/actuator.models";
import { ListGroup, Table } from "react-bootstrap";
import EntityIndex from "./elements/EntityIndex";

export default function ActuatorHistory() {
    const { t } = useTranslation();
    const locale = localStorage.getItem("i18nextLng") ?? "en";

    return (
        <div>
            <h1>{t("actuatorHistory")}</h1>

            <EntityIndex<ActuatorHistoryDto>
                entityName="File"
                title=""
                url={endpoints.actuatorhistory + "/all/with-parents"}
                searchCriteria={["actuatorName"]}>
                {(histories) =>
                    <>
                        <Table striped bordered hover responsive className="mt-3">
                            <thead>
                                <tr>
                                    <th>{t("projectName")}</th>
                                    <th>{t("buildingName")}</th>
                                    <th>{t("unitName")}</th>
                                    <th>{t("actuatorName")}</th>
                                    <th>{t("value")}</th>
                                    <th>{t("userName")}</th>
                                    <th>{t("createdDate")}</th>
                                </tr>
                            </thead>

                            {histories !== undefined && histories.length > 0
                                ?
                                <tbody>
                                    {histories?.map(history =>
                                        <tr key={history.createdDate + history.actuatorName}>
                                            <td>{history.projectName}</td>
                                            <td>{history.buildingName}</td>
                                            <td>{history.unitName}</td>
                                            <td>{history.actuatorName}</td>
                                            <td>{history.value} {history.physicalUnit}</td>
                                            <td>{history.userName}</td>
                                            <td>{new Date(history.createdDate).toLocaleDateString(locale)} {new Date(history.createdDate).toLocaleTimeString(locale)}</td>
                                        </tr>)}
                                </tbody>
                                :
                                <tbody>
                                    <tr>
                                        <td colSpan={7}>{t("noData")}</td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                    </>
                }
            </EntityIndex>
        </div>
    );
}
