import React, { Dispatch, SetStateAction } from "react";
import { User } from "../../models/auth.models";

const AuthenticationContext = React.createContext<{

    isLoggedIn: boolean;
    updateIsLoggedIn: (isLoggedIn: boolean) => void;

    user: User | null | undefined;
    updateUser: (userData: User | null | undefined) => void;

    meRef: React.MutableRefObject<string>;

    //toast
    success: boolean;
    setSuccess: Dispatch<SetStateAction<boolean>>;
    message: string;
    setMessage: Dispatch<SetStateAction<string>>;
    mode: string;
    setMode: Dispatch<SetStateAction<string>>;
    duration: number;
    setDuration: Dispatch<SetStateAction<number>>;

    currentUser: User | null;

    isAdmin: () => boolean,
    isProjectOwner: () => boolean
}>({
    isLoggedIn: false,
    updateIsLoggedIn: () => { },
    user: null,
    updateUser: () => { },
    meRef: { current: "" },
    success: false, setSuccess: () => { },
    message: "", setMessage: () => { },
    mode: "", setMode: () => { },
    duration: 0, setDuration: () => { },
    currentUser: null,
    isAdmin: () => false,
    isProjectOwner: () => false
})

export default AuthenticationContext