import css from '../../styles/DisplayErrors.module.scss';

export default function DisplayErrors(props: displayErrorProps) {
    return (
        <>
            {props.errors ?
                <ul className={css.errormessage}>
                    {props.errors.map((error, index) => <li key={index}>{error}</li>)}
                </ul> :
                null}
        </>
    )
}

interface displayErrorProps {
    errors?: string[];
}