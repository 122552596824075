  import { Row, Col, Button, Dropdown } from "react-bootstrap";
  import { Formik, Field, Form } from "formik";
  import api, { endpoints } from "../utils/api";
  import { useEffect, useState } from "react";
  import { EmailTemplateDto, IEmailTemplate } from "../models/mail.models";
  import { useTranslation } from "react-i18next";
  import { User } from "../models/auth.models";
  import { CheckCircleIcon } from "../icons/CheckCircleIcon";
  import UserCheckboxList from "./elements/UserCheckboxList";
  import { useLocation } from "react-router-dom";
import { CustomToggle, CustomMenu } from "./elements/CustomDropdown";

  export default function EmailTemplate() {
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;
    const showUserList = state ? state.showUserList : false;
    const [usersWithAlias, setUsersWithAlias] = useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [succesfullSend, setSuccesfullSend] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentTemplateID, setCurrentTemplateID] = useState<string>("");
    const [emailTemplates, setEmailTemplates] = useState<IEmailTemplate[]>([]);
    const [initialFormValues, setInitialFormValues] = useState<IEmailTemplate>({
      templateId: "",
      ars_name: "",
      ars_subject: "",
      ars_previewText: "",
      ars_logoUrl: "",
      ars_titleText: "",
      ars_explanationText: "",
      ars_primaryButtonHref: "",
      ars_primaryButtonText: "",
      ars_ignoreMessageText: "",
      ars_linkedInHref: "",
      ars_copyrightText: "",
      ars_companyNameText: "",
      ars_allRightsReservedText: "",
      ars_reachUsText: "",
      ars_companyEmailText: "",
      ars_primaryColor: "",
      ars_buttonStyling: "",
    });

    // GDAS templates that are not allowed to be deleted
    const gdasTemplates = ["ed469736-dc86-11ee-a500-dd6d8b6f8b6d", "f533e82c-dc86-11ee-a500-dd6d8b6f8b6d", "fb38866a-dc86-11ee-a500-dd6d8b6f8b6d"];

    const resetFormValues = () => {
      setInitialFormValues({
        templateId: "",
        ars_name: "",
        ars_subject: "",
        ars_previewText: "",
        ars_logoUrl: "",
        ars_titleText: "",
        ars_explanationText: "",
        ars_primaryButtonHref: "",
        ars_primaryButtonText: "",
        ars_ignoreMessageText: "",
        ars_linkedInHref: "",
        ars_copyrightText: "",
        ars_companyNameText: "",
        ars_allRightsReservedText: "",
        ars_reachUsText: "",
        ars_companyEmailText: "",
        ars_primaryColor: "",
        ars_buttonStyling: "",
      });
    };

    const findTemplate = (id: string): IEmailTemplate | undefined => {
      return emailTemplates.find((template) => template.templateId === id);
    };

    const onSubmitEmailTemplate = async (values: IEmailTemplate) => {
      setIsLoading(true);
      // Create new template if id is empty
      if (values.templateId === "") {
        var templateJsonString = JSON.stringify({
          ARS_Name: values.ars_name,
          ARS_Subject: values.ars_subject,
          ARS_PreviewText: values.ars_previewText,
          ARS_LogoUrl: values.ars_logoUrl,
          ARS_TitleText: values.ars_titleText,
          ARS_ExplanationText: values.ars_explanationText,
          ARS_PrimaryButtonHref: values.ars_primaryButtonHref,
          ARS_PrimaryButtonText: values.ars_primaryButtonText,
          ARS_IgnoreMessageText: values.ars_ignoreMessageText,
          ARS_LinkedInHref: values.ars_linkedInHref,
          ARS_CompanyNameText: values.ars_companyNameText,
          ARS_AllRightsReservedText: values.ars_allRightsReservedText,
          ARS_ReachUsText: values.ars_reachUsText,
          ARS_CompanyEmailText: values.ars_companyEmailText,
          ARS_PrimaryColor: values.ars_primaryColor,
          ARS_ButtonStyling: values.ars_buttonStyling,
        });

        const newTemplate = {
          templateId: values.templateId,
          templateJsonString: templateJsonString,
        };

        api.post(endpoints.email, newTemplate)
          .then((res) => {
            const newTemplateData = JSON.parse(res.data.templateJsonString);
            const newTemplate: IEmailTemplate = {
              templateId: res.data.templateId,
              ars_name: newTemplateData.ARS_Name,
              ars_subject: newTemplateData.ARS_Subject,
              ars_previewText: newTemplateData.ARS_PreviewText,
              ars_logoUrl: newTemplateData.ARS_LogoUrl,
              ars_titleText: newTemplateData.ARS_TitleText,
              ars_explanationText: newTemplateData.ARS_ExplanationText,
              ars_primaryButtonHref: newTemplateData.ARS_PrimaryButtonHref,
              ars_primaryButtonText: newTemplateData.ARS_PrimaryButtonText,
              ars_ignoreMessageText: newTemplateData.ARS_IgnoreMessageText,
              ars_linkedInHref: newTemplateData.ARS_LinkedInHref,
              ars_companyNameText: newTemplateData.ARS_CompanyNameText,
              ars_allRightsReservedText: newTemplateData.ARS_AllRightsReservedText,
              ars_reachUsText: newTemplateData.ARS_ReachUsText,
              ars_companyEmailText: newTemplateData.ARS_CompanyEmailText,
              ars_primaryColor: newTemplateData.ARS_PrimaryColor,
              ars_buttonStyling: newTemplateData.ARS_ButtonStyling,
              ars_copyrightText: newTemplateData.ARS_CopyrightText,
            };
            setEmailTemplates([...emailTemplates, newTemplate]);
          })
          .catch((err) => {
            //console.error(err);
          });
      } else {
        var templateJsonString = JSON.stringify({
          ARS_Name: values.ars_name,
          ARS_Subject: values.ars_subject,
          ARS_PreviewText: values.ars_previewText,
          ARS_LogoUrl: values.ars_logoUrl,
          ARS_TitleText: values.ars_titleText,
          ARS_ExplanationText: values.ars_explanationText,
          ARS_PrimaryButtonHref: values.ars_primaryButtonHref,
          ARS_PrimaryButtonText: values.ars_primaryButtonText,
          ARS_IgnoreMessageText: values.ars_ignoreMessageText,
          ARS_LinkedInHref: values.ars_linkedInHref,
          ARS_CopyrightText: values.ars_copyrightText,
          ARS_CompanyNameText: values.ars_companyNameText,
          ARS_AllRightsReservedText: values.ars_allRightsReservedText,
          ARS_ReachUsText: values.ars_reachUsText,
          ARS_CompanyEmailText: values.ars_companyEmailText,
          ARS_PrimaryColor: values.ars_primaryColor,
          ARS_ButtonStyling: values.ars_buttonStyling,
        });
        const updatedTemplate = {
          templateId: values.templateId,
          templateJsonString: templateJsonString,
        };

        api.put(endpoints.email, updatedTemplate)
          .then((res) => {
            const updatedTemplateData = JSON.parse(res.data.templateJsonString);
            const updatedEmailTemplates = emailTemplates.map((template) => {
              if (template.templateId === res.data.templateId) {
                return {
                  ...template,
                  templateId: res.data.templateId,
                  ars_name: updatedTemplateData.ARS_Name,
                  ars_subject: updatedTemplateData.ARS_Subject,
                  ars_previewText: updatedTemplateData.ARS_PreviewText,
                  ars_logoUrl: updatedTemplateData.ARS_LogoUrl,
                  ars_titleText: updatedTemplateData.ARS_TitleText,
                  ars_explanationText: updatedTemplateData.ARS_ExplanationText,
                  ars_primaryButtonHref: updatedTemplateData.ARS_PrimaryButtonHref,
                  ars_primaryButtonText: updatedTemplateData.ARS_PrimaryButtonText,
                  ars_ignoreMessageText: updatedTemplateData.ARS_IgnoreMessageText,
                  ars_linkedInHref: updatedTemplateData.ARS_LinkedInHref,
                  ars_companyNameText: updatedTemplateData.ARS_CompanyNameText,
                  ars_allRightsReservedText: updatedTemplateData.ARS_AllRightsReservedText,
                  ars_reachUsText: updatedTemplateData.ARS_ReachUsText,
                  ars_companyEmailText: updatedTemplateData.ARS_CompanyEmailText,
                  ars_primaryColor: updatedTemplateData.ARS_PrimaryColor,
                  ars_buttonStyling: updatedTemplateData.ARS_ButtonStyling,
                };
              }
              return template;
            });

            setEmailTemplates(updatedEmailTemplates);
          })
          .catch((err) => {
            //console.error(err);
          });
      }
    };

    const onSubmitSendEmail = (users: User[], templateId: string) => {
      const emailList = users.map((user) => user.email);
      let formData = new FormData();
      emailList.forEach((email) => formData.append("emailList", email));
      formData.append("templateId", templateId);

      api
        .post(endpoints.email + "/send", formData)
        .then((res) => {
          if (res.data === "") {
            setSuccesfullSend(true);
            setIsLoading(false);
          } else {
            //console.error(res.data);
          }
        })
        .catch((err) => {
          //console.error(err);
        });
    };

    useEffect(() => {
      api
        .get(endpoints.email + "/all")
        .then((res) => {
          const transformedTemplates = res.data.map(
            (template: EmailTemplateDto) => {
              const details = JSON.parse(template.templateJsonString);
              return {
                templateId: template.templateId,
                ars_name: details.ARS_Name,
                ars_subject: details.ARS_Subject,
                ars_previewText: details.ARS_PreviewText,
                ars_logoUrl: details.ARS_LogoUrl,
                ars_titleText: details.ARS_TitleText,
                ars_explanationText: details.ARS_ExplanationText,
                ars_primaryButtonHref: details.ARS_PrimaryButtonHref,
                ars_primaryButtonText: details.ARS_PrimaryButtonText,
                ars_ignoreMessageText: details.ARS_IgnoreMessageText,
                ars_linkedInHref: details.ARS_LinkedInHref,
                ars_copyrightText: details.ARS_CopyrightText,
                ars_companyNameText: details.ARS_CompanyNameText,
                ars_allRightsReservedText: details.ARS_AllRightsReservedText,
                ars_reachUsText: details.ARS_ReachUsText,
                ars_companyEmailText: details.ARS_CompanyEmailText,
                ars_primaryColor: details.ARS_PrimaryColor,
                ars_buttonStyling: details.ARS_ButtonStyling,
              };
            }
          );
          setEmailTemplates(transformedTemplates);
        })
        .catch((err) => {
          //console.error(err);
        });
        async function initUserFetch() {
          try {
            const response = await api.get(
              endpoints.accounts + "/allUsersWithAlias"
            );
            setUsersWithAlias(response.data);
          } catch (error) {
            //console.error('Error fetching users with roles:', error);
          }
        }
        initUserFetch();
    }, []);

    useEffect(() => {
      if (currentTemplateID != "" && currentTemplateID != null) {
        const template = findTemplate(currentTemplateID);
        if (template) {
          setInitialFormValues(template);
        }
      } else {
        resetFormValues();
      }
    }, [currentTemplateID]);

    useEffect(() => {
      let timer: string | number | NodeJS.Timeout | undefined;
      if (succesfullSend) {
        timer = setTimeout(() => {
          setSuccesfullSend(false);
        }, 5000);
      }
      return () => clearTimeout(timer);
    }, [succesfullSend]);

    useEffect(() => {
      setIsLoading(false);
    }, [emailTemplates]);

    return (
      <Row style={{display: "flex", justifyContent: (showUserList ? "" : "center")}}>
        <Col sm={12} md={8}>
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={(values, { resetForm }) => {
              onSubmitEmailTemplate(values).then(() => {
                resetForm();
                setCurrentTemplateID("");
              });
            }}
          >
            {({ resetForm }) => (
              <Form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h1>{t("templateDetails")}</h1>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Dropdown style={{ margin: "0 0 1rem 1rem" }}>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                      {t("predifinedTemplates")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {emailTemplates.map((template) => (
                        <Dropdown.Item
                          key={template.templateId}
                          eventKey={template.templateId}
                          onClick={() => setCurrentTemplateID(template.templateId)}
                        >
                          {template.ars_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <label htmlFor="ars_name" style={{ margin: "0rem 1rem" }}>
                    {t("templateName")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    name="ars_name"
                    type="text"
                    disabled={showUserList}
                  />
                  <label htmlFor="ars_subject" style={{ margin: "0rem 1rem" }}>
                    {t("subject")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    name="ars_subject"
                    type="text"
                    disabled={showUserList}
                  />
                  <label
                    htmlFor="ars_previewText"
                    style={{ margin: "0rem 1rem" }}
                  >
                    {t("previewText")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    name="ars_previewText"
                    type="text"
                    disabled={showUserList}
                  />
                  <label htmlFor="ars_titleText" style={{ margin: "0rem 1rem" }}>
                    {t("title")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    name="ars_titleText"
                    type="text"
                    disabled={showUserList}
                  />
                  <label
                    htmlFor="ars_explanationText"
                    style={{ margin: "0rem 1rem" }}
                  >
                    {t("explanation")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    as="textarea"
                    name="ars_explanationText"
                    disabled={showUserList}
                  />
                  <label
                    htmlFor="ars_primaryButtonText"
                    style={{ margin: "0rem 1rem" }}
                  >
                    {t("primaryButtonText")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    name="ars_primaryButtonText"
                    type="text"
                    disabled={showUserList}
                  />
                  <label
                    htmlFor="ars_ignoreMessageText"
                    style={{ margin: "0rem 1rem" }}
                  >
                    {t("ignoreMessage")}
                  </label>
                  <Field
                    style={{ margin: "0rem 1rem 1rem 1rem", padding: "0.5rem" }}
                    as="textarea"
                    name="ars_ignoreMessageText"
                    disabled={showUserList}
                  />
                </div>
                {/* Buttons */}
                {!showUserList && (
                  <Row style={{ display: "flex", flexDirection: "row" }}>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button type="submit" variant="primary" disabled={isLoading}>
                        {currentTemplateID ? t("update") : t("save")}
                      </Button>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="button"
                        variant="secondary"
                        disabled={isLoading}
                        onClick={() => {
                          setIsLoading(true);
                          resetForm();
                          setCurrentTemplateID("");
                          setIsLoading(false);
                        }}
                      >
                        {t("cancel")}
                      </Button>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        type="button"
                        variant="danger"
                        disabled={isLoading || gdasTemplates.includes(currentTemplateID) || currentTemplateID === ""}
                        onClick={() => {
                          if (currentTemplateID) {
                            setIsLoading(true);
                            api
                              .delete(endpoints.email + `/${currentTemplateID}`)
                              .then(() => {
                                setEmailTemplates(
                                  emailTemplates.filter(
                                    (template) =>
                                      template.templateId !== currentTemplateID
                                  )
                                );
                                resetForm();
                                setCurrentTemplateID("");
                                setIsLoading(false);
                              })
                              .catch((err) => {
                                //console.error(err);
                              });
                          }
                        }}
                      >
                        {t("delete")}
                      </Button>
                    </Col>
                  </Row>
                )}
              </Form>
            )}
          </Formik>
        </Col>
        {showUserList && (
          <Col sm={12} md={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <h1>{t("userList")}</h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  width: "50%",
                }}>
                <UserCheckboxList
                  key={currentTemplateID}
                  allUsers={usersWithAlias}
                  setSelectedUsers={setSelectedUsers}
                />
              </div>
              {selectedUsers.length > 0 && currentTemplateID !== "" &&(
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        onSubmitSendEmail(selectedUsers, currentTemplateID)
                      }}
                    >
                      {t("send")}
                    </Button>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      type="button"
                      variant="secondary"
                      disabled={isLoading}
                      onClick={() => {
                        setIsLoading(true);
                        setSelectedUsers([]);
                        setCurrentTemplateID("");
                        setIsLoading(false);
                      }}
                    >
                      {t("reset")}
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
            {
              succesfullSend && (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto", height: "200px", marginTop: "2rem"}}>
                  <CheckCircleIcon color='green' />
                  <span>{t("succesfullSend")}</span>
                </div>
              )
            }
          </Col>
        )}
      </Row>
    );
  }
