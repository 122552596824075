import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from './Header';
import { HeaderContainerProps } from '../../models/Headers.props';
import { useNavigate } from 'react-router-dom';

const HeaderContainer: React.FC<HeaderContainerProps> = React.memo(({
    user,
    items,
    activeItem,
    handleLogout,
}) => {

    const navigate = useNavigate()
    //Popup settings open/close logic
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const closePopupOnOutsideClick = (event: MouseEvent) => {
            if (
                popupRef.current &&
                event.target instanceof HTMLElement &&
                !popupRef.current.contains(event.target)
            ) {
                closePopup();
            }
        };

        document.addEventListener('click', closePopupOnOutsideClick);

        return () => {
            document.removeEventListener('click', closePopupOnOutsideClick);
        };
    }, []);

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <>
            <Header
                user={user}
                items={items}
                activeItem={activeItem}
                handleLogout={handleLogout}
                //Popup props
                ref={popupRef}
                openPopup={openPopup}
                isPopupOpen={isPopupOpen}
            />
        </>
    );
});

export default HeaderContainer;
