import { Button, Col, Row } from "react-bootstrap";
import { CameraType } from "../../../models/camera.models";
import api, { endpoints } from "../../../utils/api";
import { ArrowUpIcon, ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, MagnifyingGlassPlusIcon, MagnifyingGlassMinusIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from "react";

import '../../../styles/Camera.scss';

interface CameraProps {
    camera: CameraType;
    onClick?: () => void;
    isSingleView?: boolean;
}

export const Camera: React.FC<CameraProps> = ({ camera, onClick, isSingleView }) => {
    const [src, setSrc] = useState('');

    useEffect(() => {
        const imgSrc = `${endpoints.camera}/mjpeg?username=${encodeURIComponent(
            camera.username
        )}&password=${encodeURIComponent(
            camera.password
        )}&cameraUrl=${encodeURIComponent(
            camera.url
        )}`;

        setSrc(imgSrc);

        return () => {
            setSrc(''); // Clean up on component unmount
        };
    }, [camera]);

    async function sendCameraCommand(url: string) {
        const { username, password } = camera;

        await api.get(endpoints.camera + `/control?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&controlUrl=${encodeURIComponent(url)}`)
            .catch(error => console.error('Error sending camera command:', error));
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h4 style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>{camera.name}</h4>
                </Col>
                <Col lg={isSingleView ? 9 : 12} className="text-center">
                    <img
                        src={src}
                        alt={`Camera Feed ${camera.name}`}
                        style={isSingleView ? { width: "100%" } : { maxHeight: "30vh", width: "auto", display: "inline-block" }}
                    />
                </Col>
                {isSingleView && <Col lg={3} className="d-flex flex-column justify-content-end">
                    <div className="control-group">
                        {camera.tiltUpUrl &&
                            <Button onClick={() => sendCameraCommand(camera.tiltUpUrl)} className="button-direction button-up">
                                <ArrowUpIcon className="icon" />
                            </Button>
                        }
                        <div className="inner-control-group">
                            {camera.tiltLeftUrl &&
                                <Button onClick={() => sendCameraCommand(camera.tiltLeftUrl)} className="button-direction button-left">
                                    <ArrowLeftIcon className="icon" />
                                </Button>
                            }
                            <div className="control-group">
                                {camera.zoomInUrl &&
                                    <Button onClick={() => sendCameraCommand(camera.zoomInUrl)} className="button-zoom">
                                        <MagnifyingGlassPlusIcon className="icon" />
                                    </Button>
                                }
                                {camera.zoomOutUrl &&
                                    <Button onClick={() => sendCameraCommand(camera.zoomOutUrl)} className="button-zoom">
                                        <MagnifyingGlassMinusIcon className="icon" />
                                    </Button>
                                }
                            </div>
                            {camera.tiltRightUrl &&
                                <Button onClick={() => sendCameraCommand(camera.tiltRightUrl)} className="button-direction button-right">
                                    <ArrowRightIcon className="icon" />
                                </Button>
                            }
                        </div>
                        {camera.tiltDownUrl &&
                            <Button onClick={() => sendCameraCommand(camera.tiltDownUrl)} className="button-direction button-down">
                                <ArrowDownIcon className="icon" />
                            </Button>
                        }
                    </div>
                </Col>}
            </Row>
        </>
    );
};

export function parseCameraData(widgetJson: string | null, actuatorName: string) {
    if (widgetJson === null) {
        return null;
    }

    try {
        const cameraData = JSON.parse(widgetJson);
        return { ...cameraData, name: actuatorName };
    } catch (error) {
        console.error("Error parsing camera data:", error);
        return null;
    }
}