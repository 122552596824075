import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectIdentifier } from "../models/project.models";
import { Accordion, Container } from "react-bootstrap";
import ProjectAccordion from "./elements/warnings/ProjectAccordion";
import api, { endpoints } from "../utils/api";

const Warnings: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState<ProjectIdentifier[]>([]);

  useEffect(() => {
    const fetchProjectNames = async () => {
      const response = await api.get(endpoints.projects + "/allProjectNames");
      setProjects(response.data);
      setIsLoading(false);
    };
    fetchProjectNames();
  }, []);

  if (isLoading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <Container fluid>
      <h1>{t("warnings")}</h1>
      <strong><p style={{ whiteSpace: "pre-line" }}>{t("bigData")}</p></strong>
      <Accordion defaultActiveKey="0">
        <h2>{t("projectList")}</h2>
        {projects.length > 0 && projects.map((project) => (
          <ProjectAccordion key={project.projectId} project={project} />
        ))}
      </Accordion>
    </Container>
  );
};

export default Warnings;
