import React, { useState } from 'react';
import api, { endpoints } from '../../../utils/api';
import { User } from '../../../models/auth.models';
import { Accordion, Button, Form, Row, Col } from 'react-bootstrap';
import { ActuatorUpdateDto, ExtendedActuator, Unit } from '../../../models/project.models';
import { useTranslation } from 'react-i18next';
import { useValidationSchemas } from '../../../validations';
import UserCheckboxList from '../UserCheckboxList';
import SimpleToggle from '../../SimpleToggle';

const ActuatorAccordion: React.FC<ActuatorAccordionProps> = ({ unit }) => {
  const { nullableOnlyNumberValidation } = useValidationSchemas();
  const [usersWithRoles, setUsersWithRoles] = useState<User[]>([]);
  const [actuators, setActuators] = useState<ExtendedActuator[]>([]);
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<HasChanges>({});
  const [editedThresholds, setEditedThresholds] = useState<EditedThresholds>({});
  const [thresholdErrors, setThresholdErrors] = useState<ThresholdErrors>({});
  const [actuatorUserSelections, setActuatorUserSelections] = useState<{[topic: string]: User[];}>({});
  const [blockingWarnings, setBlockingWarnings] = useState<{[topic: string]: boolean;}>({});

  const { t } = useTranslation();


  const updateThresholds = (
    topic: string,
    field:
      | "thresholdTop"
      | "thresholdBottom"
      | "thresholdHours"
      | "thresholdMinutes"
      | "thresholdSeconds",
    newValue: number
  ) => {
    setEditedThresholds((prev: any) => ({
      ...prev,
      [topic]: {
        ...prev[topic],
        [field]: newValue,
      },
    }));
    setHasChanges((prev) => ({
      ...prev,
      [topic]: true,
    }));
  };

  const handleUserSelectionChange = (
    selectedUsers: User[],
    actuatorId: string
  ) => {
    setActuatorUserSelections((prevSelections) => ({
      ...prevSelections,
      [actuatorId]: selectedUsers,
    }));
    setHasChanges((prev) => ({
      ...prev,
      [actuatorId]: true,
    }));
  };

  const handleToggleChange = (topic: string, newValue: boolean) => {
    setBlockingWarnings((prev) => ({
      ...prev,
      [topic]: newValue,
    }));
    setHasChanges((prev) => ({
      ...prev,
      [topic]: true,
    }));
  }

  const handleCancelEdit = (topic: string, isReset: boolean) => {

    setThresholdErrors((prev: any) => {
      const newState = { ...prev };
      delete newState[topic];
      return newState;
    });

    setEditedThresholds((prev: any) => {
      const newState = { ...prev };
      delete newState[topic];
      return newState;
    });

    setBlockingWarnings((prev) => ({
      ...prev,
      [topic]: false,
    }));

    setActuatorUserSelections((prevSelections) => {
      const newState = { ...prevSelections };
      delete newState[topic];
      return newState;
    });

    setHasChanges((prev) => ({
      ...prev,
      [topic]: false,
    }));

    if (isReset){
      fetchActuators();
    }
  };

  const validateInput = (topic: string, address: string) => {
    const actuator =  actuators.find((actuator) => actuator.address === address);
    const thresholdTop = editedThresholds[topic]?.thresholdTop ?? actuator?.settings?.thresholdTop;
    const thresholdBottom = editedThresholds[topic]?.thresholdBottom ?? actuator?.settings?.thresholdBottom;
    const thresholdHours = editedThresholds[topic]?.thresholdHours ?? actuator?.settings?.thresholdHours;
    const thresholdMinutes = editedThresholds[topic]?.thresholdMinutes ?? actuator?.settings?.thresholdMinutes;
    const thresholdSeconds = editedThresholds[topic]?.thresholdSeconds ?? actuator?.settings?.thresholdSeconds;

    const schema = nullableOnlyNumberValidation;
    schema.validate(thresholdTop)
    .then(() => schema.validate(thresholdBottom))
    .then(() => schema.validate(thresholdHours))
    .then(() => schema.validate(thresholdMinutes))
    .then(() => schema.validate(thresholdSeconds))
    .then(() => {
      if (thresholdTop < thresholdBottom) {
        throw new Error(t("bottomThresholdSmallerThanTop"));
      }
    })
      .then(() => {
        handleUpdateData(topic, unit.gdasGuid, actuator as ExtendedActuator);
      })
      .catch((error) => {
        const errors: ThresholdErrors = {};
        errors[topic] = { thresholdBottom: t("bottomThresholdSmallerThanTop") };
        setThresholdErrors((prevErrors) => ({
          ...prevErrors,
          ...errors,
        }));
      });
  };

  const handleUpdateData = async (
    topic: string,
    gdasGuid: string,
    actuator: ExtendedActuator,
    isReset: boolean = false
  ) => {
    setDisableButtons(true);
    const address = actuator.address;
    const thresholdTop = isReset ? null : editedThresholds[topic]?.thresholdTop ?? actuator.settings?.thresholdTop;
    const thresholdBottom = isReset ? null : editedThresholds[topic]?.thresholdBottom ?? actuator.settings?.thresholdBottom;
    const thresholdHours = isReset ? null : editedThresholds[topic]?.thresholdHours ?? actuator.settings?.thresholdHours;
    const thresholdMinutes = isReset ? null : editedThresholds[topic]?.thresholdMinutes ?? actuator.settings?.thresholdMinutes;
    const thresholdSeconds = isReset ? null : editedThresholds[topic]?.thresholdSeconds ?? actuator.settings?.thresholdSeconds;
    const receivers = isReset ? [] : actuatorUserSelections[topic]?.map((user) => user.email) ?? actuator.settings?.receivers ?? [];
    const isBlocking = isReset ? false : blockingWarnings[topic] ?? actuator.settings?.isBlockingWarning ?? false;

    const dto: ActuatorUpdateDto = {
      gdasGuid,
      address,
      thresholdTop,
      thresholdBottom,
      thresholdHours,
      thresholdMinutes,
      thresholdSeconds,
      receivers,
      isBlockingWarning: isBlocking,
    };

    try {
      await api.put(endpoints.actuators + "/actuator-settings", dto);
      if (isReset) {
        handleCancelEdit(topic, isReset);
      } else {
        setHasChanges((prev) => ({
          ...prev,
          [topic]: false,
        }));
        setThresholdErrors((prevErrors) => {
          const newState = { ...prevErrors };
          delete newState[topic];
          return newState;
        });
      }
    } catch (error: any) {
      if (error.response?.status === 400) {
        setThresholdErrors((prev: any) => ({
          ...prev,
          [topic]: error.response.data,
        }));
      }
    }
    setDisableButtons(false);
  }

  const fetchActuators = async () => {
    const response = await api.get(`${endpoints.actuators}/actuatorNames/${unit.id}`);
    setActuators(response.data);
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get(endpoints.accounts + "/allUsersWithRoles");
      setUsersWithRoles(response.data);
    } catch (error) {
      //console.error('Error fetching users with roles:', error);
    }
  };

  return (
    <Accordion.Item eventKey={unit.id}>
      <Accordion.Header onClick={() => {
        if (actuators.length === 0) fetchActuators();
        if (usersWithRoles.length === 0) fetchUsers();
      }}>{unit.name}</Accordion.Header>
      <Accordion.Body>
        {actuators.length > 0 && actuators.map((actuator, actuatorIndex) => {
            const topic = `${unit.gdasGuid}/${actuator.address}`;
            return (
              <Accordion key={topic + "-" + actuatorIndex}>
                <Accordion.Item eventKey={String(actuatorIndex)} key={topic}>
                  <Accordion.Header>{actuator.name}</Accordion.Header>
                  <Accordion.Body>
                    <Form>
                      <Row>
                        <Col md={4}>
                          <p>{t("value")}:</p>
                        </Col>
                        <Col md={8}>
                          <p>{actuator.lastValue.toFixed(actuator.decimals ?? 2)}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <p>{t("decimals")}:</p>
                        </Col>
                        <Col md={8}>
                          <p>{actuator.decimals}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <p>{t("topThreshold")}:</p>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            style={{
                              marginRight: "0.5rem",
                            }}
                            type="number"
                            step={0.1}
                            value={editedThresholds[topic]?.thresholdTop ?? actuator.settings?.thresholdTop ?? ""}
                            onChange={(e) => {
                              updateThresholds(
                                topic,
                                "thresholdTop",
                                parseFloat(e.target.value)
                              );
                            }}
                            onClick={(e) => {
                              const target =
                                e.target as HTMLInputElement;
                              target.select();
                            }}
                          />{" "}
                          {actuator?.physicalUnit}
                        </Col>
                      </Row>
                      {thresholdErrors[topic]
                        ?.thresholdTop && (
                          <Row>
                            <div style={{ color: "red" }}>
                              {
                                thresholdErrors[topic].thresholdTop
                              }
                            </div>
                          </Row>
                        )}
                      <Row>
                        <Col md={4}>
                          <p>{t("bottomThreshold")}:</p>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            style={{
                              marginRight: "0.5rem",
                            }}
                            type="number"
                            step={0.1}
                            value={editedThresholds[topic]?.thresholdBottom ?? actuator.settings?.thresholdBottom ?? ""}
                            onChange={(e) => {
                              updateThresholds(
                                topic,
                                "thresholdBottom",
                                parseFloat(e.target.value)
                              );
                            }}
                            onClick={(e) => {
                              const target =
                                e.target as HTMLInputElement;
                              target.select();
                            }}
                          />{" "}
                          {actuator?.physicalUnit}
                        </Col>
                      </Row>
                      {thresholdErrors[topic]
                        ?.thresholdBottom && (
                          <Row>
                            <div style={{ color: "red" }}>
                              {
                                thresholdErrors[topic].thresholdBottom
                              }
                            </div>
                          </Row>
                        )}
                      <Row>
                        <Col md={4}>
                          <p>{t("timeThreshold")}:</p>
                        </Col>
                        <Col
                          md={8}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Form.Control
                            style={{
                              marginRight: "0.5rem",
                            }}
                            type="number"
                            step={1}
                            placeholder={t("hours")}
                            value={editedThresholds[topic]?.thresholdHours ?? actuator.settings?.thresholdHours}
                            min={0}
                            onChange={(e) => {
                              updateThresholds(
                                topic,
                                "thresholdHours",
                                parseInt(e.target.value)
                              );
                            }}
                            onClick={(e) => {
                              const target =
                                e.target as HTMLInputElement;
                              target.select();
                            }}
                          />{" "}
                          {t("h")}
                          <Form.Control
                            style={{
                              margin: "0 0.5rem",
                            }}
                            type="number"
                            step={1}
                            placeholder={t("minutes")}
                            value={editedThresholds[topic]?.thresholdMinutes ?? actuator.settings?.thresholdMinutes}
                            min={0}
                            max={59}
                            onChange={(e) => {
                              updateThresholds(
                                topic,
                                "thresholdMinutes",
                                parseInt(e.target.value)
                              );
                            }}
                            onClick={(e) => {
                              const target =
                                e.target as HTMLInputElement;
                              target.select();
                            }}
                          />{" "}
                          {t("min")}
                          <Form.Control
                            style={{
                              margin: "0 0.5rem",
                            }}
                            type="number"
                            step={1}
                            placeholder={t("seconds")}
                            value={editedThresholds[topic]?.thresholdSeconds ?? actuator.settings?.thresholdSeconds}
                            min={0}
                            max={59}
                            onChange={(e) => {
                              updateThresholds(
                                topic,
                                "thresholdSeconds",
                                parseInt(e.target.value)
                              );
                            }}
                            onClick={(e) => {
                              const target =
                                e.target as HTMLInputElement;
                              target.select();
                            }}
                          />{" "}
                          {t("s")}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <p>{t("isBlocking")}:</p>
                        </Col>
                        <Col md={8}>
                          <SimpleToggle
                            id={`blocking-warning-toggle-${topic}`}
                            value={
                              blockingWarnings[topic]
                                ? "1"
                                : "0"
                            }
                            labels={["warningNonBlocking", "warningBlocking"]}
                            onToggle={() => handleToggleChange(topic, !blockingWarnings[topic])}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <p>{t("receivers")}:</p>
                        </Col>
                        <Col md={8}>
                          <UserCheckboxList
                            allUsers={usersWithRoles}
                            initialSelectedUsers={
                              actuator.settings?.receivers ?? []
                            }
                            selectedUsers={
                              actuatorUserSelections[topic]
                            }
                            setSelectedUsers={
                              handleUserSelectionChange
                            }
                            additionalKey={
                              topic
                            }
                          />
                        </Col>
                      </Row>
                      {hasChanges[topic] && (
                        <Row
                          style={{ marginTop: "1rem" }}
                        >
                          <Col md={2}>
                            <Button
                              variant="primary"
                              disabled={disableButtons}
                              onClick={() => validateInput(topic, actuator.address)}
                            >
                              {t("save")}
                            </Button>
                          </Col>
                          <Col md={2}>
                            <Button
                              variant="secondary"
                              disabled={disableButtons}
                              onClick={() =>
                                handleCancelEdit(topic, false)
                              }
                            >
                              {t("cancel")}
                            </Button>
                          </Col>
                        </Row>
                      )}
                      <Row
                        style={{ marginTop: "1rem" }}
                      >
                        <Col>
                          <Button
                            variant="outline-primary"
                            disabled={disableButtons}
                            onClick={() =>
                              handleUpdateData(
                                topic,
                                unit.gdasGuid,
                                actuator,
                                true // isReset
                              )
                            }
                          >
                            {t("reset")}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          }
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ActuatorAccordion;

interface ActuatorAccordionProps {
  unit: Unit;
}

interface HasChanges {
  [topic: string]: boolean;
}

interface EditedThresholds {
  [topic: string]: {
    thresholdTop: number;
    thresholdBottom: number;
    thresholdHours: number;
    thresholdMinutes: number;
    thresholdSeconds: number;
  };
}

interface ThresholdErrors {
  [topic: string]: {
    thresholdTop?: string;
    thresholdBottom?: string;
  };
}