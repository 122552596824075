import { Button } from 'react-bootstrap';
import { Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import Confirm from './Confirm';
import GenericList from './GenericList';
import Pagination from './Pagination';
import api from '../../utils/api';
import { DateTime } from 'luxon';

export default function EntityIndex<T>(props: entityIndexProps<T>) {

    const [entities, setEntities] = useState<T[]>([]);
    const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(30);
    const [page, setPage] = useState(1);

    const [requestPending, _] = useState(false);
    const [reloading, setReloading] = useState<boolean>(false);

    useEffect(() => {
        loadData();
    }, [page, recordsPerPage, reloading, props.foundedFiles, props.newLoadFile]);

    function loadData() {
        console.log({ page, recordsPerPage, requestPending })

        api
            .get(props.url, {
                params: { page, recordsPerPage, requestPending },
            })
            .then((response: AxiosResponse<T[]>) => {
                const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
                setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
                setEntities(response.data);

                // for open request amount
                if (props.requestAmount !== undefined) {
                    props.requestAmount(entities.length);
                    setReloading((prev) => !prev);
                }
            });
    }

    async function deleteEntity(id: string) {
        try {
            await api.delete(`${props.url}/${id}`);
            loadData();
        } catch (error) {
        }
    }

    const buttons = (id: string, editURL: string) => (
        <>
            {/* <Link to={editURL}>
            <Button variant="warning">Edit</Button>
        </Link> */}
            <Button variant="danger" onClick={() => Confirm(() => deleteEntity(id))}>
                Delete
            </Button>
        </>
    );

    // use name of item as key for sorting decision
    function sortItems(key: string, order: string) {
        setEntities(
            [...entities].sort((a, b) => {
                // adjust for tag - DTO discrepancies
                if (key === 'date') {
                    key = 'createdDate';
                } else if (key === 'sharer') {
                    key = 'owner';
                }
                // bypass TypeScript safety since this is a generic function
                // and cannot know the type beforehand, but WE know it will work
                const aValue = (a as any)[key];
                const bValue = (b as any)[key];

                if (aValue !== undefined && bValue !== undefined) {
                    if (key === 'createdDate') {
                        const d1 = DateTime.fromISO(aValue).toMillis();
                        const d2 = DateTime.fromISO(bValue).toMillis();
                        if (order === 'asc') {
                            return d1 - d2;
                        } else {
                            return d2 - d1;
                        }
                    } else if (key === 'size') {
                        if (order === 'asc') {
                            return aValue - bValue;
                        } else {
                            return bValue - aValue;
                        }
                    } else if (typeof aValue === 'string' && typeof bValue === 'string') {
                        if (order === 'asc') {
                            return aValue.localeCompare(bValue);
                        } else {
                            return bValue.localeCompare(aValue);
                        }
                    }
                } else {
                    // extra case for different naming decision in backend
                    key = 'fileName';
                    const aValue = (a as any)[key];
                    const bValue = (b as any)[key];
                    if (aValue !== undefined && bValue !== undefined) {
                        if (typeof aValue === 'string' && typeof bValue === 'string') {
                            if (order === 'asc') {
                                return aValue.localeCompare(bValue);
                            } else {
                                return bValue.localeCompare(aValue);
                            }
                        }
                    }
                }
                return 0;
            })
        );
    }

    return (
        <>
            <div id="sorting-div">
                <h3>{props.title}</h3>
                {/* <Dropdown as={ButtonGroup} drop="end">
                    <Button variant="primary">Sort by:</Button>

                    <Dropdown.Toggle split variant="primary" />

                    <Dropdown.Menu id="sort-menu">
                        {props.searchCriteria.map((criteria, index) => (
                            <Dropdown.Item key={index}>
                                <div id="sort-direction">
                                    {criteria}
                                    <div>
                                        <i
                                            className="bi bi-caret-up-square-fill"
                                            onClick={() => sortItems(criteria.toLowerCase(), 'asc')}
                                        ></i>{' '}
                                        |{' '}
                                        <i
                                            className="bi bi-caret-down-square-fill"
                                            onClick={() =>
                                                sortItems(criteria.toLowerCase(), 'desc')
                                            }
                                        ></i>
                                    </div>
                                </div>
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>

            <GenericList list={entities}>{props.children(entities!, buttons, sortItems)}</GenericList>

            <div id="pagination-container">
                <Pagination
                    currentPage={page}
                    totalAmountOfPages={totalAmountOfPages}
                    onChange={(newPage) => setPage(newPage)}
                />
            </div>
        </>
    );
}

interface entityIndexProps<T> {
    entityName: string;
    title: string;
    url: string;
    searchCriteria: string[];
    requestPending?: boolean;
    requestAmount?: Dispatch<SetStateAction<number>>;
    children(entities: T[], buttons: (id: string, editUrl: string) => ReactElement, sortItems: (key: string, order: string) => void): ReactElement;
    foundedFiles?: T[]
    newLoadFile?: boolean;
}
