import {
    ArrowRightIcon,
    ArrowTrendingUpIcon,
    ArrowTrendingDownIcon,
} from '@heroicons/react/24/outline';

const iconMap : IconMap = {
    'arrow-right': <ArrowRightIcon />,
    'arrow-trending-up': <ArrowTrendingUpIcon />,
    'arrow-trending-down': <ArrowTrendingDownIcon />,
};

export const getIcon = (iconName: string) => {
    return iconMap[iconName] || null;
};

interface IconMap {
    [key: string]: JSX.Element | null;
}