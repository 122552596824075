import React, { useEffect, useState, useContext } from 'react';
import api, { endpoints } from '../utils/api';
import AuthenticationContext from './auth/AuthenticationContext';
import { User } from '../models/auth.models';
import '../styles/UserManagement.scss';
import { Form as ReactForm, Button, Row, Col, Container, Accordion } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from "react-i18next";
import { useValidationSchemas } from '../validations';
import * as Yup from 'yup';

const UserManagement: React.FC = () => {

    const { currentUser, isAdmin, isProjectOwner } = useContext(AuthenticationContext);
    const { t } = useTranslation();
    const {
        nullableOnlyLetterValidation,
        nameValidation,
        emailValidation,
        passwordValidation,
      } = useValidationSchemas();

      const initialValues = {
        firstName: '',
        lastName: '',
        alias: '',
        email: '',
        password: '',
        role: 'User'
    };

    const validationSchema = Yup.object({
        firstName: nameValidation,
        lastName: nameValidation,
        alias: nullableOnlyLetterValidation,
        email: emailValidation,
        password: passwordValidation
    });

    const [usersWithRoles, setUsersWithRoles] = useState<User[]>([]);
    const [allModules, setAllModules] = useState<UserModule[]>([]);
    const [originalUsers, setOriginalUsers] = useState<User[]>([]);
    const [editedUsers, setEditedUsers] = useState<{ [email: string]: User }>({});
    const [hasChanges, setHasChanges] = useState<HasChanges>({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAdmin() || isProjectOwner()) {
            fetchUsersWithRoles();
            fetchModules();
        }
    }, []);

    const fetchUsersWithRoles = async () => {
        try {
            const response = await api.get(endpoints.accounts + '/allUsersWithRoles');
            setUsersWithRoles(response.data);
            setOriginalUsers(response.data);
        } catch (error) {
            //console.error('Error fetching users with roles:', error);
        }
    };

    const fetchModules = async () => {
        try {
            const response = await api.get(endpoints.modules + '/modules');
            setAllModules(response.data);
        } catch (error) {
            //console.error('Error fetching modules:', error);
        }
    };

    const handleUpdateUser = async (email: string) => {
        try {
            const user = editedUsers[email];
            if (!user) {
               alert(t("userNotFound"));
            }
            var formUser: FormValues = {
                firstName: user.firstName,
                lastName: user.lastName,
                alias: user.alias,
                email: user.email,
                password: "",
                role: user.roles[0]
            };
            setIsLoading(true);
            var response = await api.put(endpoints.accounts + `/user/${email}`, formUser);
            if (response.data == ""){
                fetchUsersWithRoles();
                setHasChanges(prev => ({
                    ...prev,
                    [email]: false,
                }));
            }
            else if(response.data.Error != null){
                alert(response.data.Error);
            }
            setIsLoading(false);
        } catch (error) {
            //console.error('Error updating user:', error);
        }
    }

    const handleCreateUser = async (values: FormValues, { setSubmitting, setValues, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; setValues: (values: FormValues) => void; resetForm: () => void }) => {
        try {
            const response = await api.post(`${endpoints.accounts}/user`, values);
            if (response.data == ""){
                fetchUsersWithRoles();
                setValues(initialValues);
                setOriginalUsers(response.data);
                resetForm();
            }
            else if(response.data.Error != null){
                alert(response.data.Error);
            }

        } catch (error) {
            //console.error("Error creating user: ", error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleUserChange = (email: string, field: keyof User, value: any) => {
        setEditedUsers(prev => {
            const currentUserEdits = prev[email];
            const originalUserData = originalUsers.find(user => user.email === email);
            const updatedUserData = { ...originalUserData, ...currentUserEdits, [field]: value };

            return {
                ...prev,
                [email]: updatedUserData,
            };
        });
        setHasChanges(prev => ({
            ...prev,
            [email]: true,
        }));
    };

    const resetUserEdits = (email: string) => {
        setEditedUsers(prev => {
            const updatedEdits = { ...prev };
            if (updatedEdits[email]) {
                updatedEdits[email] = originalUsers.find(user => user.email === email) || updatedEdits[email];
            }
            return updatedEdits;
        });
        setHasChanges(prev => {
            const updatedChanges = { ...prev };
            updatedChanges[email] = false;
            return updatedChanges;
        });
    };

    const handleDeleteUser = async (email: string) => {
        if (currentUser && email === currentUser.email) {
            alert(t("deleteOwnAccount"));
            return;
        }

        try {
            await api.delete(endpoints.accounts + `/user/${email}`);
            fetchUsersWithRoles();
        } catch (error) {
            //console.error('Error deleting user:', error);
        }
    };

    const toggleModule = async (userEmail: string, moduleId: string) => {
        try {
            const user = usersWithRoles.find(u => u.email === userEmail);
            if (!user) {
                throw new Error("User not found");
            }

            const isModuleAssigned = user.modules.includes(moduleId);

            const payload = { userEmail: userEmail, moduleId: moduleId };

            if (isModuleAssigned) {
                await api.post(endpoints.modules + '/unassignUserFromModule', payload);
            } else {
                await api.post(endpoints.modules + '/assignUserToModule', payload);
            }

            fetchUsersWithRoles();

        } catch (error) {
            //console.error('Error toggling module:', error);
        }
    };

    const addUserMarginField = "0.5rem 0rem";

    return (
        <Container fluid>
            <h1>{t("userManagement")}</h1>
            <div className='user-form-section'>
                <h2>{t("addUser")}</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleCreateUser}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Row>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field name="firstName" type="text" className="form-control" placeholder={t("firstNamePlaceholder")} />
                                        <ErrorMessage name="firstName" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field name="lastName" type="text" className="form-control" placeholder={t("lastNamePlaceholder")} />
                                        <ErrorMessage name="lastName" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field name="alias" type="text" className="form-control" placeholder={t("aliasEmailPlaceholder")} />
                                        <ErrorMessage name="alias" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field name="email" type="email" className="form-control" placeholder={t("emailPlaceholder")} />
                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field name="password" type="password" className="form-control" placeholder={t("passwordPlaceholder")} />
                                        <ErrorMessage name="password" component="div" className="text-danger" />
                                    </div>
                                </Col>
                                <Col xs={12} md={6} lg={4} style={{margin: addUserMarginField}}>
                                    <div className="form-group">
                                        <Field as="select" className="form-control" name="role">
                                            <option value="User">{t("user")}</option>
                                            <option value="ProjectOwner">{t("projectOwner")}</option>
                                            {currentUser?.roles.includes('SuperAdmin') && <option value="SuperAdmin">{t("superAdmin")}</option>}
                                        </Field>
                                    </div>
                                </Col>
                                <Row xs={12} md={6} lg={4} style={{margin: addUserMarginField, display: "flex", justifyContent: "center"}}>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>{t("addUser")}</Button>
                                </Row>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>

            <div className="user-list-section mt-5">
                <h2>{t("userList")}</h2>
                <div className="table-container">
                    <Accordion>
                        {usersWithRoles.map((user, index) => (
                            <Accordion.Item eventKey={String(index)} key={user.email}>
                                <Accordion.Header>
                                    {user.firstName} {user.lastName} - {user.email} - {t(user.roles)}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <h3>{t("modules")}</h3>
                                    <ul>
                                        {allModules.map(module => (
                                            <li key={module.id}>
                                                <ReactForm.Check
                                                    type="switch"
                                                    id={`custom-switch-${module.id}`}
                                                    label={module.name}
                                                    checked={user.modules.includes(module.id)}
                                                    onChange={() => toggleModule(user.email, module.id)}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                    <h3>{t("user")}</h3>
                                    <Row className="form-group">
                                        <Col md={2}>
                                            <label>{t("firstNamePlaceholder")}</label>
                                        </Col>
                                        <Col md={10}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedUsers[user.email]?.firstName || user.firstName}
                                                onChange={e => handleUserChange(user.email, 'firstName', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={2}>
                                            <label>{t("lastNamePlaceholder")}</label>
                                        </Col>
                                        <Col md={10}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedUsers[user.email]?.lastName || user.lastName}
                                                onChange={e => handleUserChange(user.email, 'lastName', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={2}>
                                            <label>{t("aliasEmailPlaceholder")}</label>
                                        </Col>
                                        <Col md={10}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editedUsers[user.email]?.alias ?? user.alias ?? ""}
                                                onChange={e => handleUserChange(user.email, 'alias', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={2}>
                                            <label>{t("emailPlaceholder")}</label>
                                        </Col>
                                        <Col md={10}>
                                            <input
                                                type="email"
                                                className="form-control"
                                                value={editedUsers[user.email]?.email || user.email}
                                                onChange={e => handleUserChange(user.email, 'email', e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="form-group">
                                        <Col md={2}>
                                            <label>{t("rolePlaceholder")}</label>
                                        </Col>
                                        <Col md={10}>
                                        <select
                                            className="form-control"
                                            value={editedUsers[user.email]?.roles || user.roles}
                                            onChange={e => handleUserChange(user.email, 'roles', [e.target.value])}
                                        >
                                            <option value="User">{t("user")}</option>
                                            <option value="ProjectOwner">{t("projectOwner")}</option>
                                            {currentUser?.roles.includes('SuperAdmin') && <option value="SuperAdmin">{t("superAdmin")}</option>}
                                        </select>
                                        </Col>
                                    </Row>
                                    {
                                        hasChanges[user.email] &&
                                        <Row style={{marginTop: "1rem"}}>
                                            <Col md={2}>
                                                <Button variant="primary" onClick={() => handleUpdateUser(user.email)} disabled={isLoading}>{t("update")}</Button>
                                            </Col>
                                            <Col md={2}>
                                                <Button variant="secondary" onClick={() => resetUserEdits(user.email)}>{t("cancel")}</Button>
                                            </Col>
                                        </Row>
                                    }
                                    <Row style={{marginTop: "1rem"}}>
                                        <Col>
                                            <Button variant="danger" onClick={() => handleDeleteUser(user.email)} disabled={currentUser?.email === user.email}>{t("archive")}</Button>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </Container>
    );
};

interface FormValues {
    firstName: string;
    lastName: string;
    alias: string | null;
    email: string;
    password: string;
    role: string;
}

interface HasChanges {
    [email: string]: boolean;
}

export default UserManagement;