import { useEffect, useState } from "react";
import { Actuator, Building, Unit } from "../../models/project.models";
import ToggleWidget from "./widgets/ToggleWidget";
import mqtt from "mqtt";
import StateViewWidget from "./widgets/StateViewWidget";
import ValueViewWidget from "./widgets/ValueViewWidget";
import StateIconWidget from "./widgets/StateIconWidget";
import api, { endpoints } from "../../utils/api";

const ActuatorCard: React.FC<{ building: Building, unit: Unit, actuator: Actuator, mqttMessage: string }> = ({ building, unit, actuator, mqttMessage }) => {
    const [mqttValue, setMqttValue] = useState<string>('');

    const widgetConfig = actuator.widgetJson ? JSON.parse(actuator.widgetJson) : null;
    const labels = widgetConfig?.labels || ['OK', 'Off'];
    const colours = widgetConfig?.colours || ['green', 'red'];
    const offOnLabels = ['Off', 'On'];
    const icons = widgetConfig?.icons || ["arrow-right", "arrow-trending-up", "arrow-trending-down"];

    useEffect(() => {
        let mqttValue;

        if (!mqttMessage) {
            return;
        }

        const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.\d{4}$/;

        var trimmedMessage = mqttMessage.trim().replace("\u0000", "");

        if (dateRegex.test(trimmedMessage)) {
            mqttValue = trimmedMessage;
        }
        else if (timeRegex.test(trimmedMessage)) {
            mqttValue = trimmedMessage;
        }
        else if (!isNaN(parseFloat(trimmedMessage))) {
            mqttValue = parseFloat(trimmedMessage).toFixed(actuator.decimals);
        }
        else {
            mqttValue = trimmedMessage;
        }

        setMqttValue(mqttValue);
    }, [mqttMessage, actuator.decimals]);

    const handlePublish = (message: string) => {
        const options = {
            username: building.mqttUser,
            password: building.mqttPass
        };

        const client = mqtt.connect(building.mqttServer, options);
        const topic = unit.gdasGuid + "/" + actuator.address;

        client.on('connect', () => {
            client.publish(topic, message, { retain: true });
            client.end();
        });

        var actuatorChangeDto = {
            server: building.mqttServer,
            topic: topic,
            actuatorId: actuator.id,
            value: parseFloat(message)
        };

        api.post(endpoints.actuatorhistory + "/user-interaction", actuatorChangeDto);
    };

    function renderWidgetByType() {
        switch (actuator.inputType) {
            case -1:
                if (widgetConfig !== null) {
                    return (
                        <StateViewWidget
                            value={mqttValue}
                            labels={labels}
                            colours={colours}
                        />
                    );
                } else {
                    return (
                        <ValueViewWidget
                            value={mqttValue}
                            physicalUnit={actuator.physicalUnit ?? ''}
                        />
                    );
                }
            case 0:
                return (
                    <ValueViewWidget
                        value={mqttValue}
                        physicalUnit={actuator.physicalUnit ?? ''}
                    />
                );
            case 1:
                return (
                    <ToggleWidget
                        id={`toggle-widget-${actuator.id}`}
                        value={mqttValue}
                        labels={offOnLabels}
                        onToggle={() => handlePublish(mqttValue === '1' ? '0' : '1')}
                    />
                );
            case 2:
                return (
                    <StateViewWidget
                        value={mqttValue}
                        labels={labels}
                        colours={colours}
                    />
                );
            case 3:
                return (
                    <StateIconWidget
                        value={mqttValue}
                        labels={labels}
                        icons={icons}
                    />
                );
            default:
                // Return null or a default component if none of the cases match
                return null;
        }
    }
    

    return (
        <div className="card" style={{ height: '100%', width: '100%', padding: '1rem', border: '0px', borderRadius: '0px' }}>
            <p>{actuator.name}</p>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                {renderWidgetByType()}
            </div>

        </div>
    );
};

export default ActuatorCard;