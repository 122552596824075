import React, { useEffect, useState } from 'react';
import { Squares2X2Icon } from '@heroicons/react/24/outline';
import HeaderPopup from '../HeaderPopup';
import { HeaderProps } from '../../models/Headers.props';
import { useNavigate } from 'react-router-dom';
import logo from '../../resources/logo_wallis.png';
import api, { endpoints } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../icons/CheckIcon';
import { EnvelopeIcon } from '../../icons/EnvelopeIcon';
import { WarningDto } from '../../models/warning.models';

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
    (
        {
            user,
            items,
            activeItem,
            handleLogout,
            //Popup props
            openPopup,
            isPopupOpen,
        },
        ref
    ) => {
        const navigate = useNavigate()
        const { t } = useTranslation();

        const [isFocusInput, setIsFocusInput] = useState(false);
        const [warnings, setWarnings] = useState<WarningDto[]>([]);
        const [showWarning, setShowWarning] = useState<boolean>(false);

        const fetchWarning = async () => {
            try {
                const response = await api.get(endpoints.warnings);
                if (response.data != null) {
                    const warningsArray = Array.isArray(response.data) ? response.data : [response.data];
                    const blockingWarnings = warningsArray.filter((warning: any) => !warning.actuator?.isBlockingWarning);
                    setWarnings(blockingWarnings);
                    setShowWarning(blockingWarnings.length > 0);
                }
                else {
                    setShowWarning(false);
                    setWarnings([]);
                }
            } catch (error) {
                setShowWarning(false);
                setWarnings([]);
            }
        }

        const handleWarningAcknowledged = () => {
            // Remove the acknowledged warning and show the next one, if any
            setWarnings((prevWarnings) => prevWarnings.slice(1));
        }

        const handleSignWarning = async (warningId: string) => {
            try {
                const response = await api.put(endpoints.warnings + `/signwarning/${warningId}`);
                if (response.data === "") {
                    handleWarningAcknowledged();
                }
            } catch (error) {
            }
        };

        useEffect(() => {
            fetchWarning();
        }, [navigate]);

        useEffect(() => {
            //close logic dropdown (just close + after preview)
            const handledCloseDropdown = (e: any) => {
                const composed = e.composedPath();

                if (!composed.some((el: any) => el.dataset?.closeim || el.className === 'fade modal')) {

                    setIsFocusInput(false);
                    document.body.removeEventListener('click', handledCloseDropdown);
                }
            };

            document.body.addEventListener('click', handledCloseDropdown);
        }, [isFocusInput]);

        return (
            <header className="header">
                <img className="user__logo-desktop" src={logo} alt="logo" />
                {
                    showWarning && warnings.length > 0 && (
                        <div className="warning-wrapper">
                            <div className="warning-container">
                                <span><strong>{t("warnings")}</strong></span>
                                <div className="warning-content">
                                    <div className="warning-item">
                                        <span>{warnings[0].actuatorName} - {t("outsideThresholdZone")}</span>
                                        <CheckIcon color='green' onClick={() => handleSignWarning(warnings[0].warningId)} style={{ cursor: "pointer", marginLeft: "1rem" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="user-wrapper">
                    <img className="user__logo-mobile" src={logo} alt="logo" />
                    <div className="user__content">
                        <div className="user__data-wrapper">
                            {
                                <div className="user__text">
                                    <span style={{ whiteSpace: "normal", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                        {user?.firstName} {user?.lastName}
                                    </span>
                                </div>
                            }
                            <div></div>
                            <div className="user__envelope" onClick={() => navigate('/mail', { state: { showUserList: true } })}>
                                <EnvelopeIcon className="h-6 w-6 text-gray-500" />
                            </div>
                            <div className='user__settings' ref={ref}>
                                <Squares2X2Icon
                                    className="h-6 w-6 text-gray-500"
                                    onClick={openPopup}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <HeaderPopup
                    isOpen={isPopupOpen}
                    handleLogout={handleLogout}
                    items={items}
                    activeItem={activeItem}
                />
            </header>
        );
    }
);
export default Header;
