import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api, { endpoints } from "../utils/api";
import { Table } from "react-bootstrap";
import { WarningHistoryDto } from "../models/warning.models";
import EntityIndex from "./elements/EntityIndex";

const ConditionalTd: React.FC<ConditionalTdProps> = ({ isWarning, children }) => {
  const className = isWarning ? "text-danger" : "";
  return <td className={className}>{children}</td>;
};

export default function WarningHistory() {
  const { t } = useTranslation();
  const locale = localStorage.getItem("i18nextLng") ?? "en";

  return (
    <div>
      <h1>{t("warningHistory")}</h1>
      <p>{t("unsignedExplanation")}</p>
      <EntityIndex<WarningHistoryDto>
        entityName="File"
        title=""
        url={endpoints.warnings + "/warning-history/all"}
        searchCriteria={["actuatorName"]}>
        {(histories) =>
          <>
            <Table striped bordered hover responsive className="mt-3">
              <thead>
                <tr>
                  <th>{t("projectName")}</th>
                  <th>{t("projectOwner")}</th>
                  <th>{t("actuatorName")}</th>
                  <th>{t("unitName")}</th>
                  <th>{t("currentValue")}</th>
                  <th>{t("thresholdTop")}</th>
                  <th>{t("thresholdBottom")}</th>
                  <th>{t("signedBy")}</th>
                  <th>{t("createdDate")}</th>
                  <th>{t("lastModified")}</th>
                </tr>
              </thead>

              {histories !== undefined && histories.length > 0
                ?
                <tbody>
                  {histories.map((warning, index) => {
                    const isWarning = warning.signedBy === "unsignedWarning";
                    return (
                      <tr key={index}>
                        <ConditionalTd isWarning={isWarning}>{warning.projectName}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{warning.projectOwnerName}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{warning.actuatorName}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{warning.unitName}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{`${warning.currentValue} ${warning.physicalUnit}`}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{`${warning.thresholdTop} ${warning.physicalUnit}`}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{`${warning.thresholdBottom} ${warning.physicalUnit}`}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{warning.signedBy === "unsignedWarning" ? t("unsignedWarning") : warning.signedBy}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{new Date(warning.createdDate).toLocaleDateString(locale)} {new Date(warning.createdDate).toLocaleTimeString(locale)}</ConditionalTd>
                        <ConditionalTd isWarning={isWarning}>{new Date(warning.lastModified).toLocaleDateString(locale)} {new Date(warning.lastModified).toLocaleTimeString(locale)}</ConditionalTd>
                      </tr>
                    );
                  })}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td colSpan={10}>{t("noWarnings")}</td>
                  </tr>
                </tbody>
              }
            </Table>
          </>
        }
      </EntityIndex>
    </div>
  );
};

interface ConditionalTdProps {
  isWarning: boolean;
  children: React.ReactNode;
}
