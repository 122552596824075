import { ChevronDownIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

export const CustomToggle = React.forwardRef<
  HTMLAnchorElement,
  CustomToggleProps
>(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) onClick(e);
    }}
  >
    {children}
    <ChevronDownIcon style={{width: "auto", height: "20px"}} />
  </a>
));

export const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(
  ({ children, style, className }, ref) => {
    const [value, setValue] = useState("");
    const { t } = useTranslation();

    return (
      <div
        ref={ref}
        style={style}
        className={className}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder={t("typeToFilter")}
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child: any) =>
              !value || child.props.children.startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

interface CustomToggleProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

interface CustomMenuProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}
