import { useEffect, useState } from "react";
import api, { endpoints } from "../../../utils/api";
import { Building } from "../../../models/project.models";
import MapWithFixedBuildingActuatorCards from "../../elements/MapWithFixedBuildingActuatorCards";
import { useNavigate } from "react-router-dom";
import { moduleRegistry } from "../ModuleRegistry";

export default function Overview() {
    const navigate = useNavigate();
    const [pins, setPins] = useState<Building[]>([]);

    const actuatorOrder = [
        "text:Identification:allZones",
        "empty",
        "empty",
        "/2000/5/0",
        "/2000/7/0",
        "divider:",
        "text:Identification:allInterventionAreas",
        "2100/93/1",
        "2100/93/2",
        "2100/93/5",
        "2100/93/4",
        "2100/93/3",
        "2100/93/6",
        "2100/93/7"
    ];

    useEffect(() => {
        Promise.all([
            api.get(endpoints.buildings + '/moduleBuildings', {
                params: {
                    moduleIds: 'a18936c1-208f-463f-b864-a392e76b6f93', // piezo
                },
            }),
            api.get(endpoints.buildings + '/moduleBuildings', {
                params: {
                    moduleIds: '29f2d24a-b07b-4721-bda6-9a02f0ede08f', // brunnensteuerung
                },
            })
        ]).then((responses) => {
            const pins = [...responses[0].data, ...responses[1].data];
            setPins(pins);
        });
    }, []);

    const onPinSelected = (pin: Building) => {
        const moduleId = pin.module.id;
        const module = moduleRegistry[moduleId];
        if (module) {
            navigate(module.path, { state: { selectedBuilding: pin } });
        }
    };

    return (
        <>
            <MapWithFixedBuildingActuatorCards buildingId="f59cd020-9093-42b0-97d5-add4831210cc" actuatorOrder={actuatorOrder} pins={pins} onPinSelected={onPinSelected} callingComponent="overview"></MapWithFixedBuildingActuatorCards>
        </>
    );
}