import { useContext, useEffect, useState } from "react";
import AuthenticationContext from "../../auth/AuthenticationContext";
import api, { endpoints } from "../../../utils/api";
import { Building, Unit } from "../../../models/project.models";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Camera, parseCameraData } from "./Camera";
import { CameraType } from "../../../models/camera.models";
import { useParams } from "react-router-dom";
import { cameraOrder } from "../CameraRegistry";

export default function Cameras() {
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthenticationContext);
  var { cameraId } = useParams() as { cameraId: string };

  const [pins, setPins] = useState<Building[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState<CameraType | null>(null);

  useEffect(() => {
    //get active modules for user
    try {
      api
        .get(endpoints.buildings + "/moduleBuildings", {
          params: {
            moduleIds: '7a8b4e8d-8ce2-4c2d-841a-7edca5425522' //cameras
          }
        })
        .then((response) => {
          setPins(response.data);
        });
    } catch (error) {
      //console.error("Error fetching modules:", error);
    }
  }, [currentUser]);

  const handleCameraClick = (camera: CameraType) => {
    setSelectedCamera(camera);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const cameraActuators = pins
    .flatMap((building) => building.units.flatMap((unit: Unit) => unit.actuators))
    .filter((actuator) => actuator.inputType === 100);

  const cameras = cameraActuators
    .map((actuator) => parseCameraData(actuator.widgetJson, actuator.name))
    .filter(Boolean);

  const sortedCameras = cameras.sort((a, b) => {
    const indexA = cameraOrder.findIndex(order => order.id === a.id || order.name === a.name);
    const indexB = cameraOrder.findIndex(order => order.id === b.id || order.name === b.name);
    return indexA - indexB;
  });


  const singleCameraOrder = cameraOrder.find(order => order.id === cameraId)
  const singleCamera = sortedCameras.find(camera => camera.name === singleCameraOrder?.name);

  return (
    <>
      {cameraId && singleCamera ? (
        <>
          <Camera camera={singleCamera} isSingleView={true} />
        </>
      ) : (
        <>
          <Row>
            {sortedCameras.map((camera, index) => (
              <Col xl={6} key={index}>
                <Camera camera={camera} onClick={() => handleCameraClick(camera)} />
              </Col>
            ))}
          </Row>
        </>
      )}
      <Modal show={showModal} onHide={handleClose} size="xl">
        <Modal.Body>
          {selectedCamera && <Camera camera={selectedCamera} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}