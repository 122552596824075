import { Col, Container } from 'react-bootstrap';
import white_logo from '../../resources/white_logo.png';
import '../../styles/Footer.scss'
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    return(
        <>
            <Container className='bd-footer' id='f-main' style={{backgroundColor: "transparent", color: "white", justifyContent: "center"}}>
                <Col>
                    <img src={white_logo} className='logo' alt='logo' />
                    {`GDAS ${new Date().getFullYear().toString()}`}
                </Col>
                <Col>
                    <a style={{color: "white"}} href="https://gdas.swiss/imprint/">{t("imprint")}</a>
                    <a style={{color: "white"}} href="https://gdas.swiss/disclaimer/">{t("disclaimer")}</a>
                </Col>
            </Container>
        </>
    )
}