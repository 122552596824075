import axios from 'axios';
import moment, { isDate } from 'moment-timezone';

// Define the base URL from the environment variable
const apiBaseURL = process.env.REACT_APP_API_URL;

// Custom Axios Instance
const api = axios.create({
    baseURL: apiBaseURL,
    withCredentials: true,
});

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const isDateString = (value: any): boolean => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}$/;
    return typeof value === 'string' && dateRegex.test(value) && moment.utc(value).isValid();
};

// Add a Response Interceptor
api.interceptors.response.use(
    response => {
        // Traverse the response data and convert date strings to the Switzerland timezone
        const convertDatesInObject = (obj: any) => {
            Object.keys(obj).forEach(key => {
                const value = obj[key];

                if (isDateString(value)) {
                    obj[key] = moment.tz(value, 'UTC').tz(userTimeZone).format();
                } else if (typeof value === 'object' && value !== null) {
                    convertDatesInObject(value);
                }
            });
        };

        if (response.data) {
            convertDatesInObject(response.data);
        }

        // If the response is successful, simply return it
        return response;
    },
    error => {
        let errorMessage = "An unknown error occurred.";

        // Check if the error response contains the structured error message
        if (error.response && error.response.data && error.response.data.Message) {
            errorMessage = error.response.data.Message;
            console.error(`Statuscode: ${error.response.status} - Message: ${errorMessage}`);
        }

        // Throw the extracted error message
        throw new Error(errorMessage);
    }
);

// Define Endpoints
export const endpoints = {
    accounts: `${apiBaseURL}/accounts`,
    actuators: `${apiBaseURL}/actuators`,
    actuatorhistory: `${apiBaseURL}/actuatorhistory`,
    buildings: `${apiBaseURL}/buildings`,
    camera: `${apiBaseURL}/camera`,
    email: `${apiBaseURL}/emailTemplate`,
    messages: `${apiBaseURL}/mqtt`,
    modules: `${apiBaseURL}/modules`,
    projects: `${apiBaseURL}/projects`,
    units: `${apiBaseURL}/units`,
    userAccesses: `${apiBaseURL}/userAccesses`,
    warnings: `${apiBaseURL}/warnings`
}

// Export the customized api instance
export default api;
