import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function ModuleButton({
    name,
    description,
    path
}: Module) {

    return (
        <>
            <Link to={path} style={{ textDecoration: 'none' }}>
                <Card style={{ border: '0px', borderRadius: '0px' }}>
                    <Card.Body>
                        <Card.Title>{name}</Card.Title>
                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                        <Card.Text>
                            {description}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Link>
        </>
    )
}