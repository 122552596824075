import ModuleButton from "./ModuleButton";
import {
    MapPinIcon,
    ArrowTrendingUpIcon,
    VideoCameraIcon
} from '@heroicons/react/24/outline';

export const moduleRegistry: ModuleRegistry = {
    '29f2d24a-b07b-4721-bda6-9a02f0ede08f': { name: 'Well Control', component: ModuleButton, path: '/well-control', icon: <MapPinIcon /> },
    'a18936c1-208f-463f-b864-a392e76b6f93': { name: 'Piezo', component: ModuleButton, path: '/piezo', icon: <ArrowTrendingUpIcon />},
    '7a8b4e8d-8ce2-4c2d-841a-7edca5425522': { name: 'Cameras', component: ModuleButton, path: '/cameras', icon: <VideoCameraIcon />}
};

export const moduleConfig: { [id: string]: ModuleConfig} = {
    '29f2d24a-b07b-4721-bda6-9a02f0ede08f': { //Well Control
        pinStatusActuatorName: 'Betriebsmeldung',
        pinStatusColours: ['red', 'darkgreen', 'green', 'orange', 'orange', 'orange', 'red', 'red', 'red', 'red', 'red'],
        pinStatusTooltipMessage: ['Well no release', 'Well ready for operation', 'Well in operation', 'No operation', 'No operation', 'No operation', 'Well no release', 'Well no release', 'Well no release', 'Well no release', 'Well no release']
    },
    'a18936c1-208f-463f-b864-a392e76b6f93': { // piezo
        pinStatusActuatorName: 'Betriebsmeldung',
        pinStatusColours: ['darkgreen', 'orange', 'red', '', '', 'yellow'],
        pinStatusTooltipMessage: ['OK: > 50% daily data transmitted', 'Error: < 50% daily data transmitted', 'Fault: no daily data transmitted', '', '', 'Info: > 0.5m difference between yesterdays measured value and todays measured value']
    }
};