import { ReactElement, useContext } from "react";
import AuthenticationContext from "./AuthenticationContext";

export default function Authorized(props: authorizedProps) {
    const { isLoggedIn, currentUser } = useContext(AuthenticationContext);
    const hasRequiredRole = props.roles ? props.roles.some(role => currentUser?.roles.includes(role)) : true;

    return (
        <>
            {isLoggedIn && hasRequiredRole ? props.authorized : props.notAuthorized}
        </>
    )
}

interface authorizedProps {
    authorized: ReactElement;
    notAuthorized?: ReactElement;
    roles?: string[];
}
