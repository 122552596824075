import { useContext } from "react"
import AuthenticationContext from "../../auth/AuthenticationContext"


const useFeedback = () => {
    const {setSuccess, setMessage, setMode, setDuration} = useContext(AuthenticationContext)

    function done(message: string, mode: string, duration: number){
        setMessage(message)
        // color, see bootstrap variant for reference
        setMode(mode)
        // in ms, normally 3000
        setDuration(duration)
        setSuccess(true)
    }

    return { done }
}

export default useFeedback