import { useState } from "react";
import DisplayErrors from "../elements/DisplayErrors";
import Modal from "react-bootstrap/Modal";
import ResetPasswordForm from "./PasswordResetForm";
import api, { endpoints } from "../../utils/api";
import useFeedback from "../elements/customHooks/useFeedback";
import { useTranslation } from "react-i18next";

export default function ResetPasswordModal(props: ResetPasswordModalProps) {
    const { t } = useTranslation();
    const [errors, setErrors] = useState<string[]>([]);
    const { done } = useFeedback();

    async function setNewPassword(values: PasswordResetFormValues) {
        try {
            let response;
            if (values.currentPassword && props.changePassword) {
                // Change password because user is logged in
                response = await api.put(endpoints.accounts + "/password/change", {
                    currentPassword: values.currentPassword,
                    newPassword: values.password
                });
            } else {
                // Reset password because forgot password
                response = await api.put(endpoints.accounts + "/password/reset", {
                    newPassword: values.password,
                    token: props.token,
                    email: props.email
                });
            }

            if (response.status === 200 || response.data === "") {
                props.handleClose();
                done(t("passwordChangedSuccessfully"), "success", 3000);
            } else if (response.data.HttpStatusCode === 409 || response.data.HttpStatusCode === 400) {
                throw new Error(response.data.Error);
            } else {
                setErrors(response.data);
            }
        } catch (error) {
            const errorMessage = (error as Error).message;
            setErrors([errorMessage]);
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.changePassword ? t("changePassword") : t("resetPassword")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DisplayErrors errors={errors} />
                    <ResetPasswordForm onSubmit={setNewPassword} handleClose={props.handleClose} changePassword={props.changePassword} />
                </Modal.Body>
            </Modal>
        </>
    )
}

interface ResetPasswordModalProps {
    show: boolean;
    email: string;
    token?: string;
    handleClose(): void;
    changePassword?: boolean;
}

interface PasswordResetFormValues {
    currentPassword?: string;
    password: string;
    confirmPassword: string;
}
