import {
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageIcon } from "../icons/LanguageIcon";
import { switchLanguage, simplifyLanguageCode } from "../translations/LanguageSwitch";
import { DropdownDivider } from "react-bootstrap";

export default function HeaderPopup({
  items,
  activeItem,
  isOpen,
  handleLogout,
}: HeaderPopupProps) {

  const { t } = useTranslation();
  const currentLanguage = simplifyLanguageCode(localStorage.getItem("i18nextLng") || navigator.language);

  if (!isOpen) {
    return null;
  }

  const switchLanguageText = currentLanguage === 'en' ? t("switchToGerman") : t("switchToEnglish");

  return (
    <div className='popup'>
      <div className='popup-content'>
        <ul className='popup-sidebar'>
          {items.map((i, index) => {
            const isActive = i.name === activeItem.name;
            return (
              <li key={index} className={`${isActive ? "active" : ""} popup-item'}`}>
                <Link to={`${i.to}`}>
                  {i?.icon}
                  <span>{i.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        <div className='popup-item' onClick={switchLanguage}>
          <LanguageIcon />
          <p>{switchLanguageText}</p>
        </div>
        <div className='popup-item' onClick={handleLogout}>
          <ArrowRightOnRectangleIcon />
          <p>{t("signOut")}</p>
        </div>
      </div>
    </div>
  );
}

interface HeaderPopupProps {
  items: MenuItem[];
  activeItem: MenuItem;
  isOpen: boolean;
  handleLogout: () => void;
}

interface MenuItem {
  name: string;
  to: string;
  icon?: JSX.Element;
}
