const TextCard: React.FC<{ title: string, text: string }> = ({ title, text }) => {
    return (
        <div className="card" style={{ height: '100%', width: '100%', padding: '1rem', border: '0px', borderRadius: '0px' }}>
            <p>{title}</p>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <h1>
                    <strong>{text}</strong>
                </h1>
            </div>

        </div>
    );
};

export default TextCard;