import React, { useEffect, useState } from "react";

interface paginationProps {
    currentPage: number;
    totalAmountOfPages: number;
    radius: number;
    onChange(page: number): void;
}

interface linkModel {
    page: number;
    enabled: boolean;
    text: string;
    active: boolean;
}

export default function Pagination(props: paginationProps) {
    const [linkModels, setLinkModels] = useState<linkModel[]>([]);

    function selectPage(link: linkModel) {
        if (link.page === props.currentPage || !link.enabled) {
            return;
        }

        props.onChange(link.page);
    }

    function getClass(link: linkModel) {
        const classes = ["pointer"];

        if (link.active) {
            classes.push("active");
        }

        if (!link.enabled) {
            classes.push("disabled");
        }

        return classes.join(" ");
    }

    useEffect(() => {
        const links: linkModel[] = [];

        const previousPageEnabled = props.currentPage !== 1;
        const previousPage = props.currentPage - 1;
        links.push({
            text: 'Prev',
            page: previousPage,
            enabled: previousPageEnabled,
            active: false
        });

        let startPage = Math.max(1, props.currentPage - props.radius);
        let endPage = Math.min(props.totalAmountOfPages, props.currentPage + props.radius);

        // Adjust start and end pages to ensure at least five pages are shown
        if (props.totalAmountOfPages > 5) {
            if (endPage - startPage + 1 < 5) {
                if (startPage === 1) {
                    endPage = 5;
                } else if (endPage === props.totalAmountOfPages) {
                    startPage = props.totalAmountOfPages - 4;
                } else {
                    startPage = props.currentPage - 2;
                    endPage = props.currentPage + 2;
                }
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            links.push({
                text: `${i}`,
                page: i,
                enabled: true,
                active: props.currentPage === i
            })
        }

        const nextPageEnabled = props.currentPage !== props.totalAmountOfPages && props.totalAmountOfPages > 0;
        const nextPage = props.currentPage + 1;
        links.push({
            text: 'Next',
            page: nextPage,
            enabled: nextPageEnabled,
            active: false
        });

        setLinkModels(links);
    }, [props.currentPage, props.totalAmountOfPages, props.radius]);

    return (
        <nav>
            <ul className="pagination">
                {linkModels.map(link =>
                    <li key={link.text} onClick={() => selectPage(link)}
                        className={`page-item cursor ${getClass(link)}`}
                        style={{cursor: 'pointer'}}>
                        <span className="page-link">{link.text}</span>
                    </li>)}
            </ul>
        </nav>
    );
}

Pagination.defaultProps = {
    radius: 2
}
