import { useTranslation } from "react-i18next";

const ValueViewWidget: React.FC<ValueViewWidgetProps> = ({ value, physicalUnit }) => {
    const { t } = useTranslation();

    return (
        <>
            <h1>
                <strong>{value}</strong>
            </h1>
            {value !== '' ? (
                <h2>
                    &nbsp;{physicalUnit}
                </h2>
            ) : null}
        </>
    );
}

interface ValueViewWidgetProps {
    value: string;
    physicalUnit: string;
}

export default ValueViewWidget;