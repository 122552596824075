import { ReactElement } from 'react';

export default function GenericList(props: genericListProps) {
    if (!props.list) {
        if (props.loadingUI) {
            return props.loadingUI;
        }
        return <>Loading</>;
    } else if (props.list.length === 0) {
        return <>There are no elements to display</>;
    } else {
        return props.children;
    }
}

interface genericListProps {
    list: any;
    loadingUI?: ReactElement;
    children: ReactElement;
}
