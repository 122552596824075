import { MapContainer, Marker, TileLayer, useMap, Popup, Tooltip } from "react-leaflet";
import React, { useEffect, useRef, useState } from "react";
import BuildingActuators from "./BuildingActuators";
import { Actuator, Unit, Building } from "../../models/project.models";
import mqtt from "mqtt";
import L, { Icon } from 'leaflet';
import { moduleConfig } from "../modules/ModuleRegistry";
import { useTranslation } from "react-i18next";

function MapView() {
  const map = useMap();

  useEffect(() => {
    map.setView({ lat: 46.297627, lng: 7.903123 }, 16);
    map.scrollWheelZoom.enable();
  }, []);

  return null;
}

const MapWithPinActuatorCards: React.FC<MapWithPinActuatorCardsProps> = ({
  pins,
  pinStatusActuatorName,
  onPinSelected,
  actuatorOrder,
  preselectedPin,
  callingComponent,
  zone
}) => {
  const { t } = useTranslation();
  const [selectedPin, setSelectedPin] = useState<Building>(preselectedPin);
  const [mqttMessages, setMqttMessages] = useState<{ [key: string]: string }>({});
  const markerRefs = useRef<Map<string, any>>(new Map());
  const [triggerPopup, setTriggerPopup] = useState(false);

  useEffect(() => {
    if (preselectedPin && markerRefs.current.has(preselectedPin.id) && triggerPopup) {
      markerRefs.current.get(preselectedPin.id).openPopup();
      setTriggerPopup(false);
    }
  }, [preselectedPin, triggerPopup]);

  useEffect(() => {
    if (!pinStatusActuatorName || !pins || !pins[0]) return;

    const options = {
      username: pins[0].mqttUser,
      password: pins[0].mqttPass,
    };

    const client = mqtt.connect(pins[0].mqttServer, options);

    client.on('connect', () => {
      pins.forEach((building: Building) => {
        building.units.forEach((unit) => {
          client.subscribe(unit.gdasGuid + "/KeepAlive");
          unit.actuators.forEach((actuator) => {
            if (actuator.name === pinStatusActuatorName) {
              try {
                client.subscribe(unit.gdasGuid + "/" + actuator.address);
              } catch (error) {
                console.log(error);
              }
            }
          });
        });
      });

      client.on('message', (topic, message) => {
        setMqttMessages(prevMessages => {
          return {
            ...prevMessages,
            [topic]: message.toString(),
          };
        });
      });

      return () => {
        client.end();
      };
    });
  }, [pins, pinStatusActuatorName]);

  const getMarkerIcon = ((pin: Building, statusUnit: Unit, statusActuator: Actuator): Icon => {
    const defaultIconOptions = {
      iconAnchor: [11, 40] as [number, number],
      popupAnchor: [0, -40] as [number, number],
      iconSize: [22, 40] as [number, number],
      shadowUrl: require('../../resources/leaflet/images/marker-shadow.png'),
      shadowSize: [22, 40] as [number, number],
      shadowAnchor: [5, 40] as [number, number]
    };

    var defaultIcon = new L.Icon({
      ...defaultIconOptions,
      iconUrl: require(`../../resources/markers/well-control/marker-icon-blue.png`),
      iconRetinaUrl: require(`../../resources/markers/well-control/marker-icon-blue.png`)
    });

    if (!pin.module || !moduleConfig[pin.module.id] || !(moduleConfig[pin.module.id].pinStatusActuatorName) || !statusUnit || !statusActuator) return defaultIcon;

    const statusValue = mqttMessages[`${statusUnit.gdasGuid}/${statusActuator.address}`];

    const mqttValue = isNaN(parseFloat(statusValue)) ? -1 : parseFloat(statusValue);
    const colour = moduleConfig[pin.module.id].pinStatusColours ? (moduleConfig[pin.module.id].pinStatusColours as any)[mqttValue.toString()] || 'blue' : 'blue';

    //PIEZO
    if (pin.module.id === 'a18936c1-208f-463f-b864-a392e76b6f93') {
      // Use local icons for Well Control
      return new L.Icon({
        iconAnchor: [11, 40] as [number, number],
        popupAnchor: [0, -40] as [number, number],
        iconSize: [22, 40] as [number, number],
        shadowUrl: require('../../resources/leaflet/images/marker-shadow.png'),
        shadowSize: [22, 40] as [number, number],
        shadowAnchor: [5, 40] as [number, number],
        iconUrl: require(`../../resources/markers/piezo/piezo-dark-${colour}.png`),
        iconRetinaUrl: require(`../../resources/markers/piezo/piezo-dark-${colour}.png`),
      });
    }

    return new L.Icon({
      ...defaultIconOptions,
      iconUrl: require(`../../resources/markers/well-control/marker-icon-${colour}.png`),
      iconRetinaUrl: require(`../../resources/markers/well-control/marker-icon-${colour}.png`)
    });
  });

  const onMarkerClick = (pin: Building) => {
    setSelectedPin(pin);
    if (onPinSelected) {
      onPinSelected(pin);
    }
  };

  return (
    <>
      <div style={{ width: "100%", height: "50vh" }}>
        <MapContainer style={{ width: "100%", height: "100%" }}>
          <MapView />
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {pins.map((pin) => {
            const statusActuator = pin.units.flatMap((unit: Unit) => unit.actuators).find((actuator: Actuator) => actuator.name === pinStatusActuatorName);
            const statusUnit = pin.units.find((unit: Unit) => unit.actuators.includes(statusActuator));

            let mqttValue = -1;
            let tooltipMessage = "";

            if (statusUnit && statusActuator) {
              const mqttMessage = mqttMessages[`${statusUnit.gdasGuid}/${statusActuator.address}`];
              mqttValue = !isNaN(parseFloat(mqttMessage)) ? parseFloat(mqttMessage) : -1;
              tooltipMessage = moduleConfig[pin.module.id]?.pinStatusTooltipMessage ? moduleConfig[pin.module.id].pinStatusTooltipMessage[mqttValue] : "";
            }
            return (
              <Marker
                key={pin.id}
                position={[pin.latitude, pin.longitude]}
                icon={getMarkerIcon(pin, statusUnit, statusActuator)}
                eventHandlers={{
                  click: () => onMarkerClick(pin),
                }}
                ref={(ref) => {
                  if (ref && pin.id === preselectedPin.id) {
                    markerRefs.current.set(pin.id, ref);
                    setTriggerPopup(true);
                  }
                }}
              >
                <Popup closeOnClick={false} closeButton={false}>
                  <div>
                    <h2>{pin.name}</h2>
                  </div>
                </Popup>
                {tooltipMessage &&
                  <Tooltip permanent={false} interactive={true}>
                    {statusUnit.name}: {t(tooltipMessage)}
                  </Tooltip>
                }
              </Marker>
            )
          })}
        </MapContainer>
      </div>
      {selectedPin && <BuildingActuators key={selectedPin.id} building={selectedPin} actuatorOrder={actuatorOrder} callingComponent={callingComponent} zone={zone}/>}
    </>
  );
};

export default MapWithPinActuatorCards;