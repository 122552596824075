import i18n from 'i18next';

export const simplifyLanguageCode = (code: string): LanguageCode => {
    const simplifiedCode = code.split('-')[0];
    return simplifiedCode as LanguageCode;
};

export const getOppositeLanguage = (currentLanguage: LanguageCode): LanguageCode => {
    return currentLanguage === 'en' ? 'de' : 'en';
};

export const switchLanguage = (): void => {
    const currentLanguage = simplifyLanguageCode(localStorage.getItem("i18nextLng") || navigator.language);
    const newLanguage = getOppositeLanguage(currentLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("i18nextLng", newLanguage);

    window.location.reload();
};

type LanguageCode = 'en' | 'de';