import { Route, Routes } from "react-router-dom"
import Authorized from "../auth/Authorized"
import Home from "../Home"
import LoginPage from "../LoginPage"
import Overview from "../modules/Overview/Overview"
import WellControl from "../modules/WellControl/WellControl"
import UserManagement from "../UserManagement"
import ProjectManagement from "../ProjectManagement"
import Warnings from "../Warnings"
import Piezo from "../modules/Piezo/Piezo"
import Cameras from "../modules/Cameras/Cameras"
import EmailTemplate from "../EmailTemplate"
import WarningHistory from "../WarningHistory"
import ActuatorHistory from "../ActuatorHistory"
import GlobalCharts from "../GlobalCharts"


function AppRouter() {
    return (
        <>
            <Authorized authorized={
                <Routes>
                    <Route path="/" element={<Home children={<Overview />} />} />
                    <Route path="/warnings" element={<Home children={<Authorized authorized={<Warnings />} roles={["SuperAdmin", "ProjectOwner"]} />} />} />
                    <Route path="/usermanagement" element={<Home children={<Authorized authorized={<UserManagement />} roles={["SuperAdmin", "ProjectOwner"]} />} />} />
                    <Route path="/projectmanagement" element={<Home children={<Authorized authorized={<ProjectManagement />} roles={["SuperAdmin", "ProjectOwner"]} />} />} />
                    <Route path="/mail" element={<Home children={<Authorized authorized={<EmailTemplate />} roles={["SuperAdmin", "ProjectOwner"]} />} />} />
                    <Route path="/well-control" element={<Home children={<WellControl />} />} />
                    <Route path="/well-control/:zoneId" element={<Home children={<WellControl />} />} />
                    <Route path="/piezo" element={<Home children={<Piezo />} />} />
                    <Route path="/piezo/:interventionAreaId" element={<Home children={<Piezo />} />} />
                    <Route path="/cameras" element={<Home children={<Cameras />} />} />
                    <Route path="/cameras/:cameraId" element={<Home children={<Cameras />} />} />
                    <Route path="/warninghistory" element={<Home children={<WarningHistory />} />} />
                    <Route path="/actuatorhistory" element={<Home children={<ActuatorHistory />} />} />
                    <Route path="/charts" element={<Home children={<GlobalCharts />} />} />
                </Routes>
            }
                notAuthorized={
                    <Routes>
                        <Route path="*" element={<LoginPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </Routes>
                } />
        </>
    )
}

export default AppRouter