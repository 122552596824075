import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import AuthenticationContext from "../../auth/AuthenticationContext";
import api, { endpoints } from "../../../utils/api";
import { Building } from "../../../models/project.models";
import { useTranslation } from "react-i18next";
import MapWithPinActuatorCards from "../../elements/MapWithPinActuatorCards";
import { useLocation } from "react-router-dom";
import { moduleConfig, moduleRegistry } from "../ModuleRegistry";
import MapWithFixedBuildingActuatorCards from "../../elements/MapWithFixedBuildingActuatorCards";
import { piezoZoneBuildingsRegistry } from '../BuildingRegisty';

export default function Piezo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  var { interventionAreaId } = useParams() as { interventionAreaId: string };
  const { currentUser } = useContext(AuthenticationContext);
  const location = useLocation();
  var selectedBuilding = location.state?.selectedBuilding as Building;
  var selectedArea = location.state?.filter as string;

  const { pinStatusActuatorName, pinStatusColours } = moduleConfig['a18936c1-208f-463f-b864-a392e76b6f93'] || {};

  const [pins, setPins] = useState<Building[]>([]);
  const [predefinedActuatorOrder, setPredefinedActuatorOrder] = useState<string[]>([]);

  const actuatorOrder = [
    "Identifikation",
    "letzte Datenübermittlung",
    "Uhrzeit",
    "Messwert m.ü.M - aktuell",
    "Betriebsmeldung",
    "max. Höchststand",
    "Messwert m.ü.M - Vortag",
    "empty",
  ];

  const overAllOrder = [
    "text:Identification:allInterventionAreas",
    "2100/93/1",
    "2100/93/2",
    "2100/93/5",
    "2100/93/4",
    "2100/93/3",
    "2100/93/6",
    "2100/93/7",
  ];

  const interventionArea1order = [
    "text:Identification:" + t("interventionArea") + " 1",
    "2100/90/1",
    "2100/90/2",
    "2100/90/5",
    "2100/90/4",
    "2100/90/3",
    "2100/90/6",
    "2100/90/7",
  ];

  const interventionArea2order = [
    "text:Identification:" + t("interventionArea") + " 2",
    "2100/91/1",
    "2100/91/2",
    "2100/91/5",
    "2100/91/4",
    "2100/91/3",
    "2100/91/6",
    "2100/91/7",
  ];

  const interventionArea3order = [
    "text:Identification:" + t("interventionArea") + " 3",
    "2100/92/1",
    "2100/92/2",
    "2100/92/5",
    "2100/92/4",
    "2100/92/3",
    "2100/92/6",
    "2100/92/7",
  ];

  const updateBuilding = (building: Building) => {
    selectedBuilding = building;
  };

  useEffect(() => {

    if (selectedArea !== undefined) interventionAreaId = "-1";

    switch (interventionAreaId) {
      case '1':
        setPredefinedActuatorOrder(interventionArea1order);
        break;
      case '2':
        setPredefinedActuatorOrder(interventionArea2order);
        break;
      case '3':
        setPredefinedActuatorOrder(interventionArea3order);
        break;
      case undefined:
      default:
        if (selectedBuilding === undefined) {
          setPredefinedActuatorOrder(overAllOrder);
        }
        else {
          setPredefinedActuatorOrder(actuatorOrder);
        }
        break;
    }
  }, [interventionAreaId, selectedBuilding]);

  useEffect(() => {
    //get active modules for user
    try {
      api
        .get(endpoints.buildings + "/moduleBuildings", {
          params: {
            moduleIds: 'a18936c1-208f-463f-b864-a392e76b6f93' //piezo
          }
        })
        .then((response) => {
          setPins(response.data);
        });
    } catch (error) {
      //console.error("Error fetching modules:", error);
    }
  }, [currentUser]);

  const onPinSelected = (pin: Building, filter: string | undefined) => {
    const moduleId = pin.module.id;
    const module = moduleRegistry[moduleId];
    if (module) {
        navigate(module.path + (filter !== undefined ? ("/" + filter) : ""), { state: { selectedBuilding: pin, filter } });
    }
  };

  return (
    <>
      <h1>{t("Piezo")}</h1>
      {
        interventionAreaId === undefined || selectedArea !== undefined
        ?
          (
            selectedBuilding !== undefined || selectedArea !== undefined
            ?
            <MapWithPinActuatorCards pins={ selectedArea === undefined ? pins : pins.filter(pin => piezoZoneBuildingsRegistry[selectedArea].includes(pin.name))} actuatorOrder={predefinedActuatorOrder} pinStatusActuatorName={pinStatusActuatorName} pinStatusColours={pinStatusColours} preselectedPin={selectedBuilding} onPinSelected={updateBuilding} callingComponent="piezo"></MapWithPinActuatorCards>
            :
            <MapWithFixedBuildingActuatorCards buildingId="f59cd020-9093-42b0-97d5-add4831210cc" actuatorOrder={overAllOrder} pins={(selectedArea || interventionAreaId) ? pins.filter(pin => piezoZoneBuildingsRegistry[selectedArea ?? interventionAreaId].includes(pin.name)) : pins} onPinSelected={onPinSelected} callingComponent="piezo" zone={interventionAreaId}></MapWithFixedBuildingActuatorCards>
          )
        :
        <MapWithFixedBuildingActuatorCards buildingId="f59cd020-9093-42b0-97d5-add4831210cc" actuatorOrder={predefinedActuatorOrder} pins={(selectedArea ?? interventionAreaId) ? pins.filter(pin => piezoZoneBuildingsRegistry[selectedArea ?? interventionAreaId].includes(pin.name)) : pins} onPinSelected={onPinSelected} callingComponent="piezo" zone={interventionAreaId}></MapWithFixedBuildingActuatorCards>
      }
    </>
  );
}
