import React, { useState, useContext, useEffect } from 'react';
import api, { endpoints } from '../utils/api';
import AuthenticationContext from './auth/AuthenticationContext';
import { Container, Accordion, Button, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Project } from '../models/project.models';

const ProjectManagement: React.FC = () => {
    type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

    type BuildingState = {
        [projectId: string]: {
            name?: string;
            street?: string;
            zipCode?: string;
            city?: string;
            country?: string;
            latitude?: number;
            longitude?: number;
            mqttServer?: string;
            mqttUser?: string;
            mqttPass?: string;
        };
    };

    type UnitState = {
        [buildingId: string]: {
            name: '',
            gdasGuid: ''
        }
    }

    type ActuatorState = {
        [unitId: string]: {
            name: '',
            address: '',
            physicalUnit: '',
            decimals: 0,
            inputType: 0
        }
    }

    const { t } = useTranslation();

    const defaultMqttSettings = {
        mqttServer: "wss://proxy.gdas.digital",
        mqttUser: "gdasmqtt",
        mqttPass: "pRvTAuL87WBhbrJTaEga"
    };

    const inputTypes = [
        { value: 0, label: t("readOnly") },
        { value: 1, label: t("writeBoolean")},
        { value: 2, label: t("writeInteger") }
    ];

    const { currentUser } = useContext(AuthenticationContext);

    const [projects, setProjects] = useState<Project[]>([]);
    const [modules, setModules] = useState<Module[]>([]);
    const [userAccesses, setUserAccesses] = useState<UserAccess[]>([]);

    const [newProject, setNewProject] = useState({
        name: '',
        email: currentUser?.email
    });
    const [assignUser, setAssignUser] = useState({
        email: '',
        projectId: ''
    });
    const [newBuilding, setNewBuilding] = useState<BuildingState>({});
    const [selectedModule, setSelectedModule] = useState('');

    const [newUnit, setNewUnit] = useState<UnitState>({});

    const [newActuator, setNewActuator] = useState<ActuatorState>({});

    useEffect(() => {
        if (currentUser?.roles.includes('SuperAdmin') || currentUser?.roles.includes('ProjectOwner')) {
            fetchProjects();
            fetchModules();
            fetchUserAccesses();
        }
    }, [currentUser]);

    const fetchProjects = async () => {
        try {
            const response = await api.get(endpoints.projects + '/allProjects');
            setProjects(response.data);
        } catch (error) {
            //console.error('Error fetching users with roles:', error);
        }
    };

    const fetchModules = async () => {
        try {
            const response = await api.get(endpoints.modules + '/modules');
            setModules(response.data);
        } catch (error) {
            //console.error('Error fetching modules:', error);
        }
    };

    const fetchUserAccesses = async () => {
        try {
            const response = await api.get(endpoints.userAccesses + '/userAccesses');
            setUserAccesses(response.data);
        } catch (error) {
            //console.error('Error fetching user accesses:', error);
        }
    };

    //creating project
    const handleProjectInputChange = (event: React.ChangeEvent<FormControlElement>) => {
        setNewProject({ ...newProject, [event.target.name]: event.target.value });
    };

    const handleCreateProject = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            await api.post(endpoints.projects + '/project', newProject);
            fetchProjects();
            setNewProject({ name: '', email: currentUser?.email || '' });
        } catch (error) {
            //console.error('Error creating project:', error);
        }
    };

    //assigning user
    const handleUserInputChange = (event: React.ChangeEvent<FormControlElement>) => {
        setAssignUser({ ...assignUser, [event.target.name]: event.target.value });
    };

    const handleAssignUser = async (projectId: string, event: React.FormEvent) => {
        event.preventDefault();
        try {
            const updatedUserAssignment = {
                ...assignUser,
                projectId
            };

            await api.post(endpoints.projects + '/assignUser', updatedUserAssignment);
            fetchProjects();
            setAssignUser({ projectId: '', email: currentUser?.email || '' });
        } catch (error) {
            alert(`Could not assign user to project.`);
        }
    };

    const hanldeUnassignUser = async (projectId: string, userEmail: string) => {
        try {
            await api.post(endpoints.projects + `/unassignUser`, { projectId, email: userEmail });
            fetchProjects();
        } catch (error) {
            //console.error('Error removing user from project:', error);
        }
    };

    //creating building
    const handleBuildingInputChange = (projectId: string, event: React.ChangeEvent<FormControlElement>) => {
        const { name, value } = event.target;
        setNewBuilding(prevState => ({
            ...prevState,
            [projectId]: {
                ...prevState[projectId],
                [name]: value,
            },
        }));
    };

    const handleCreateBuilding = async (projectId: string, event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const buildingData = { ...defaultMqttSettings, ...newBuilding[projectId] };
            await api.post(endpoints.buildings + '/building', { ...buildingData, projectId, moduleId: selectedModule });
            fetchProjects();
            setNewBuilding(prevState => ({
                ...prevState,
                [projectId]: {
                    name: '',
                    street: '',
                    zipCode: '',
                    city: '',
                    country: '',
                    latitude: 0,
                    longitude: 0,
                    mqttServer: defaultMqttSettings.mqttServer,
                    mqttUser: defaultMqttSettings.mqttUser,
                    mqttPass: defaultMqttSettings.mqttPass,
                },
            }));
        } catch (error) {
            //console.error('Error creating building:', error);
        }
    };

    useEffect(() => {
        if (currentUser?.roles.includes('SuperAdmin') || currentUser?.roles.includes('ProjectOwner')) {
            fetchProjects();
        }
    }, [currentUser]);

    //creating unit
    const handleUnitInputChange = (buildingId: string, event: React.ChangeEvent<FormControlElement>) => {
        const { name, value } = event.target;
        setNewUnit(prevState => ({
            ...prevState,
            [buildingId]: {
                ...prevState[buildingId],
                [name]: value,
            },
        }));
    };

    const handleCreateUnit = async (buildingId: string, event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const unitData = { ...newUnit[buildingId], buildingId }
            await api.post(endpoints.units + '/unit', unitData);
            fetchProjects();

            setNewUnit(prevState => ({
                ...prevState,
                [buildingId]: {
                    name: '',
                    gdasGuid: '',
                },
            }));
        } catch (error) {
            //console.error('Error creating unit:', error);
        }
    };

    //creating actuator
    const handleActuatorInputChange = (unitId: string, event: React.ChangeEvent<FormControlElement>) => {
        const { name, value } = event.target;
        setNewActuator(prevState => ({
            ...prevState,
            [unitId]: {
                ...prevState[unitId],
                [name]: value,
            },
        }));
    };

    const handleCreateActuator = async (unitId: string, event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const actuatorData = { ...newActuator[unitId], unitId };
            await api.post(endpoints.actuators + '/actuator', actuatorData);
            fetchProjects();

            setNewActuator(prevState => ({
                ...prevState,
                [unitId]: {
                    name: '',
                    address: '',
                    physicalUnit: '',
                    decimals: 0,
                    inputType: 0
                },
            }));
        } catch (error) {
            //console.error('Error creating actuator:', error);
        }
    };

    //user access
    const checkUserAccess = (userEmail: string, actuatorId: string) => {
        return userAccesses.some(access => {;
            return access.userEmail === userEmail && access.actuatorId === actuatorId;
        });
    };

    const handleAccessChange = async (userEmail: string, actuatorId: string) => {
        const hasAccess = checkUserAccess(userEmail, actuatorId);

        try {
            if (hasAccess) {
                await api.post(endpoints.userAccesses + '/revokeUserAccess', { userEmail, actuatorId });
            } else {
                await api.post(endpoints.userAccesses + '/grantUserAccess', { userEmail, actuatorId });
            }
            fetchUserAccesses();
        } catch (error) {
            //console.error('Error updating user access:', error);
        }
    };

    return (
        <Container fluid>
            <h1>{t("projectManagement")}</h1>
            {/* <div className='project-form-section'>
                <h2>Add Project</h2>
                <Form onSubmit={handleCreateProject}>
                    <Row>
                        <Col xs={12} md={6} lg={4}>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={newProject.name}
                                    onChange={handleProjectInputChange}
                                    placeholder="Project Name"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <Button variant="primary" type="submit">Add Project</Button>
                        </Col>
                    </Row>
                </Form>
            </div> */}

            <div className="project-list-section mt-5">
                <h2>{t("projectList")}</h2>
                {projects.map((project, index) => (
                    <Accordion>
                        <Accordion.Item eventKey={String(index)} key={project.id}>
                            <Accordion.Header>{project.name}</Accordion.Header>
                            <Accordion.Body>


                                {/* User list */}
                                <h3>{t("userList")}</h3>
                                <Accordion>
                                    {project.users.map(user => (
                                        <Accordion.Item eventKey={user.email} key={user.email}>
                                            <Accordion.Header>{user.firstName} {user.lastName} - {user.email}</Accordion.Header>
                                            <Accordion.Body>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => hanldeUnassignUser(project.id, user.email)}
                                                    disabled={currentUser?.email === user.email}
                                                >
                                                    {t("remove")}
                                                </Button>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>

                                {/* Assign user to project form */}
                                <Accordion style={{ marginTop: '32px' }}>
                                    <Accordion.Item eventKey={'assignUser'} key={'assignUser'}>
                                        <Accordion.Header>{t("assignUser")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Form onSubmit={(e) => handleAssignUser(project.id, e)}>
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <Form.Group>
                                                            <Form.Control
                                                                type="text"
                                                                name="email"
                                                                value={assignUser.email}
                                                                onChange={handleUserInputChange}
                                                                placeholder={t("emailPlaceholder")}
                                                                required
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Button variant="primary" type="submit">{t("assignUser")}</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                {/* Buildings Accordion */}
                                <h3 style={{ marginTop: '32px' }}>{t("buildingList")}</h3>
                                <Accordion>
                                    {project.buildings.map((building, buildingIndex) => (
                                        <Accordion.Item eventKey={String(buildingIndex)} key={building.id}>
                                            <Accordion.Header>{building.name}</Accordion.Header>
                                            <Accordion.Body>
                                                {/* Units Accordion */}
                                                <h3>{t("unitList")}</h3>
                                                <Accordion>
                                                    {building.units.map((unit, unitIndex) => (
                                                        <Accordion.Item eventKey={String(unitIndex)} key={unit.id}>
                                                            <Accordion.Header>{unit.name}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {/* Actuators list */}
                                                                <h3>{t("actuatorList")}</h3>
                                                                <Accordion>
                                                                    {unit.actuators.map((actuator, actuatorIndex) => (
                                                                        <Accordion.Item eventKey={String(actuatorIndex)} key={actuator.id}>
                                                                            <Accordion.Header>{actuator.name}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <p>{t("physicalUnit")}: {actuator.physicalUnit}</p>
                                                                                <p>{t("decimals")}: {actuator.decimals}</p>
                                                                                <h3>{t("access")}</h3>
                                                                                <ul>
                                                                                    {project.users.map((user) => (
                                                                                        <Form.Check
                                                                                            key={`${user.email}-${actuator.id}`}
                                                                                            type="switch"
                                                                                            id={`access-switch-${user.email}-${actuator.id}`}
                                                                                            label={`${user.firstName} ${user.lastName}`}
                                                                                            checked={checkUserAccess(user.email, actuator.id)}
                                                                                            onChange={() => handleAccessChange(user.email, actuator.id)}
                                                                                        />
                                                                                    ))}
                                                                                </ul>
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    ))}
                                                                </Accordion>

                                                                {/* Create actuator */}
                                                                <Accordion style={{ marginTop: '32px' }}>
                                                                    <Accordion.Item eventKey={String(unitIndex)} key={unit.id}>
                                                                        <Accordion.Header>{t("createNewActuator")}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {/* Actuator creation form */}
                                                                            <Form onSubmit={(e) => handleCreateActuator(unit.id, e)}>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="name"
                                                                                    placeholder={t("actuatorNamePlaceholder")}
                                                                                    value={newActuator[unit.id]?.name || ''}
                                                                                    onChange={(e) => handleActuatorInputChange(unit.id, e)}
                                                                                    required
                                                                                />
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="address"
                                                                                        placeholder={t("addressPlaceholder")}
                                                                                        value={newActuator[unit.id]?.address || ''}
                                                                                        onChange={(e) => handleActuatorInputChange(unit.id, e)}
                                                                                        required
                                                                                    />
                                                                                    <Form.Label>{t("example")} ADDRESS-VALUE/XXXX/X/X</Form.Label>
                                                                                </Form.Group>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="physicalUnit"
                                                                                    placeholder={t("physicalUnitPlaceholder")}
                                                                                    value={newActuator[unit.id]?.physicalUnit || ''}
                                                                                    onChange={(e) => handleActuatorInputChange(unit.id, e)}
                                                                                    required
                                                                                />
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    name="decimals"
                                                                                    placeholder={t("decimalsPlaceholder")}
                                                                                    value={newActuator[unit.id]?.decimals || ''}
                                                                                    onChange={(e) => handleActuatorInputChange(unit.id, e)}
                                                                                    required
                                                                                />
                                                                                <Form.Group controlId="formInputType">
                                                                                    <Form.Control as="select" name="inputType" value={newActuator[unit.id]?.inputType || ''} onChange={(e) => handleActuatorInputChange(unit.id, e)} required>
                                                                                        {inputTypes.map(type => (
                                                                                            <option key={type.value} value={type.value}>{type.label}</option>
                                                                                        ))}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                                <Button type="submit" variant="primary">{t("addActuator")}</Button>
                                                                            </Form>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}

                                                    <Accordion style={{ marginTop: '32px' }}>
                                                        <Accordion.Item eventKey={`newUnit-${building.id}`} key={`newUnit-${building.id}`}>
                                                            <Accordion.Header>{t("createNewUnit")}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Form onSubmit={(e) => handleCreateUnit(building.id, e)}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="name"
                                                                        placeholder={t("unitNamePlaceholder")}
                                                                        value={newUnit[building.id]?.name || ''}
                                                                        onChange={(e) => handleUnitInputChange(building.id, e)}
                                                                        required
                                                                    />
                                                                    <Form.Group>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="gdasGuid"
                                                                            placeholder={t("gdasGuidPlaceholder")}
                                                                            value={newUnit[building.id]?.gdasGuid || ''}
                                                                            onChange={(e) => handleUnitInputChange(building.id, e)}
                                                                            required
                                                                        />
                                                                        <Form.Label>{t("example")} GDAS/XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX-XXXX</Form.Label>
                                                                    </Form.Group>
                                                                    <Button type="submit" variant="primary">{t("addUnit")}</Button>
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Accordion>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>

                                <Accordion style={{ marginTop: '32px' }}>
                                    <Accordion.Item eventKey={'createNewBuilding'} key={'createNewBuilding'}>
                                        <Accordion.Header style={{ color: 'red' }}>{t("createNewBuilding")}</Accordion.Header>
                                        <Accordion.Body>
                                            <Form onSubmit={(e) => handleCreateBuilding(project.id, e)}>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder={t("buildingNamePlaceholder")}
                                                    value={newBuilding[project.id]?.name || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="street"
                                                    placeholder={t("streetPlaceholder")}
                                                    value={newBuilding[project.id]?.street || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="zipCode"
                                                    placeholder={t("zipCodePlaceholder")}
                                                    value={newBuilding[project.id]?.zipCode || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="city"
                                                    placeholder={t("cityPlaceholder")}
                                                    value={newBuilding[project.id]?.city || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="text"
                                                    name="country"
                                                    placeholder={t("countryPlaceholder")}
                                                    value={newBuilding[project.id]?.country || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="number"
                                                    step="0.1"
                                                    name="latitude"
                                                    placeholder={t("latitudePlaceholder")}
                                                    value={newBuilding[project.id]?.latitude || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Control
                                                    type="number"
                                                    step="0.1"
                                                    name="longitude"
                                                    placeholder={t("longitudePlaceholder")}
                                                    value={newBuilding[project.id]?.longitude || ''}
                                                    onChange={(e) => handleBuildingInputChange(project.id, e)}
                                                    required
                                                />
                                                <Form.Group controlId="formModuleSelect">
                                                    <Form.Control as="select" value={selectedModule} onChange={e => setSelectedModule(e.target.value)}>
                                                        <option value="">{t("noModule")}</option>
                                                        {modules.map(module => (
                                                            <option key={module.name} value={module.id}>{module.name}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>

                                                <Button type="submit" variant="primary">{t("addBuilding")}</Button>
                                            </Form>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ))}
            </div>
        </Container >
    );
};

export default ProjectManagement;
