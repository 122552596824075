import React from 'react';
import { ExtendedActuator } from "../../models/project.models";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { findBuildingInZones } from '../modules/BuildingRegisty';

const CalculatorModal: React.FC<CalculatorModalProps> = ({
  show,
  onHide,
  writableActuators,
  mqttMessages,
  dropdownSelection,
  onDropdownChange,
  selectedActuatorsForModal,
  onAddActuator,
  onValueChange,
  onReset,
  onCancel,
  onSend,
  isGlobal,
  t
}) => {
  return (
    <Modal size="xl" show={show} onHide={onHide} animation={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("writeValues")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3" style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
          <Col sm={10}>
            <Form.Control
              as="select"
              multiple
              value={dropdownSelection}
              onChange={(e) => {
                const target = e.target as unknown as HTMLSelectElement;
                const selections = Array.from(target.selectedOptions).map(o => o.value);
                onDropdownChange(selections);
              }}
            >
              {writableActuators
              .sort((a, b) => a.unitName.localeCompare(b.unitName))
              .map(actuator => {
                const gdasGuidAddress = `${actuator.gdasGuid}/${actuator.address}`;
                const value = parseFloat(mqttMessages[gdasGuidAddress]).toFixed(actuator.decimals);
                const displayValue = isNaN(parseFloat(value)) ? actuator.lastValue?.toFixed(actuator.decimals ?? 2) : value;
                const unitName = getMasterUnitDetails( actuator,  isGlobal ?? false, t);
                const displayText = `${unitName} ${actuator.name} [${displayValue} ${actuator.physicalUnit ?? ''}]`;
                return (
                  <option key={actuator.id} value={gdasGuidAddress}>{displayText}</option>
                );
              })}
            </Form.Control>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              onClick={() => dropdownSelection.forEach(selection => onAddActuator(selection))}
            >
              {t("add")}
            </Button>
          </Col>
        </Row>
        {Array.from(selectedActuatorsForModal).map(([topic, value], index) => {
             var [gdasGuid, address] = topic.split('/ADDRESS-VALUE');
             var actuator = writableActuators.find(a => a.gdasGuid === gdasGuid && a.address.includes(address));
             var actuatorName = actuator?.name ?? "N/A";
             var unitName = actuator?.unitName ?? "N/A";
             if (actuator != undefined){
               unitName = getMasterUnitDetails(actuator, isGlobal ?? false, t);
             }
             var currentValue = parseFloat(mqttMessages[topic]).toFixed(actuator ? actuator.decimals : 2);
             if (currentValue === "NaN" && actuator && actuator.lastValue !== null) {
               currentValue = actuator.lastValue.toFixed(actuator.decimals ?? 2);
             }
             var physicalUnit = actuator ? actuator.physicalUnit : "";
            return (
                <Form.Group as={Row} key={index} className="align-items-center" style={{ margin: "0.5rem 0" }}>
                    <Col sm={8}>
                    <Form.Label>{`${unitName} ${actuatorName} [${currentValue} ${physicalUnit}]`}</Form.Label>
                    </Col>
                    <Col sm={4}>
                    <Form.Control
                        type="number"
                        value={value}
                        onChange={(e) => onValueChange(topic, e.target.value)}
                    />
                    </Col>
                </Form.Group>
            );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" onClick={onReset}>{t("reset")}</Button>
        <Button variant="secondary" onClick={onCancel}>{t("cancel")}</Button>
        <Button variant="primary" onClick={onSend}>{t("send")}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CalculatorModal;

interface FormatMasterUnitNameParams {
  unit: string;
  zones: string[];
  zoneType: string;
}

interface CalculatorModalProps {
    show: boolean;
    onHide: () => void;
    writableActuators: ExtendedActuator[];
    mqttMessages: { [key: string]: string };
    dropdownSelection: string[];
    onDropdownChange: (selections: string[]) => void;
    selectedActuatorsForModal: Map<string, string>;
    onAddActuator: (selection: string) => void;
    onValueChange: (topic: string, value: string) => void;
    onReset: () => void;
    onCancel: () => void;
    onSend: () => void;
    isGlobal: boolean;
    t: (key: string) => string;
  }

// Helper to get the calling component
function getCallingComponent(isGlobal: boolean): string {
  var callingComponent = "";
  if (window.location.pathname.includes("well-control")) callingComponent = "well-control";
  else if (window.location.pathname.includes("piezo")) callingComponent = "piezo";
  else if (isGlobal) callingComponent = "global";
  else callingComponent = "home";
  return callingComponent;
}

// Helper to format the master unit name based on zones
function formatMasterUnitName({ unit, zones, zoneType }: FormatMasterUnitNameParams, t: (key: string) => string): string {
  const zoneString = zoneType === "piezo" || zoneType === "global" ? t("interventionArea") :
                     zoneType === "well-control" ? t("zone") : "";
  return `${unit} (${zoneString}: ${zones.length === 0 ? t("overAll") : zones.join(', ')}) `;
}

// Helper to get master unit details with types
function getMasterUnitDetails( actuator: ExtendedActuator, isGlobal: boolean, t: (key: string) => string): string {
  const callingComponent = getCallingComponent(isGlobal);
  if (actuator.unitName === "MASTER") {
    const zones = findBuildingInZones(callingComponent, actuator.address.replace("ADDRESS-VALUE/", ""), isGlobal);
    return formatMasterUnitName({ unit: actuator.unitName, zones, zoneType: callingComponent }, t);
  }
  return actuator.unitName;
}
