import { useTranslation } from "react-i18next";
import { getIcon } from "../IconMap";

const StateIconWidget: React.FC<StateIconWidgetProps> = ({ value, labels, icons }) => {
    const { t } = useTranslation();
    const icon = getIcon(icons[Number(value)]);

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <h1>
                <strong>{t(value ? labels[Number(value)] : '')}</strong>
            </h1>
            <div style={{width: '50px', height: '50px'}}>{value ? icon : ''}</div>
        </div>
    );
}

interface StateIconWidgetProps {
    value: string;
    labels: string[];
    icons: string[];
}

export default StateIconWidget;
