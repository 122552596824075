import React from 'react';
import { Accordion } from 'react-bootstrap';
import { BuildingIdentifier, ProjectIdentifier } from '../../../models/project.models';
import BuildingAccordion from './BuildingAccordion';
import api, { endpoints } from '../../../utils/api';
import { useTranslation } from 'react-i18next';

const ProjectAccordion: React.FC<ProjectAccordionProps> = ({ project }) => {

  const { t } = useTranslation();
  const [buildings, setBuildings] = React.useState<BuildingIdentifier[]>([]);

  const fetchBuildings = async () => {
    const response = await api.get(`${endpoints.buildings}/buildingName/${project.projectId}`);
    setBuildings(response.data);
  };

  return (
    <Accordion.Item eventKey={project.projectId}>
      <Accordion.Header onClick={() => {
          if (buildings.length === 0) fetchBuildings();
        }}>
        {project.projectName}
      </Accordion.Header>
      <Accordion.Body>
        <h3>{t("buildingList")}</h3>
        <BuildingAccordion buildings={buildings} />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ProjectAccordion;

interface ProjectAccordionProps {
  project: ProjectIdentifier;
}