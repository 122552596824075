import { PureComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';

const CustomTooltip = ({
  active,
  payload
}: TooltipProps<number, string>) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
        <p>{`${payload[0].payload.createdDate}`}</p>
        <p>{`${t("value")}: ${payload[0].value}`}</p>
        {/* <p>{`${t("delta")}: ${payload[0].payload.delta}`}</p> */}
      </div>
    );
  }

  return null;
};

export default class PositiveAndNegativeBarChart extends PureComponent<PositiveAndNegativeBarChartProps> {
  render() {
    const { chartData, label1, label2 } = this.props;

    return (
      <div style={{ width: '100%', backgroundColor: "white" }}>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdDate" />
            <YAxis />
            {/* <Tooltip content={<CustomTooltip />} /> */}
            <Tooltip />
            <Legend />
            <ReferenceLine y={0} stroke="#000" />
            <Bar dataKey="value1" fill="#8884d8" name={label1}/>
            {
                label2 &&
                <Bar dataKey="value2" fill="#f59f2f" name={label2} />
            }
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export interface ChartDataStructure {
    createdDate: string;
    value1: number;
    value2: number;
    delta: number;
}

interface PositiveAndNegativeBarChartProps {
    chartData: ChartDataStructure[];
    label1: string;
    label2?: string;
}