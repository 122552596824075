import { PureComponent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps
} from 'recharts';

const CustomTooltip = ({ active, payload, label }: TooltipProps<string, string>) => {
    const { t } = useTranslation();
    if (active && payload && payload.length) {
        return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p className="label">{`${label}`}</p>
            <p>{`${t("value")}: ${payload[0].value}`}</p>
            {/* <p>{`${t("delta")}: ${payload[0].payload.delta}`}</p> */}
        </div>
        );
    }
    return null;
};

export default class SimpleLineChart extends PureComponent<SimpleLineChartProps> {
  render() {
    const { chartData, label1, label2 } = this.props;

    return (
      <div style={{ width: '100%', backgroundColor: "white" }}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="createdDate" />
            <YAxis />
            {/* <Tooltip content={<CustomTooltip />} /> */}
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value1" stroke="#8884d8" name={label1} />
            {label2 && <Line type="monotone" dataKey="value2" stroke="#f59f2f" name={label2} />}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

interface ChartDataStructure {
    createdDate: string;
    value1: number;
    value2: number;
    delta: number;
  }

  interface SimpleLineChartProps {
    chartData: ChartDataStructure[];
    label1: string;
    label2?: string;
  }