import { useEffect, useState } from "react";
import { User } from "../../models/auth.models";
import { useTranslation } from "react-i18next";

export default function UserCheckboxList(props: UserCheckboxListProps) {

    const { t } = useTranslation();
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

    const toggleUserSelection = (user: User) => {
      const isUserSelected = selectedUsers.find(
        (selectedUser) => selectedUser.email === user.email
      );
      if (isUserSelected) {
        const updatedSelectedUsers = selectedUsers.filter(
          (selectedUser) => selectedUser.email !== user.email
        );
        setSelectedUsers(updatedSelectedUsers);
        props.setSelectedUsers(updatedSelectedUsers, props.additionalKey);
      } else {
          const updatedSelectedUsers = [...selectedUsers, user];
          setSelectedUsers(updatedSelectedUsers);
          props.setSelectedUsers(updatedSelectedUsers, props.additionalKey);
      }
    };

    useEffect(() => {
      const initialSelected = props.allUsers.filter(user =>
        props.initialSelectedUsers?.includes(user.email)
      );
      setSelectedUsers(initialSelected);
    }, [props.initialSelectedUsers, props.allUsers]);

    useEffect(() => {
        setSelectedUsers(props.selectedUsers || []);
      }, [props.selectedUsers]);

  return (
    <>
      {
        props.allUsers.length === 0
        ?
          <div>
            <p style={{margin: "1rem 0"}}>{t("noUser")}</p>
          </div>
        :
        props.allUsers.sort((a, b) => {
          const aHasRoles = a.roles && a.roles.length > 0;
          const bHasRoles = b.roles && b.roles.length > 0;

          if (aHasRoles && bHasRoles) {
            const roleComparison = a.roles[0].localeCompare(b.roles[0]);
            if (roleComparison !== 0) return roleComparison;
            return a.lastName.localeCompare(b.lastName);
          }

          else if (!aHasRoles && !bHasRoles) {
            const aAlias = a.alias || "";
            const bAlias = b.alias || "";
            return aAlias.localeCompare(bAlias);
          }

          else if (!aHasRoles) return 1;
          else if (!bHasRoles) return -1;

          return 0;
        })
        .map((user) => (
          <div
            key={props.additionalKey + "#" + user.email}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              margin: "0.25rem 0",
            }}
          >
            <input
              type="checkbox"
              id={props.additionalKey + "#" + user.email}
              checked={selectedUsers.some(
                (selectedUser) => selectedUser.email === user.email
              )}
              onChange={() => toggleUserSelection(user)}
            />
            <label
              htmlFor={props.additionalKey + "#" + user.email}
              style={{
                marginLeft: "8px",
                textAlign: "left",
                width: "100%",
              }}
            >
              { user.roles != null ? `${user.roles.join(", ")} - ${user.lastName} ${user.firstName}` : `${user.alias}`}
            </label>
          </div>
        ))}
    </>
  );
}

interface UserCheckboxListProps {
    allUsers: User[]
    selectedUsers?: User[];
    initialSelectedUsers?: string[];
    setSelectedUsers: (selectedUsers: User[], key: string) => void;
    additionalKey: string;
}

UserCheckboxList.defaultProps = {
    additionalKey: "",
};
