import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from 'react-bootstrap';
import FormTextInput from '../elements/FormTextInput';
import { userCredentials } from "../../models/auth.models";
import { useTranslation } from 'react-i18next';
import { useValidationSchemas } from '../../validations';


export default function AuthForm(props: authFormProps) {

    const { t } = useTranslation();
    const {
        emailValidation,
        passwordValidation,
        confirmPasswordValidation
      } = useValidationSchemas();

    let validationObject: any = {
        email: Yup.string().required(t("emailRequired")),
        password: Yup.string().required(t("passwordRequired"))
    };

    if (props.isRegister) {
        validationObject.email = emailValidation;
        validationObject.password = passwordValidation;
        validationObject.confirmPassword = confirmPasswordValidation;
    }

    const validationSchema = Yup.object().shape(validationObject);

    return (
        <Formik
            initialValues={props.model}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}>
            {formikProps => (
                <Form>
                    <FormTextInput name={'email'} displayName={t("emailPlaceholder")} />
                    <FormTextInput name={'password'} displayName={t("passwordPlaceholder")} type="password" />
                    {props.isRegister ?
                        <>
                            <FormTextInput name={'confirmPassword'} displayName={t("confirmPassword")} type="password" />
                        </> :
                        null
                    }
                    <div className="d-grid gap-2">
                        <Button variant="primary" style={{backgroundColor: "#342826"}} size="lg" type="submit" disabled={formikProps.isSubmitting}>{props.isRegister ? t("register") : t("login")}</Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface authFormProps {
    model: userCredentials;
    isRegister?: boolean;
    onSubmit(values: userCredentials, actions: FormikHelpers<userCredentials>): void;
}